import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationExportEntry } from "@/interfaces/organization/export/OrganizationExportEntry";

class OrganizationExportApi extends OrganizationGenericEntityApi<OrganizationExportEntry> {
  apiIdentifier = "organization_export_entry";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/export-data`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_export_entry")
      .asOrganizationEntity()
      .asDate("current_export_last_created_at")
      .asDate("job_pickup_at")
      .asDate("job_started_at")
      .asDate("completed_at")
      .asDate("available_until")
      .build() as OrganizationExportEntry;
  }
}

export function useOrganizationExportApi() {
  return new OrganizationExportApi();
}
