<script setup lang="ts">
import { useFrontendStore } from "@/stores/Frontend";
import { useOrganizationsStore } from "@/stores/organization/entity/Organizations";
import { useUserStore } from "@/stores/user/User";
import { useRoute } from "vue-router";

const frontend = useFrontendStore();
const userStore = useUserStore();
const route = useRoute();
const organizations = await useOrganizationsStore().getAll();
</script>

<template>
  <nav v-if="userStore.isAuthenticated">
    <ul>
      <li v-if="organizations.length > 1">
        <RouterLink
          class="unstyled"
          :to="{ name: 'accountOrganizationsDashboard' }"
        >
          {{ frontend.trans("general.operation.account_organizations_dashboard") }}
        </RouterLink>
      </li>
      <li
        v-if="userStore.loggedUserInfo.email_verified_at != null 
          && route.name != 'createOrganization'"
      >
        <RouterLink class="unstyled" :to="{ name: 'organizations' }">
          {{ frontend.trans("general.operation.organizations") }}
        </RouterLink>
      </li>
      <li
        v-if="userStore.loggedUserInfo.capabilities.includes('administrator')"
      >
        <RouterLink class="unstyled" :to="{ name: 'administrationDashboard' }">
          {{ frontend.trans("general.operation.administration") }}
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
ul {
  @include font-default();
  display: flex;
  gap: var(--enlivy-spacing-divider-md);
  list-style-type: none;
}
</style>
