import type { Entity } from "@/interfaces/generic/Entity";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationIdMapEntityStorageData } from "@/interfaces/generic/storage/OrganizationIdMapEntityStorageData";
import { updateObject } from "@/lib/Object";
import { computed } from "vue";
import { EntityStorage } from "./EntityStorage";

export class IdMapEntityStorage<T extends Entity> extends EntityStorage<
  T,
  OrganizationIdMapEntityStorageData<T>
> {
  constructor() {
    super({} as OrganizationIdMapEntityStorageData<T>);
  }

  get(entityIdentifier: EntityIdentifier): T | undefined {
    if (entityIdentifier.id == undefined) {
      return undefined;
    }

    return this.storage.value?.[entityIdentifier.id];
  }

  getComputed(entityIdentifier: EntityIdentifier) {
    return computed(() => this.get(entityIdentifier));
  }

  set(
    entityIdentifier: EntityIdentifier,
    entity: T,
    initializationCallback?: (entityIdentifier: EntityIdentifier) => void
  ) {
    if (entityIdentifier.id == undefined) {
      // TODO: Throw error, set should not be called without all the identifiers present.
      return entity;
    }

    // If the entity is already in the store, update it.
    if (this.isInStore(entityIdentifier)) {
      const existingEntity = this.get(entityIdentifier);
      if (existingEntity != undefined) {
        updateObject(existingEntity, entity);
        return existingEntity;
      }
    } else if (!entity.deleted_at) {
      initializationCallback?.(entityIdentifier);
    }

    this.storage.value[entityIdentifier.id] = entity;

    return entity;
  }

  remove(entityIdentifier: EntityIdentifier): T | undefined {
    if (entityIdentifier.id == undefined) {
      return undefined;
    }

    const entity = this.get(entityIdentifier);
    delete this.storage.value?.[entityIdentifier.id];

    return entity;
  }
}
