import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationEntity } from "@/interfaces/organization/OrganizationEntity";
import { GenericEntityApi } from "./GenericEntityApi";

export abstract class OrganizationGenericEntityApi<
  T extends OrganizationEntity,
> extends GenericEntityApi<T> {
  prepareEntity(entity: any) {
    delete entity._entity;
    delete entity.organization_id;
  }

  fillWithEntityIdentifierFields(
    entityFromApi: any,
    entityIdentifier: EntityIdentifier
  ) {
    if (entityIdentifier.organizationId != undefined) {
      entityFromApi.organization_id = entityIdentifier.organizationId;
    }
  }

  async download(args: { entity: T; params?: any }) {
    return this.baseApiDownloadFile({
      url: this.getBaseUrl({ organizationId: args.entity.organization_id }),
      entityId: args.entity.id,
      params: args.params,
    });
  }
}
