import Error402Dialog from "@/components/dialogs/Error402Dialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import RefreshDialog from "@/components/dialogs/RefreshDialog.vue";
import type { ErrorResponse } from "@/interfaces/common/error/ErrorResponse";
import { openDialog } from "@/lib/Utils";
import { useAuthStore } from "@/stores/Auth";
import { useFrontendStore } from "@/stores/Frontend";
import { ref } from "vue";

export const criticalFetchErrorGlobalFlag = ref(false);

export function errorHandler(error: any) {
  // Unwrap promise error.
  if (error instanceof PromiseRejectionEvent) {
    if (error.reason?.cause?.code == "ERR_CANCELED") {
      error.preventDefault();
    }

    error = error.reason;
  }

  if (error.criticalFetch) {
    criticalFetchErrorGlobalFlag.value = true;
    return;
  }

  if (error.cause) {
    return handleApiError(error);
  }

  throw error;
}

// @TODO Will need to display these, in a dissmisable state, that does not stop the user - robert
function handleApiError(error: ErrorResponse) {
  // Check if there is an error message to display.
  if (!error.cause || !error.cause.response) {
    return;
  }

  // Skip 401 & 422 errors since these are already displayed in forms.
  if (
    error.cause.response?.status == 422 ||
    error.cause.response?.status == 401 ||
    !error.cause.response.data ||
    !(error.cause.response.data as any).message
  ) {
    return;
  }

  const frontend = useFrontendStore();
  if (error.cause.response?.status == 402) {
    openDialog(Error402Dialog, {
      title: frontend.trans("general.title.error"),
      error: (error.cause.response.data as any).message,
    });
    return;
  }

  openDialog(ErrorDialog, {
    title: frontend.trans("general.title.error"),
    text: frontend.trans(
      "general.error.link_access",
      true,
      `${error.cause.config?.method?.toUpperCase()} ${error.cause.config?.url}`
    ),
    error: (error.cause.response.data as any).message,
  });
}

export function errorHandlerForNewApplicationVersion(swEvent?: any) {
  const authStore = useAuthStore();
  const frontend = useFrontendStore();

  openDialog(RefreshDialog, {
    title: frontend.trans("general.notification.application_update_title"),
    text: frontend.trans("general.notification.application_update_description"),
  }).then((result) => {
    if (result) {
      if (swEvent) {
        // The call is from the Service Worker
        const swRegistration = swEvent.detail;
        if (!swRegistration || !swRegistration.waiting) {
          return;
        }

        swRegistration.waiting.postMessage({ type: "SKIP_WAITING" });
        setTimeout(() => {
          swRegistration.waiting.postMessage({ type: "REFRESH_ALL_WINDOWS" });
        }, 300);
      } else {
        // The call is from the Vue App
        window.location.reload();
        authStore.authChannel.post("refresh-event");
      }
    }
  });
}
