import { useOrganizationTaxRateApi } from "@/api/organization/OrganizationTaxRateApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationTaxClass } from "@/interfaces/organization/tax/tax-classes/OrganizationTaxClass";
import type { OrganizationTaxRate } from "@/interfaces/organization/tax/tax-rates/OrganizationTaxRate";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { useOrganizationTaxClassesStore } from "./OrganizationTaxClasses";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationTaxRatesStore = defineStore(
  "organizationTaxRates",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationTaxRate>();
    const pageCache = new OrganizationCache<OrganizationTaxRate>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationTaxRate>(
        "tax_rate",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationTaxRate>({
      storage: storage,
      pageCache: pageCache,
      entityApi: useOrganizationTaxRateApi(),
      enhanceEntity: enhanceEntity,
      initializationCallback: subscribeToSocket,
    });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationTaxRate,
  storage: OrganizationEntityStorage<OrganizationTaxRate>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  const organizationTaxClassesStore = useOrganizationTaxClassesStore();
  const organizationUserStore = useOrganizationUsersStore();

  addComputedField<OrganizationTaxRate, OrganizationTaxClass>(
    entity,
    "taxClass",
    () => storage.get(entityIdentifier)?.organization_tax_class_id,
    async () =>
      organizationTaxClassesStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_tax_class_id,
      })
  );

  addComputedField<OrganizationTaxRate, OrganizationUser>(
    entity,
    "deletedByUser",
    () => storage.get(entityIdentifier)?.deleted_by_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.deleted_by_user_id,
      })
  );

  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.name);
}
