<script setup lang="ts">
import { useFrontendStore } from "@/stores/Frontend";
import { nextTick, onMounted, onUpdated, ref, watch } from "vue";

const emit = defineEmits(["entity-deleted"]);
const props = defineProps<{
  maxRows?: number;
}>();

const frontend = useFrontendStore();
const rows = ref([] as any[]);
const tableInnerWrapper = ref<any>(null);

const shadows = ref({
  top: { elem: undefined, scroll: 0, visible: false },
  right: { elem: undefined, scroll: 0, visible: false },
  bottom: { elem: undefined, scroll: 0, visible: false },
  left: { elem: undefined, scroll: 0, visible: false },
} as any);

const searchQuery = ref("");

// For some reason nextTick is not enough when first loading the page.
// TODO: Find a solution that doesn't rely on a timeout.
watch(
  () => frontend.windowWidth,
  () => {
    adjustFixedRows();
    setTimeout(() => {
      adjustFixedRows();
    }, 500);
  }
);

onMounted(() => {
  adjustFixedRows();
  setTimeout(() => {
    adjustFixedRows();
  }, 500);
});

onUpdated(() => {
  adjustFixedRows();
  setTimeout(() => {
    adjustFixedRows();
  }, 500);
});

function adjustFixedRows() {
  nextTick(() => {
    if (!rows.value || rows.value.length == 0) {
      return;
    }

    if (props.maxRows) {
      let height = 0;
      for (const rowIndex in rows.value) {
        if (Number(rowIndex) < props.maxRows) {
          const row = rows.value[rowIndex];
          height += row.clientHeight;
        }
      }

      if (height != 0) {
        tableInnerWrapper.value.style.maxHeight = height + "px";
      }
    }
  });
}

function addRows(parent: any) {
  if (parent) {
    rows.value.push(...parent.children);
  }
}

function updateSearchQuery(newValue: string) {
  searchQuery.value = newValue ? newValue : "";
  for (const row of rows.value) {
    // Skip the rows in header
    if (row.parentElement.nodeName == "THEAD") {
      continue;
    }

    if (row.textContent.includes(searchQuery.value)) {
      row.style.display = "table-row";
    } else {
      row.style.display = "none";
    }
  }
}

function updateShadows() {
  if (!tableInnerWrapper.value) {
    return;
  }

  shadows.value.top.visible =
    tableInnerWrapper.value.querySelectorAll("thead").length != 0;
  shadows.value.right.visible =
    tableInnerWrapper.value.querySelectorAll('[data-align="right"]').length !=
    0;
  shadows.value.bottom.visible =
    tableInnerWrapper.value.querySelectorAll('[data-align="bottom"]').length !=
    0;
  shadows.value.left.visible =
    tableInnerWrapper.value.querySelectorAll('[data-align="left"]').length != 0;

  shadows.value.top.scroll = tableInnerWrapper.value.scrollTop;
  shadows.value.right.scroll = Math.floor(
    tableInnerWrapper.value.scrollWidth -
      tableInnerWrapper.value.offsetWidth -
      tableInnerWrapper.value.scrollLeft -
      1.0
  );
  shadows.value.bottom.scroll = Math.floor(
    tableInnerWrapper.value.scrollHeight -
      tableInnerWrapper.value.offsetHeight -
      tableInnerWrapper.value.scrollTop -
      1.0
  );
  shadows.value.left.scroll = tableInnerWrapper.value.scrollLeft;

  const firstCell = rows.value[0].children[0];
  const lastCell = rows.value[rows.value.length - 1].lastChild;

  shadows.value.top.elem.style.top = firstCell.offsetHeight - 4 + "px";
  shadows.value.right.elem.style.right = lastCell.offsetWidth - 3 + "px";
  shadows.value.bottom.elem.style.bottom = lastCell.offsetHeight - 4 + "px";
  shadows.value.left.elem.style.left = firstCell.offsetWidth - 3 + "px";
}

function handleEntityDeleted(entity: any) {
  emit("entity-deleted", entity);
}
</script>

<template>
  <div class="table-outer-wrapper">
    <div
      class="table-inner-wrapper"
      ref="tableInnerWrapper"
      @scroll="updateShadows"
    >
      <table>
        <thead :ref="addRows">
          <slot
            name="head"
            :searchQuery="searchQuery"
            :updateSearchQuery="updateSearchQuery"
          ></slot>
        </thead>
        <slot name="tbody" :onEntityDeleted="handleEntityDeleted">
          <tbody :ref="addRows">
            <slot name="rows" :onEntityDeleted="handleEntityDeleted"></slot>
          </tbody>
        </slot>

        <slot name="t-foot"> </slot>
      </table>
    </div>

    <div
      class="table-shadow"
      data-align="row"
      data-side="top"
      :ref="(el) => (shadows.top.elem = el)"
      v-show="shadows.top.visible && shadows.top.scroll > 0"
    ></div>
    <div
      class="table-shadow"
      data-align="col"
      data-side="right"
      :ref="(el) => (shadows.right.elem = el)"
      v-show="shadows.right.visible && shadows.right.scroll > 0"
    ></div>
    <div
      class="table-shadow"
      data-align="row"
      data-side="bottom"
      :ref="(el) => (shadows.bottom.elem = el)"
      v-show="shadows.bottom.visible && shadows.bottom.scroll > 0"
    ></div>
    <div
      class="table-shadow"
      data-align="col"
      data-side="left"
      :ref="(el) => (shadows.left.elem = el)"
      v-show="shadows.left.visible && shadows.left.scroll > 0"
    ></div>
  </div>
</template>

<style scoped lang="scss">
.table-outer-wrapper {
  position: relative;
  .table-shadow {
    position: absolute;
    top: 0;
    &[data-align="col"] {
      width: 1px;
      height: 100%;
    }
    &[data-align="row"] {
      width: 100%;
      height: 1px;
    }

    &[data-side="top"] {
      box-shadow: 0 3px 3px 0px;
    }
    &[data-side="right"] {
      box-shadow: -3px 0 3px 0px;
    }
    &[data-side="bottom"] {
      box-shadow: 0 -3px 3px 0px;
    }
    &[data-side="left"] {
      box-shadow: 3px 0 3px 0px;
    }
  }
}

.table-inner-wrapper {
  position: relative;
  width: 100%;
  overflow: auto;
  table {
    width: 100%;
    thead {
      :deep(th) {
        z-index: 2;
        background-color: var(--enlivy-white-100-color);
        position: sticky;
        top: -1px;
      }
    }
    tbody {
      :deep(tr) {
        @include table-tr();
      }
    }
  }
}
</style>
