import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationReportSchemaField } from "@/interfaces/organization/report/OrganizationReportSchemaField";

class OrganizationReportSchemaFieldApi extends OrganizationGenericEntityApi<OrganizationReportSchemaField> {
  apiIdentifier = "organization_report_schema_field";

  getBaseUrl(args: { organizationId: string; reportSchemaId: string }) {
    return `/organizations/${args.organizationId}/report-schemas/${args.reportSchemaId}/fields`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(
      entityFromApi,
      "organization_report_schema_field"
    )
      .asOrganizationEntity()
      .asId("organization_report_schema_id")
      .build() as OrganizationReportSchemaField;
  }

  prepareEntity(entity: any) {
    super.prepareEntity(entity);
    delete entity.organization_report_schema_id;
  }

  fillWithEntityIdentifierFields(
    entityFromApi: any,
    entityIdentifier: EntityIdentifier
  ) {
    super.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);

    if (entityIdentifier.reportSchemaId != undefined) {
      entityFromApi.organization_report_schema_id =
        entityIdentifier.reportSchemaId;
    }
  }
}

export function useOrganizationReportSchemaFieldApi() {
  return new OrganizationReportSchemaFieldApi();
}
