import { AdministrationGenericEntityApi } from "@/api/generic/AdministrationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { AdministrationUserAccountType } from "@/interfaces/administration/user-account-type/AdministrationUserAccountType";

class AdministrationUserAccountTypeApi extends AdministrationGenericEntityApi<AdministrationUserAccountType> {
  apiIdentifier = "administration_user_account_type";

  getBaseUrl(): string {
    return `/user-account-types`;
  }
  parseEntity(entityFromApi: any): AdministrationUserAccountType {
    return new ApiResponseParser(
      entityFromApi,
      "administration_user_account_type"
    )
      .asId("id")
      .asDate("created_at")
      .asDate("updated_at")
      .asDate("deleted_at")
      .asId("default_membership_plan_id")
      .asId("deleted_by_user_id")
      .asBool("is_administrator")
      .asBool("is_consultant")
      .asBool("is_default")
      .build() as AdministrationUserAccountType;
  }
}

export function useAdministrationUserAccountTypeApi() {
  return new AdministrationUserAccountTypeApi();
}
