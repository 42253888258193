import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationPayslip } from "@/interfaces/organization/payslips/OrganizationPayslip";

class OrganizationPayslipApi extends OrganizationGenericEntityApi<OrganizationPayslip> {
  apiIdentifier = "organization_payslip";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/payslips`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_payslip")
      .asOrganizationEntity()
      .asId("organization_payslip_schema_id")
      .asId("organization_receiver_user_id")
      .asId("organization_sender_user_id")
      .asId("deleted_by_user_id")
      .asNumber("net_total")
      .asNumber("total")
      .asDate("issued_at")
      .asDate("paid_at")
      .build() as OrganizationPayslip;
  }

  getDefaultInclude(): string[] {
    return [];
  }
}

export function useOrganizationPayslipApi() {
  return new OrganizationPayslipApi();
}
