import { useOrganizationInvoiceNetworkExchangeApi } from "@/api/organization/OrganizationInvoiceNetworkExchangeApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationInvoice } from "@/interfaces/organization/invoice/OrganizationInvoice";
import type { OrganizationInvoiceNetworkExchange } from "@/interfaces/organization/invoice/OrganizationInvoiceNetworkExchange";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { defineStore } from "pinia";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";
import { useOrganizationInvoicesStore } from "./OrganizationInvoices";

export const useOrganizationInvoiceNetworkExchangesStore = defineStore(
  "organizationInvoiceNetworkExchanges",
  () => {
    const storage =
      new OrganizationEntityStorage<OrganizationInvoiceNetworkExchange>();
    const pageCache =
      new OrganizationCache<OrganizationInvoiceNetworkExchange>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationInvoiceNetworkExchange>(
        "network-exchanges",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions =
      new OrganizationGenericActions<OrganizationInvoiceNetworkExchange>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationInvoiceNetworkExchangeApi(),
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
      });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationInvoiceNetworkExchange,
  storage: OrganizationEntityStorage<OrganizationInvoiceNetworkExchange>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  const organizationInvoicesStore = useOrganizationInvoicesStore();

  addComputedField<OrganizationInvoiceNetworkExchange, OrganizationInvoice>(
    entity,
    "invoice",
    () => storage.get(entityIdentifier)?.organization_invoice_id,
    async () =>
      organizationInvoicesStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_invoice_id,
      })
  );

  setEntityNameFields(entity, () => {
    return storage.get(entityIdentifier)?.id;
  });
}
