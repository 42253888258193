<script setup lang="ts">
import LoaderMiniSpinnerTimed from "@/components/loaders/LoaderMiniSpinnerTimed.vue";
import { ref } from "vue";

const emit = defineEmits(["clicked"]);
const props = withDefaults(defineProps<{
  disabled?: boolean;
  loaderOnClick?: boolean;
  loaderColor?: string;
}>(), {
  disabled: false,
  loaderOnClick: false,
});

const inputSearchLoaderRef = ref();

function onClick() {
  if (props.disabled) {
    return;
  }

  if (props.loaderOnClick) {
      inputSearchLoaderRef.value.resetAnimation();
  }

  emit("clicked");
}
</script>

<template>
  <button @click.prevent="onClick" :class="{ disabled: disabled }">
    <span v-if="$slots.default" class="text">
      <slot></slot>
    </span>
    <LoaderMiniSpinnerTimed
      ref="inputSearchLoaderRef"
      :iconColor="loaderColor"
    />
  </button>
</template>

<style scoped lang="scss">
button {
  @include enlivy-button();
  @include enlivy-button-primary();

  &.with_icon {
    > svg {
      @include svg-color( var( --enlivy-text-plan-color ) );
    }
  }

  &.secondary {
    @include enlivy-button-secondary();
  }
  &.unstyled {
    @include font-large();
    color: var(--enlivy-grey-100-color);
    background: none;
    padding: 0;
    min-height: auto;
  }
  > span {
    display: flex;
    align-items: center;
    gap: var(--enlivy-spacing-md);
    flex-direction: row-reverse;
  }
}
</style>
