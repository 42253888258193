import { useUserApiTokenApi } from "@/api/user/UserApiTokenApi";
import type { UserApiToken } from "@/interfaces/user/UserApiToken";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { OrganizationCache } from "../generic/cache/OrganizationCache";
import { IdMapEntityStorage } from "../generic/storage/IdMapEntityStorage";
import { OrganizationGenericActions } from "../generic/store/OrganizationGenericActions";

export const useUserApiTokensStore = defineStore("userApiTokens", () => {
  const storage = new IdMapEntityStorage<UserApiToken>();
  const pageCache = new OrganizationCache<UserApiToken>();

  const genericActions = new OrganizationGenericActions<UserApiToken>({
    storage: storage,
    pageCache: pageCache,
    entityApi: useUserApiTokenApi(),
    enhanceEntity: enhanceEntity,
  });

  return {
    ...genericActions,
  };
});

function enhanceEntity(
  entity: UserApiToken,
  storage: IdMapEntityStorage<UserApiToken>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.name);
}
