import { useOrganizationReportSchemaApi } from "@/api/organization/OrganizationReportSchemaApi";
import type { OrganizationReportSchema } from "@/interfaces/organization/report/OrganizationReportSchema";
import type { OrganizationReportSchemaField } from "@/interfaces/organization/report/OrganizationReportSchemaField";
import type { OrganizationUserRole } from "@/interfaces/organization/user/OrganizationUserRole";
import { deepClone, isEmpty } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { useOrganizationReportSchemaFieldsStore } from "./OrganizationReportSchemaFields";
import { useOrganizationUserRolesStore } from "./OrganizationUserRoles";

export const useOrganizationReportSchemasStore = defineStore(
  "organizationReportSchemas",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationReportSchema>();
    const pageCache = new OrganizationCache<OrganizationReportSchema>();
    const api = useOrganizationReportSchemaApi();
    const fieldsStore = useOrganizationReportSchemaFieldsStore();

    const genericActions =
      new OrganizationGenericActions<OrganizationReportSchema>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
        enhanceEntity: enhanceEntity,
        preStore: preStore,
        prePersist: prePersist,
      });

    const updateFields = async (
      reportSchemaId: string,
      fields: OrganizationReportSchemaField[]
    ) => {
      const storedFields = [] as OrganizationReportSchemaField[];

      for (const index in fields) {
        const { conditional_logic_settings, builder_id, ...field } = deepClone(
          fields[index]
        ) as OrganizationReportSchemaField;
        field.organization_report_schema_id = reportSchemaId;

        const isCreate = !field.id;
        const storedField = !field.id
          ? await fieldsStore.create(field)
          : (field as OrganizationReportSchemaField);

        if (conditional_logic_settings?.rules) {
          // Replace the builder field id with the id stored in the DB
          conditional_logic_settings.rules =
            conditional_logic_settings.rules.map((rule) => {
              if (rule.field_id) {
                const storedField = storedFields.find(
                  (f) => f.builder_id === rule.field_id
                );

                if (storedField) {
                  rule.field_id = storedField.id;
                } else {
                  // @TODO handle this case where the order of the fields is changed unexpectedly
                }
              }
              return rule;
            });
        }

        let finalField = deepClone(storedField);
        storedField.conditional_logic_settings =
          conditional_logic_settings || (null as any);

        if (!isCreate || conditional_logic_settings) {
          finalField = await fieldsStore.update(storedField);
        }

        finalField.builder_id = builder_id;
        storedFields.push(finalField);
      }
      return storedFields;
    };

    const create = async (reportSchema: OrganizationReportSchema) => {
      const { report_schema_fields, ...entity } = deepClone(
        reportSchema
      ) as OrganizationReportSchema;
      delete entity.userRole;

      const fields = report_schema_fields || [];

      const createdReportSchema = await genericActions.create(
        entity as OrganizationReportSchema
      );

      createdReportSchema.report_schema_fields = await updateFields(
        createdReportSchema.id,
        fields
      );

      return createdReportSchema;
    };

    const update = async (reportSchema: OrganizationReportSchema) => {
      const { report_schema_fields, ...entity } = deepClone(
        reportSchema
      ) as OrganizationReportSchema;
      delete entity.userRole;

      const fields = report_schema_fields || [];

      // Delete fields that are no longer present.
      const existingFields = fields.map((field) => field.id);
      const existingEntity = storage.get(getEntityIdentifier(entity));
      for (const field of existingEntity?.report_schema_fields || []) {
        if (!existingFields.includes(field.id)) {
          await fieldsStore.delete(field);
        }
      }

      const updatedReportSchema = await genericActions.update(
        entity as OrganizationReportSchema
      );

      updatedReportSchema.report_schema_fields = await updateFields(
        updatedReportSchema.id,
        fields
      );

      return updatedReportSchema;
    };

    return {
      ...genericActions,
      create,
      update,
    };
  }
);

function enhanceEntity(
  entity: OrganizationReportSchema,
  storage: OrganizationEntityStorage<OrganizationReportSchema>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const userRolesStore = useOrganizationUserRolesStore();
  addComputedField<OrganizationReportSchema, OrganizationUserRole>(
    entity,
    "userRole",
    () => storage.get(entityIdentifier)?.organization_user_role_id,
    async () =>
      userRolesStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_user_role_id,
      })
  );

  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.title);
}

function preStore(entity: OrganizationReportSchema) {
  const fieldsStore = useOrganizationReportSchemaFieldsStore();
  if (entity.report_schema_fields) {
    fieldsStore.storeEntities(entity.report_schema_fields);
  }
}

function prePersist(
  entity: OrganizationReportSchema,
  existingEntity?: OrganizationReportSchema
) {
  // If it's an update operation, mark sub-entities removed for deletion.
  if (existingEntity) {
    const remainingEntityLanguages =
      entity.title_lang_map && !isEmpty(entity.title_lang_map)
        ? Object.keys(entity.title_lang_map)
        : [];

    for (const lang in existingEntity.title_lang_map) {
      if (!remainingEntityLanguages.includes(lang)) {
        if (!entity.title_lang_map) {
          entity.title_lang_map = {};
          entity.description_lang_map = {};
          entity.instructions_lang_map = {};
        }

        entity.title_lang_map[lang] = "";
        entity.description_lang_map[lang] = "";
        entity.instructions_lang_map[lang] = "";
      }
    }
  }
}
