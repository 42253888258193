import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationUserAddress } from "@/interfaces/organization/user/OrganizationUserAddress";

class OrganizationUserAddressApi extends OrganizationGenericEntityApi<OrganizationUserAddress> {
  apiIdentifier = "organization_user_address";

  getBaseUrl(args: { organizationId: string; userId: string }) {
    return `/organizations/${args.organizationId}/users/${args.userId}/addresses`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_user_address")
      .asOrganizationEntity()
      .asId("organization_user_id")
      .asId("deleted_by_user_id")
      .build() as OrganizationUserAddress;
  }

  prepareEntity(entity: any) {
    super.prepareEntity(entity);
    delete entity.organization_user_id;
  }

  fillWithEntityIdentifierFields(
    entityFromApi: any,
    entityIdentifier: EntityIdentifier
  ) {
    super.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);

    if (entityIdentifier.userId != undefined) {
      entityFromApi.organization_user_id = entityIdentifier.userId;
    }
  }
}

export function useOrganizationUserAddressApi() {
  return new OrganizationUserAddressApi();
}
