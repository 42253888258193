import { useOrganizationBankAccountApi } from "@/api/organization/OrganizationBankAccountApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationBankAccount } from "@/interfaces/organization/bank/OrganizationBankAccount";
import type { OrganizationBankAccountDataAccount } from "@/interfaces/organization/bank/account-data/OrganizationBankAccountDataAccount";
import type { OrganizationBankAccountDataBridge } from "@/interfaces/organization/bank/account-data/OrganizationBankAccountDataBridge";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useFrontendStore } from "../../Frontend";
import { useGenericPusherUtils } from "../../generic/GenericPusherUtils";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";
import { useUserStore } from "../../user/User";
import { useOrganizationBankAccountDataStore } from "./OrganizationBankAccountData";

export const useOrganizationBankAccountsStore = defineStore(
  "organizationBankAccounts",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationBankAccount>();
    const pageCache = new OrganizationCache<OrganizationBankAccount>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationBankAccount>(
        "bank_account",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions =
      new OrganizationGenericActions<OrganizationBankAccount>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationBankAccountApi(),
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
        prePersist: prePersist,
      });

    return {
      ...genericActions,
    };
  }
);

export async function enhanceEntity(
  entity: OrganizationBankAccount,
  storage: OrganizationEntityStorage<OrganizationBankAccount>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const userStore = useUserStore();
  const organization = userStore.getActiveOrganization();

  if (
    organization.value?.membership_features?.includes("bank_account_data") &&
    userStore.hasAccess("bank_account_data")
  ) {
    const bankAccountDataStore = useOrganizationBankAccountDataStore();
    addComputedField<
      OrganizationBankAccount,
      OrganizationBankAccountDataBridge
    >(
      entity,
      "bankAccountDataBridge",
      () =>
        storage.get(entityIdentifier)?.organization_bank_account_data_bridge_id,
      async () => {
        return await bankAccountDataStore.getById({
          organizationId: entityIdentifier.organizationId as string,
          id: storage.get(entityIdentifier)
            ?.organization_bank_account_data_bridge_id as string,
        } as EntityIdentifier);
      }
    );

    addComputedField<
      OrganizationBankAccount,
      OrganizationBankAccountDataAccount
    >(
      entity,
      "bankAccountDataAccount",
      () =>
        `${
          storage.get(entityIdentifier)
            ?.organization_bank_account_data_bridge_id
        }-${
          storage.get(entityIdentifier)
            ?.organization_bank_account_data_bridge_account
        }`,
      async () => {
        const entity = storage.get(entityIdentifier);
        if (!entity?.organization_bank_account_data_bridge_id) {
          return ref(undefined);
        }

        const accounts = await bankAccountDataStore.getAccount(
          entityIdentifier.organizationId as string,
          entity.organization_bank_account_data_bridge_id
        );

        return ref(
          accounts[
            entity.organization_bank_account_data_bridge_account as string
          ]
        );
      }
    );
  }

  setEntityNameFields(
    entity,
    () => storage.get(getEntityIdentifier(entity))?.name
  );
}

function prePersist(bankAccount: OrganizationBankAccount) {
  // Get allowed fields for this country.
  const frontend = useFrontendStore();
  const informationFields = frontend.getBankAccountInformationByType(
    bankAccount.bank_country_code,
    bankAccount.type
  );

  const infoKeys = [];
  for (const field of informationFields.account_information.values()) {
    infoKeys.push(field.alias);
  }

  // Remove extra fields that should not be sent.
  if (Object.keys(bankAccount.account_information).length > 0) {
    for (const propKey of Object.keys(bankAccount.account_information)) {
      if (infoKeys.indexOf(propKey) == -1) {
        delete bankAccount.account_information[propKey];
      }
    }
  }
}
