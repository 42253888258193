import type { GenericEntityApi } from "@/api/generic/GenericEntityApi";
import type { Entity } from "@/interfaces/generic/Entity";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { PaginatedCacheValue } from "@/interfaces/generic/PaginatedCacheValue";
import type { Organization } from "@/interfaces/organization/Organization";
import type { OrganizationEntity } from "@/interfaces/organization/OrganizationEntity";
import { isObject } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { addComputedField } from "@/lib/generic/StoreUtils";
import { computed } from "vue";
import { useOrganizationsStore } from "../../organization/entity/Organizations";
import { useUserStore } from "../../user/User";
import type { GenericCache } from "../cache/GenericCache";
import type { EntityStorage } from "../storage/EntityStorage";
import type { OrganizationEntityStorage } from "../storage/OrganizationEntityStorage";
import { GenericActions } from "./GenericActions";

export class OrganizationGenericActions<
  T extends Entity
> extends GenericActions<T> {
  protected mandatoryKeys = ["_entity", "id", "organization_id"];

  constructor(args: {
    storage: EntityStorage<T, any>;
    pageCache: GenericCache<PaginatedCacheValue<T>>;
    entityApi: GenericEntityApi<T>;
    mandatoryKeys?: string[];
    ignoredKeysForDeletion?: string[];
    enhanceEntity?: (entity: T, storage: EntityStorage<T, any>) => void;
    initializationCallback?: (entityIdentifier: EntityIdentifier) => void;
    preStore?: (entity: T) => void;
    prePersist?: (entity: T, existingEntity?: T) => void;
  }) {
    super(args);

    if (args.mandatoryKeys) {
      this.mandatoryKeys = args.mandatoryKeys;
    }

    this.ignoredKeysForDeletion = Object.assign(
      this.ignoredKeysForDeletion,
      args.ignoredKeysForDeletion
    );
  }

  storeEntities = (entities: T[]) => {
    const result = [] as T[];

    for (const entity of entities) {
      if (this.preStore) {
        this.preStore(entity);
      }

      entity.stale = false;

      const entityIdentifier = getEntityIdentifier(entity);
      const storedEntity = this.storage.set(
        entityIdentifier,
        entity,
        this.initializationCallback
      );

      if (entityIdentifier.organizationId != undefined) {
        const organizationEntityStorage = this
          .storage as unknown as OrganizationEntityStorage<OrganizationEntity>;
        const organizationEntity = organizationEntityStorage.get(
          entityIdentifier
        ) as OrganizationEntity;

        // Add organization field
        const organizationsStore = useOrganizationsStore();
        addComputedField<OrganizationEntity, Organization>(
          organizationEntity,
          "organization",
          () =>
            organizationEntityStorage.get(entityIdentifier)?.organization_id,
          async () =>
            organizationsStore.lazyGetById({
              id: organizationEntityStorage.get(entityIdentifier)
                ?.organization_id,
            })
        );
      }

      if (this.enhanceEntity) {
        this.enhanceEntity(storedEntity, this.storage);
      }

      // Compute certain properties from other entity properties
      this.computeProperties(storedEntity);

      result.push(storedEntity);
    }

    return result;
  };

  getPage = async (args: {
    entityIdentifier: EntityIdentifier;
    useCache: boolean;
    page?: number;
    limit?: number;
    deleted?: number;
    params?: { [key: string]: any };
    withoutIncludes?: boolean;
  }) => {
    const { entityIdentifier: constEntityIdentifier, ...extraArgs } = args;
    let entityIdentifier = constEntityIdentifier;

    if (!isObject(entityIdentifier)) {
      entityIdentifier = {};
    }

    if (!("organizationId" in entityIdentifier)) {
      const organization = useUserStore().getActiveOrganization();
      entityIdentifier.organizationId = organization.value?.id;
    }

    const queryParams = { entityIdentifier, ...extraArgs };
    const cacheKey = `${entityIdentifier.organizationId}.${JSON.stringify(
      queryParams
    )}`;

    const cachedResponse = this.pageCache.get(cacheKey);
    if (args.useCache && cachedResponse != undefined) {
      return cachedResponse;
    }

    const response = await this.entityApi.get(queryParams);
    const storedEntities = this.storeEntities(response.entities);

    return this.pageCache.set(cacheKey, {
      entities: this.getEntityRefs(storedEntities),
      pagination: response.pagination,
      navigation: response.navigation,
      navigationByState: response.navigationByState,
    });
  };

  getByIds = async (ids: string[], groupIdentifier?: EntityIdentifier) => {
    const identifier = groupIdentifier ? groupIdentifier : {};

    if (!identifier.organizationId) {
      const activeOrganization = useUserStore().getActiveOrganization();
      if (activeOrganization.value) {
        identifier.organizationId = activeOrganization.value.id;
      }
    }

    if (identifier.organizationId) {
      await this.queueIds(identifier, ids);
    }

    return computed(() => {
      const result = [] as T[];
      if (this.storage.isInStore(identifier)) {
        for (const entityId of ids) {
          const entityIdentifier = { id: entityId, ...identifier };
          if (this.storage.isInStore(entityIdentifier)) {
            result.push(this.storage.get(entityIdentifier) as T);
          }
        }
      }

      return result;
    });
  };
}
