import { BaseApi } from "@/api/generic/BaseApi";
import type { OrganizationDataPushJob } from "@/interfaces/organization/OrganizationDataPushJob";

class OrganizationDataPushApi extends BaseApi {
  apiIdentifier = "organization_data_push";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/data-push`;
  }

  async list(args: { organizationId: string }) {
    const response = await this.baseApiGetEntities({
      url: `${this.getBaseUrl({
        organizationId: args.organizationId,
      })}`,
    });

    return response.data as OrganizationDataPushJob[];
  }

  async get(args: { organizationId: string; id: string }) {
    const response = await this.baseApiGetEntityById({
      url: `${this.getBaseUrl({
        organizationId: args.organizationId,
      })}`,
      id: args.id,
    });

    return response as OrganizationDataPushJob;
  }

  async uploadBankAccountStatement(args: {
    organizationId: string;
    data: { [key: string]: any };
  }) {
    const response = await this.baseApiPostEntity({
      url: `${this.getBaseUrl({
        organizationId: args.organizationId,
      })}/bank_account_statement_upload`,
      entity: args.data,
    });

    return response as OrganizationDataPushJob;
  }
}

export function useOrganizationDataPushApi() {
  return new OrganizationDataPushApi();
}
