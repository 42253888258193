import type { AdministrationConsoleJob } from "@/interfaces/administration/console-job/AdministrationConsoleJob";
import type { AdministrationPricingPlan } from "@/interfaces/administration/membership/AdministrationPricingPlan";
import type { AdministrationUserAccountType } from "@/interfaces/administration/user-account-type/AdministrationUserAccountType";
import type { AdministrationUserInvitationCode } from "@/interfaces/administration/user-invitation-codes/AdministrationUserInvitationCode";
import type { Entity } from "@/interfaces/generic/Entity";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { Organization } from "@/interfaces/organization/Organization";
import type { OrganizationFile } from "@/interfaces/organization/OrganizationFile";
import type { OrganizationProduct } from "@/interfaces/organization/OrganizationProduct";
import type { OrganizationReceipt } from "@/interfaces/organization/OrganizationReceipt";
import type { OrganizationTag } from "@/interfaces/organization/OrganizationTag";
import type { OrganizationBankAccount } from "@/interfaces/organization/bank/OrganizationBankAccount";
import type { OrganizationBankTransaction } from "@/interfaces/organization/bank/OrganizationBankTransaction";
import type { OrganizationBankTransactionCostType } from "@/interfaces/organization/bank/OrganizationBankTransactionCostType";
import type { OrganizationBankTransactionImport } from "@/interfaces/organization/bank/OrganizationBankTransactionImport";
import type { OrganizationBankAccountDataBridge } from "@/interfaces/organization/bank/account-data/OrganizationBankAccountDataBridge";
import type { OrganizationContract } from "@/interfaces/organization/contract/OrganizationContract";
import type { OrganizationContractStatus } from "@/interfaces/organization/contract/OrganizationContractStatus";
import type { OrganizationExportEntry } from "@/interfaces/organization/export/OrganizationExportEntry";
import type { OrganizationInvoice } from "@/interfaces/organization/invoice/OrganizationInvoice";
import type { OrganizationInvoiceNetworkExchange } from "@/interfaces/organization/invoice/OrganizationInvoiceNetworkExchange";
import type { OrganizationInvoiceNotificationLog } from "@/interfaces/organization/invoice/OrganizationInvoiceNotificationLog";
import type { OrganizationInvoicePrefix } from "@/interfaces/organization/invoice/OrganizationInvoicePrefix";
import type { OrganizationMembershipInvoice } from "@/interfaces/organization/membership/OrganizationMembershipInvoice";
import type { OrganizationMembershipPaymentMethod } from "@/interfaces/organization/membership/OrganizationMembershipPaymentMethod";
import type { OrganizationPayslip } from "@/interfaces/organization/payslips/OrganizationPayslip";
import type { OrganizationPayslipSchema } from "@/interfaces/organization/payslips/OrganizationPayslipSchema";
import type { OrganizationReport } from "@/interfaces/organization/report/OrganizationReport";
import type { OrganizationReportSchema } from "@/interfaces/organization/report/OrganizationReportSchema";
import type { OrganizationReportSchemaField } from "@/interfaces/organization/report/OrganizationReportSchemaField";
import type { OrganizationTask } from "@/interfaces/organization/task/OrganizationTask";
import type { OrganizationTaxClass } from "@/interfaces/organization/tax/tax-classes/OrganizationTaxClass";
import type { OrganizationTaxRate } from "@/interfaces/organization/tax/tax-rates/OrganizationTaxRate";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import type { OrganizationUserActivity } from "@/interfaces/organization/user/OrganizationUserActivity";
import type { OrganizationUserAddress } from "@/interfaces/organization/user/OrganizationUserAddress";
import type { OrganizationUserRole } from "@/interfaces/organization/user/OrganizationUserRole";
import type { OrganizationWebhook } from "@/interfaces/organization/webhook/OrganizationWebhook";
import type { OrganizationWebhookNotification } from "@/interfaces/organization/webhook/OrganizationWebhookNotification";
import type { User } from "@/interfaces/user/User";
import type { UserApiToken } from "@/interfaces/user/UserApiToken";
import type { UserOrganizationSetting } from "@/interfaces/user/UserOrganizationSetting";
import type { OrganizationBillingSchedule } from "@/interfaces/organization/billing-schedule/OrganizationBillingSchedule";

// Map used to get the identifier for an entity.
const entityIdentifierMap: {
  [key: string]: (entity: any) => EntityIdentifier;
} = {
  administration_membership: (entity: AdministrationPricingPlan) => ({
    id: entity.id,
  }),
  administration_user_account_type: (
    entity: AdministrationUserAccountType
  ) => ({
    id: entity.id,
  }),
  administration_user_invitation_code: (
    entity: AdministrationUserInvitationCode
  ) => ({
    id: entity.id,
  }),
  administration_console_job: (entity: AdministrationConsoleJob) => ({
    id: entity.id,
  }),

  organization: (entity: Organization) => ({ id: entity.id }),
  organization_export_entry: (entity: OrganizationExportEntry) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_bank_account: (entity: OrganizationBankAccount) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_bank_transaction: (entity: OrganizationBankTransaction) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_bank_transaction_import: (
    entity: OrganizationBankTransactionImport
  ) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_bank_transaction_cost_type: (
    entity: OrganizationBankTransactionCostType
  ) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_billing_schedule: (entity: OrganizationBillingSchedule) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_contract: (entity: OrganizationContract) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_contract_status: (entity: OrganizationContractStatus) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_receipt: (entity: OrganizationReceipt) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_file: (entity: OrganizationFile) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_invoice: (entity: OrganizationInvoice) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_invoice_prefix: (entity: OrganizationInvoicePrefix) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_invoice_notification_log: (
    entity: OrganizationInvoiceNotificationLog
  ) => ({
    id: entity.id,
    organizationId: entity.organization_id,
    invoiceId: entity.organization_invoice_id,
  }),
  organization_invoice_network_exchange: (
    entity: OrganizationInvoiceNetworkExchange
  ) => ({
    id: entity.id,
    organizationId: entity.organization_id,
    invoiceId: entity.organization_invoice_id,
  }),
  organization_bank_account_data_bridge: (
    entity: OrganizationBankAccountDataBridge
  ) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_payslip: (entity: OrganizationPayslip) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_payslip_schema: (entity: OrganizationPayslipSchema) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_product: (entity: OrganizationProduct) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_report: (entity: OrganizationReport) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_report_schema: (entity: OrganizationReportSchema) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_report_schema_field: (
    entity: OrganizationReportSchemaField
  ) => ({
    id: entity.id,
    organizationId: entity.organization_id,
    reportSchemaId: entity.organization_report_schema_id,
  }),
  organization_tag: (entity: OrganizationTag) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_task: (entity: OrganizationTask) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_tax_class: (entity: OrganizationTaxClass) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_tax_rate: (entity: OrganizationTaxRate) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_membership_payment_method: (
    entity: OrganizationMembershipPaymentMethod
  ) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_membership_invoice: (entity: OrganizationMembershipInvoice) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_user: (entity: OrganizationUser) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_user_role: (entity: OrganizationUserRole) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_user_address: (entity: OrganizationUserAddress) => ({
    id: entity.id,
    userId: entity.organization_user_id,
    organizationId: entity.organization_id,
  }),
  user: (entity: User) => ({
    id: entity.id,
  }),
  user_organization_setting: (entity: UserOrganizationSetting) => ({
    id: entity.id,
    userId: entity.user_id,
    organizationId: entity.organization_id,
  }),
  user_api_token: (entity: UserApiToken) => ({
    id: entity.id,
  }),
  organization_user_activity: (entity: OrganizationUserActivity) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_webhook: (entity: OrganizationWebhook) => ({
    id: entity.id,
    organizationId: entity.organization_id,
  }),
  organization_webhook_notification: (
    entity: OrganizationWebhookNotification
  ) => ({
    id: entity.id,
    organizationId: entity.organization_id,
    organization_webhook_endpoint_id: entity.organization_webhook_endpoint_id,
  }),
};

export function getEntityIdentifier(entity: Entity): EntityIdentifier {
  return entityIdentifierMap[entity._entity](entity) as EntityIdentifier;
}

export function getEntityIdentifierSerialized(
  entityIdentifier: EntityIdentifier
) {
  const result = [];
  if (entityIdentifier.userId) {
    result.push(entityIdentifier.userId);
  }

  if (entityIdentifier.organizationId) {
    result.push(entityIdentifier.organizationId);
  }

  if (entityIdentifier.reportSchemaId) {
    result.push(entityIdentifier.reportSchemaId);
  }

  if (entityIdentifier.invoiceId) {
    result.push(entityIdentifier.invoiceId);
  }

  if (entityIdentifier.id) {
    result.push(entityIdentifier.id);
  }

  return result.join(".");
}
