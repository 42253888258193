import { useOrganizationBankTransactionImportApi } from "@/api/organization/OrganizationBankTransactionImportApi";
import type { OrganizationBankAccount } from "@/interfaces/organization/bank/OrganizationBankAccount";
import type { OrganizationBankTransactionImport } from "@/interfaces/organization/bank/OrganizationBankTransactionImport";
import type { OrganizationBankTransactionImportSendData } from "@/interfaces/organization/bank/OrganizationBankTransactionImportSendData";
import { deepClone } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { ref } from "vue";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";
import { useOrganizationBankAccountsStore } from "./OrganizationBankAccounts";

export const useOrganizationBankTransactionImportsStore = defineStore(
  "organizationBankTransactionImports",
  () => {
    const storage =
      new OrganizationEntityStorage<OrganizationBankTransactionImport>();
    const pageCache =
      new OrganizationCache<OrganizationBankTransactionImport>();
    const api = useOrganizationBankTransactionImportApi();
    const createImportState = ref<OrganizationBankTransactionImportSendData>();

    const genericActions =
      new OrganizationGenericActions<OrganizationBankTransactionImport>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
        enhanceEntity: enhanceEntity,
      });

    const importBankTransaction = async (args: {
      organizationId: string;
      data: OrganizationBankTransactionImportSendData;
    }) => {
      return api.importBankTransaction(args);
    };

    const getCreateImportState = () => {
      return deepClone(createImportState.value) as
        | OrganizationBankTransactionImportSendData
        | undefined;
    };

    const setCreateImportState = (
      state: OrganizationBankTransactionImportSendData | undefined
    ) => {
      createImportState.value = state;
    };

    return {
      ...genericActions,
      importBankTransaction,
      getCreateImportState,
      setCreateImportState,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationBankTransactionImport,
  storage: OrganizationEntityStorage<OrganizationBankTransactionImport>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  const bankAccountsStore = useOrganizationBankAccountsStore();

  addComputedField<OrganizationBankTransactionImport, OrganizationBankAccount>(
    entity,
    "bankAccount",
    () =>
      storage.get(entityIdentifier)?.settings_json
        ?.organization_bank_account_id,
    async () =>
      bankAccountsStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.settings_json
          ?.organization_bank_account_id,
      })
  );

  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.id);
}
