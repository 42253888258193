import { useOrganizationProductApi } from "@/api/organization/OrganizationProductApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationProduct } from "@/interfaces/organization/OrganizationProduct";
import { isEmpty } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";

export const useOrganizationProductsStore = defineStore(
  "organizationProducts",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationProduct>();
    const pageCache = new OrganizationCache<OrganizationProduct>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationProduct>(
        "product",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationProduct>({
      storage: storage,
      pageCache: pageCache,
      entityApi: useOrganizationProductApi(),
      enhanceEntity: enhanceEntity,
      initializationCallback: subscribeToSocket,
      prePersist: prePersist,
    });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationProduct,
  storage: OrganizationEntityStorage<OrganizationProduct>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.name);
}

function prePersist(
  entity: OrganizationProduct,
  existingEntity?: OrganizationProduct
) {
  // If it's an update operation, mark sub-entities removed for deletion.
  if (existingEntity) {
    const remainingEntityLanguages =
      entity.name_lang_map && !isEmpty(entity.name_lang_map)
        ? Object.keys(entity.name_lang_map)
        : [];

    for (const lang in existingEntity.name_lang_map) {
      if (!remainingEntityLanguages.includes(lang)) {
        if (!entity.name_lang_map) {
          entity.name_lang_map = {};
          entity.unit_lang_map = {};
        }

        entity.name_lang_map[lang] = "";
        entity.unit_lang_map[lang] = "";
      }
    }

    const remainingPriceCountries =
      entity.price_map && !isEmpty(entity.price_map)
        ? Object.keys(entity.price_map)
        : [];

    for (const countryCode in existingEntity.price_map) {
      if (!remainingPriceCountries.includes(countryCode)) {
        if (!entity.price_map) {
          entity.price_map = {};
        }

        (entity.price_map as any)[countryCode] = "";
      }
    }
  }
}
