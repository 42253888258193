<script setup lang="ts">
import md5 from "md5";
import { computed } from "vue";

defineEmits(["load", "error"]);

interface Props {
  email?: string;
  hash?: string;
  size?: number;
  defaultImg?: string;
  rating?: string;
  alt?: string;
}

const {
  email = "",
  hash,
  size = 80,
  rating = "g",
  alt = "Avatar",
} = defineProps<Props>();

const url = computed(() => {
  const img = [
    `https://www.gravatar.com/avatar/`,
    hash || md5(email.trim().toLowerCase()),
    `?s=${size}`,
    `&d=https://enlivy.com/wp-content/uploads/2021/12/favicon-150x150.png`,
    `&r=${rating}`,
  ];

  return img.join("");
});
</script>

<template>
  <img :src="url" :alt="alt" />
</template>
