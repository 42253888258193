<script setup lang="ts">
import updateAvailableJsonConfiguration from "@/assets/animations/update-available.json";
import lottie, { type AnimationItem } from "lottie-web";
import { onMounted, ref } from "vue";

defineExpose({ reset });
withDefaults(defineProps<{
  color?: string;
}>(), {
  color: "#515663",
});

const animationRef = ref();
let lottieAnim: AnimationItem;

onMounted(() => {
  lottieAnim = lottie.loadAnimation({
    container: animationRef.value,
    renderer: "svg",
    loop: false,
    autoplay: true,
    animationData: updateAvailableJsonConfiguration,
  });

  lottieAnim.addEventListener('complete', () => {
    // Set the animation to loop only the specified segment
    lottieAnim.loop = true;
    lottieAnim.playSegments([80, 180], true);
  });
});

function reset() {
  if (lottieAnim) {
    lottieAnim.goToAndPlay(0);
  }
}
</script>

<template>
  <div ref="animationRef" class="illustration"></div>
</template>

<style scoped lang="scss">
.illustration {
    max-width: 220px;
    max-height: 220px;

    :deep(path[fill="rgb(91,96,111)"]) {
      fill: v-bind(color);
    }
  }
</style>
