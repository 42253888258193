import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationMembershipInvoice } from "@/interfaces/organization/membership/OrganizationMembershipInvoice";

class OrganizationMembershipInvoiceApi extends OrganizationGenericEntityApi<OrganizationMembershipInvoice> {
  apiIdentifier = "organization_membership_invoice";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/membership/invoices`;
  }

  parseEntity(entityFromApi: any) {
    const entity = new ApiResponseParser(
      entityFromApi,
      "organization_membership_invoice"
    )
      .asOrganizationEntity()
      .asNumber("sub_total")
      .asNumber("discount")
      .asNumber("tax_total")
      .asNumber("total")
      .build() as OrganizationMembershipInvoice;

    return entity;
  }
}

export function useOrganizationMembershipInvoiceApi() {
  return new OrganizationMembershipInvoiceApi();
}
