import { AdministrationGenericEntityApi } from "@/api/generic/AdministrationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { AdministrationPricingPlan } from "@/interfaces/administration/membership/AdministrationPricingPlan";

class AdministrationMembershipApi extends AdministrationGenericEntityApi<AdministrationPricingPlan> {
  apiIdentifier = "administration_console_job";

  getBaseUrl(): string {
    return `/membership-plans`;
  }
  parseEntity(entityFromApi: any): AdministrationPricingPlan {
    return new ApiResponseParser(entityFromApi, "administration_membership")
      .asId("id")
      .asDate("created_at")
      .asDate("updated_at")
      .asDate("deleted_at")
      .asId("stripe_product_id")
      .asId("stripe_price_id")
      .asNumber(" credit_limit_billing")
      .asNumber(" credit_limit_transaction")
      .asNumber(" credit_limit_translation")
      .asNumber(" credit_limit_storage")
      .asNumber(" credit_limit_task")
      .asNumber(" credit_limit_contracts")
      .asNumber(" credit_limit_report")
      .asNumber(" credit_limit_payslip")
      .asNumber(" feature_limit_webhook_endpoints")
      .asBool("has_feature_bank_account_data")
      .asBool("has_feature_report_schema")
      .asBool("has_feature_payslip")
      .asBool("has_feature_webhook")
      .asBool("has_feature_playbooks")
      .asBool("has_feature_billing_schedules")
      .asNumber(" weight")
      .asBool("is_public")
      .asId("deleted_by_user_id")
      .build() as AdministrationPricingPlan;
  }
}

export function useAdministrationMembershipApi() {
  return new AdministrationMembershipApi();
}
