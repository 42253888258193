import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationWebhook } from "@/interfaces/organization/webhook/OrganizationWebhook";

class OrganizationWebHookApi extends OrganizationGenericEntityApi<OrganizationWebhook> {
  apiIdentifier = "organization_webhook";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/webhooks`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_webhook")
      .asOrganizationEntity()
      .asBool("is_active")
      .asId("deleted_by_user_id")
      .build() as OrganizationWebhook;
  }

  getDefaultInclude(): string[] {
    return ["events"];
  }
}

export function useOrganizationWebHookApi() {
  return new OrganizationWebHookApi();
}
