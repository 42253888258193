import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import { ref } from "vue";

export abstract class GenericCache<T> {
  protected cache = ref({} as { [key: string]: T });

  set(key: string, value: T): T {
    this.cache.value[key] = value;
    return value;
  }

  get(key: string): T | undefined {
    return this.cache.value?.[key];
  }

  abstract clearCache(entityIdentifier: EntityIdentifier): void;
}
