import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationSubEntityStorageData } from "@/interfaces/generic/storage/OrganizationSubEntityStorageData";
import type { OrganizationEntity } from "@/interfaces/organization/OrganizationEntity";
import { updateObject } from "@/lib/Object";
import { computed } from "vue";
import { EntityStorage } from "./EntityStorage";

export class OrganizationSubEntityStorage<
  T extends OrganizationEntity
> extends EntityStorage<T, OrganizationSubEntityStorageData<T>> {
  collectionIdName: keyof EntityIdentifier;

  constructor(collectionIdName: keyof EntityIdentifier) {
    super({} as OrganizationSubEntityStorageData<T>);

    this.collectionIdName = collectionIdName;
  }

  get(entityIdentifier: EntityIdentifier): T | undefined {
    const collectionId = entityIdentifier[this.collectionIdName];

    if (
      entityIdentifier.organizationId == undefined ||
      collectionId == undefined ||
      entityIdentifier.id == undefined
    ) {
      return undefined;
    }

    return this.storage.value?.[entityIdentifier.organizationId]?.collections?.[
      collectionId
    ]?.entities?.[entityIdentifier.id];
  }

  getComputed(entityIdentifier: EntityIdentifier) {
    return computed(() => this.get(entityIdentifier));
  }

  set(
    entityIdentifier: EntityIdentifier,
    entity: T,
    initializationCallback?: (entityIdentifier: EntityIdentifier) => void
  ) {
    const collectionId = entityIdentifier[this.collectionIdName];

    if (
      entityIdentifier.organizationId == undefined ||
      collectionId == undefined ||
      entityIdentifier.id == undefined
    ) {
      console.log(
        "This should never be called without all the identifiers present:",
        entityIdentifier
      );
      // TODO: Throw error, set should not be called without all the identifiers present.
      return entity;
    }

    // If the entity is already in the store, update it.
    if (this.isInStore(entityIdentifier)) {
      const existingEntity = this.get(entityIdentifier);
      if (existingEntity != undefined) {
        updateObject(existingEntity, entity);
        return existingEntity;
      }
    }

    // Initialize the map if it's the first entity from this organization.
    if (!(entityIdentifier.organizationId in this.storage.value)) {
      this.storage.value[entityIdentifier.organizationId] = {
        collections: {},
      };

      initializationCallback?.(entityIdentifier);
    }

    // Initialize the collections map if it's the first entity from this collection.
    if (
      !(
        collectionId in
        this.storage.value[entityIdentifier.organizationId].collections
      )
    ) {
      this.storage.value[entityIdentifier.organizationId].collections[
        collectionId
      ] = {
        entities: {},
      };
    }

    this.storage.value[entityIdentifier.organizationId].collections[
      collectionId
    ].entities[entityIdentifier.id] = entity;

    return entity;
  }

  remove(entityIdentifier: EntityIdentifier): T | undefined {
    const collectionId = entityIdentifier[this.collectionIdName];

    if (
      entityIdentifier.organizationId == undefined ||
      collectionId == undefined ||
      entityIdentifier.id == undefined
    ) {
      return undefined;
    }

    const entity = this.get(entityIdentifier);
    delete this.storage.value?.[entityIdentifier.organizationId]?.collections?.[
      collectionId
    ].entities?.[entityIdentifier.id];

    return entity;
  }

  isInStore(id: EntityIdentifier): boolean {
    const collectionId = id?.[this.collectionIdName];

    if (
      id.id == undefined &&
      collectionId != undefined &&
      id.organizationId != undefined
    ) {
      return (
        id.organizationId in this.storage.value &&
        collectionId in this.storage.value[id.organizationId].collections
      );
    }

    return this.get(id) != undefined;
  }
}
