import type { RouteLocationNormalizedLoaded } from "vue-router";
import {
  redirectIfMissingParams,
  redirectIfNoOrganization,
  redirectIfOneOrganization,
} from "../RedirectBeforeEnter";

export const loggedUserRoutes = [
  {
    path: "/my-profile",
    name: "myProfile",
    component: () => import("@/views/MyProfile.vue"),
    meta: {
      title: "general.title.my_profile",
      activeNav: "logged_my_profile",
    },
  },

  {
    path: "/api-tokens",
    name: "userApiTokens",
    component: () => import("@/views/user/api-tokens/List.vue"),
    meta: {
      title: "general.title.user_api_token",
      activeNav: "logged_api_tokens_list",
    },
  },
  {
    path: "/api-tokens/new",
    name: "createUserApiToken",
    component: () => import("@/views/user/api-tokens/Create.vue"),

    meta: {
      title: "general.title.user_api_token",
      activeNav: "logged_api_tokens_create",
    },
  },
  {
    path: "/api-tokens/:userApiTokenId/edit",
    name: "editUserApiToken",
    component: () => import("@/views/user/api-tokens/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["userApiTokenId"],
        redirectName: "userApiTokens",
        redirectParams: [],
      }),
    meta: {
      title: "general.title.user_api_token",
      activeNav: "logged_api_tokens_edit",
    },
  },

  {
    path: "/dashboard",
    name: "accountOrganizationsDashboard",
    component: () => import("@/views/AccountOrganizationsDashboard.vue"),
    beforeEnter: [redirectIfNoOrganization, redirectIfOneOrganization],
    meta: {
      title: "general.title.account_organizations_dashboard",
      activeNav: "account_organizations_dashboard",
    },
  },

  // /organizations/ route is available in the Header
  {
    path: "/organizations",
    name: "organizations",
    component: () => import("@/views/organizations/List.vue"),
    beforeEnter: redirectIfNoOrganization,
    meta: { title: "organization.title.list", activeNav: "organizations_list" },
  },

  {
    path: "/organizations/new",
    name: "createOrganization",
    component: () => import("@/views/organizations/Create.vue"),
    meta: {
      title: "organization.title.add",
      activeNav: "organizations_create",
    },
  },
  // /organizations/edit route is available in the Organization sidebar
  {
    path: "/organizations/:organizationId/edit",
    name: "editOrganization",
    component: () => import("@/views/organizations/Edit.vue"),
    props: true,
    beforeEnter: (route: RouteLocationNormalizedLoaded) =>
      redirectIfMissingParams(route, {
        paramsToCheck: ["organizationId"],
        redirectName: "organizations",
        redirectParams: [],
      }),
    meta: {
      title: "organization.title.edit",
      activeNav: "organizations_edit",
      acl: "update",
    },
  },
];
