import { useAuthApi } from "@/api/AuthApi";
import type { RegistrationUser } from "@/interfaces/auth/RegistrationUser";
import { setupEcho } from "@/lib/socket/EchoUtils";
import { useBroadcastChannel } from "@vueuse/core";
import { defineStore } from "pinia";
import { useFrontendStore } from "./Frontend";
import { useUserStore } from "./user/User";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    email: undefined as string | undefined,
    authChannel: useBroadcastChannel({ name: "auth-channel" }), // This is caught in App.vue
  }),
  actions: {
    saveTempEmail(email: string) {
      this.email = email;
    },

    async login(args: { email: string; password: string; remember?: boolean }) {
      const user = await useAuthApi().login(args);
      localStorage.setItem("user", JSON.stringify(user));

      const frontend = useFrontendStore();
      frontend.updateLocale(user?.locale);

      setupEcho();

      this.authChannel.post("login-event");
    },

    async logoutTrigger() {
      await useAuthApi().logout();
      this.logoutBehaviour();
    },

    async logoutBehaviour() {
      this.clearUserSession();
      this.logoutTab();

      this.authChannel.post("logout-event");
    },

    logoutTab() {
      document.location.href = "/login";
    },

    clearUserSession() {
      localStorage.removeItem("user");
      localStorage.removeItem("activeOrganization");
      localStorage.removeItem("bankAccountDataBridge");
    },

    async register(user: RegistrationUser) {
      const newUser = await useAuthApi().register(user);
      await this.login({
        email: user.email,
        password: user.password,
        remember: true,
      });
      useUserStore().assignLoggedUserInfo(newUser);

      return newUser;
    },

    async forgotPassword(email: string) {
      return useAuthApi().forgotPassword(email);
    },

    async resetPassword(
      token: string,
      email: string,
      password: string,
      password_confirm: string
    ) {
      return useAuthApi().resetPassword({
        token,
        email,
        password,
        password_confirmation: password_confirm,
      });
    },
  },
});
