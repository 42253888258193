import { useOrganizationContractApi } from "@/api/organization/OrganizationContractApi";
import type { OrganizationContract } from "@/interfaces/organization/contract/OrganizationContract";
import type { OrganizationContractStatus } from "@/interfaces/organization/contract/OrganizationContractStatus";
import type { OrganizationFile } from "@/interfaces/organization/OrganizationFile";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { useOrganizationContractStatusesStore } from "./OrganizationContractStatuses";
import { useOrganizationFilesStore } from "./OrganizationFiles";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationContractsStore = defineStore(
  "organizationContracts",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationContract>();
    const pageCache = new OrganizationCache<OrganizationContract>();

    const genericActions = new OrganizationGenericActions<OrganizationContract>(
      {
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationContractApi(),
        enhanceEntity: enhanceEntity,
      }
    );

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationContract,
  storage: OrganizationEntityStorage<OrganizationContract>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const contractsStore = useOrganizationContractsStore();
  const organizationUserStore = useOrganizationUsersStore();
  const contractStatusesStore = useOrganizationContractStatusesStore();
  const organizationFilesStore = useOrganizationFilesStore();

  addComputedField<OrganizationContract, OrganizationContract>(
    entity,
    "parentContract",
    () => storage.get(entityIdentifier)?.organization_contract_id,
    async () =>
      contractsStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_contract_id,
      })
  );

  addComputedField<OrganizationContract, OrganizationContractStatus>(
    entity,
    "contractStatus",
    () => storage.get(entityIdentifier)?.organization_contract_status_id,
    async () =>
      contractStatusesStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_contract_status_id,
      })
  );

  addComputedField<OrganizationContract, OrganizationFile>(
    entity,
    "file",
    () => storage.get(entityIdentifier)?.organization_file_id,
    async () =>
      organizationFilesStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_file_id,
      })
  );

  addComputedField<OrganizationContract, OrganizationUser>(
    entity,
    "sender",
    () => storage.get(entityIdentifier)?.organization_sender_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_sender_user_id,
      })
  );
  addComputedField<OrganizationContract, OrganizationUser>(
    entity,
    "receiver",
    () => storage.get(entityIdentifier)?.organization_receiver_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_receiver_user_id,
      })
  );
  addComputedField<OrganizationContract, OrganizationUser>(
    entity,
    "deletedByUser",
    () => storage.get(entityIdentifier)?.deleted_by_user_id,
    async () =>
      organizationUserStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.deleted_by_user_id,
      })
  );

  setEntityNameFields(entity, () => {
    const storageEntity = storage.get(entityIdentifier);
    if (storageEntity?.number) {
      if (storageEntity?.title) {
        return storageEntity.number + " ( " + storageEntity.title + " )";
      }
      return storageEntity.number;
    }
    return storageEntity?.title;
  });
}
