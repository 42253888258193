import type { RouteLocation } from "vue-router";
import { redirectIfDoesNotOwnOrganization } from "../RedirectBeforeEnter";

export const setupProcessRoutes = [
  {
    path: "/",
    name: "SetupProcessLayout",
    component: () => import("@/views/layout/SetupProcessLayout.vue"),
    meta: { layout: "SetupProcessLayout" },
    children: [
      {
        path: "/setup-process",
        name: "setupProcess",
        redirect: () => {
          return { name: "setupProcessOrganizationCreate" };
        },
      },
      {
        path: "/setup-process/organizationId",
        name: "setupProcessOrganizationCreate",
        component: () =>
          import("@/views/setup-process/organization/Create.vue"),
        meta: {
          title: "setup_process.organization.title",
          activeNav: "setup_process_organization",
        },
      },
      // @TODO add path for edit organization and change it in the menu from
      // create to edit once the org is created
      {
        path: "/setup-process/:organizationId",
        name: "setupProcessOrganization",
        redirect: () => {
          return { name: "setupProcessOrganizationCreate" };
        },
        children: [
          {
            path: "user-roles",
            name: "setupProcessOrganizationUserRoleList",
            component: () =>
              import("@/views/setup-process/user-roles/List.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.user_roles.title.list",
              activeNav: "setup_process_user_roles_list",
              acl: "user_roles.view",
            },
          },
          {
            path: "user-roles/new",
            name: "setupProcessOrganizationUserRoleCreate",
            component: () =>
              import("@/views/setup-process/user-roles/Create.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.user_roles.title.list",
              activeNav: "setup_process_user_roles_create",
              acl: "user_roles.store",
            },
          },
          {
            path: "user-roles/:organizationUserRole/edit",
            name: "setupProcessOrganizationUserRoleEdit",
            component: () =>
              import("@/views/setup-process/user-roles/Edit.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.user_roles.title.list",
              activeNav: "setup_process_user_roles_edit",
              acl: "user_roles.update",
            },
          },

          {
            path: "users",
            name: "setupProcessOrganizationUserList",
            component: () => import("@/views/setup-process/users/List.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.users.title.list",
              activeNav: "setup_process_users_list",
              acl: "users.view",
            },
          },
          {
            path: "users/new",
            name: "setupProcessOrganizationUserCreate",
            component: () => import("@/views/setup-process/users/Create.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.users.title.list",
              activeNav: "setup_process_users_create",
              acl: "users.store",
            },
          },
          {
            path: "users/:organizationUser/edit",
            name: "setupProcessOrganizationUserEdit",
            component: () => import("@/views/setup-process/users/Edit.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.users.title.list",
              activeNav: "setup_process_users_edit",
              acl: "users.update",
            },
          },

          {
            path: "bank-accounts",
            name: "setupProcessOrganizationBankAccountList",
            component: () =>
              import("@/views/setup-process/bank-accounts/List.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.bank_accounts.title.list",
              activeNav: "setup_process_bank_accounts_list",
              acl: "bank_accounts.view",
            },
          },
          {
            path: "bank-accounts/new",
            name: "setupProcessOrganizationBankAccountCreate",
            component: () =>
              import("@/views/setup-process/bank-accounts/Create.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.bank_accounts.title.list",
              activeNav: "setup_process_bank_accounts_create",
              acl: "bank_accounts.store",
            },
          },
          {
            path: "bank-accounts/:organizationBankAccount/edit",
            name: "setupProcessOrganizationBankAccountEdit",
            component: () =>
              import("@/views/setup-process/bank-accounts/Edit.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.bank_accounts.title.list",
              activeNav: "setup_process_bank_accounts_edit",
              acl: "bank_accounts.update",
            },
          },

          {
            path: "invoice-prefixes",
            name: "setupProcessOrganizationInvoicePrefixList",
            component: () =>
              import("@/views/setup-process/invoice-prefixes/List.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.invoice_prefixes.title.list",
              activeNav: "setup_process_invoice_prefixes_list",
              acl: "invoice_prefixes.view",
            },
          },
          {
            path: "invoice-prefixes/new",
            name: "setupProcessOrganizationInvoicePrefixCreate",
            component: () =>
              import("@/views/setup-process/invoice-prefixes/Create.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.invoice_prefixes.title.list",
              activeNav: "setup_process_invoice_prefixes_create",
              acl: "invoice_prefixes.store",
            },
          },
          {
            path: "invoice-prefixes/:organizationInvoicePrefix/edit",
            name: "setupProcessOrganizationInvoicePrefixEdit",
            component: () =>
              import("@/views/setup-process/invoice-prefixes/Edit.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.invoice_prefixes.title.list",
              activeNav: "setup_process_invoice_prefixes_edit",
              acl: "invoice_prefixes.update",
            },
          },

          {
            path: "products",
            name: "setupProcessOrganizationProductList",
            component: () => import("@/views/setup-process/products/List.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.products.title.list",
              activeNav: "setup_process_products_list",
              acl: "products.view",
            },
          },
          {
            path: "products/new",
            name: "setupProcessOrganizationProductCreate",
            component: () =>
              import("@/views/setup-process/products/Create.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.products.title.list",
              activeNav: "setup_process_products_create",
              acl: "products.store",
            },
          },
          {
            path: "products/:organizationProduct/edit",
            name: "setupProcessOrganizationProductEdit",
            component: () => import("@/views/setup-process/products/Edit.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.products.title.list",
              activeNav: "setup_process_products_edit",
              acl: "products.update",
            },
          },

          {
            path: "final-step",
            name: "setupProcessFinalStep",
            component: () => import("@/views/setup-process/FinalStep.vue"),
            beforeEnter: (to: RouteLocation) =>
              redirectIfDoesNotOwnOrganization(to.params.organizationId),
            meta: {
              title: "setup_process.final_step.title",
              activeNav: "setup_process_final_step",
            },
          },
        ],
      },
    ],
  },
];
