import type { RouteLocation, RouteLocationNormalizedLoaded } from "vue-router";
import { redirectIfMissingParams } from "../RedirectBeforeEnter";
import { organizationDashboardRoutes } from "./organization/OrganizationDashboardRoutes";
import { organizationEntityRoutes } from "./organization/OrganizationEntityRoutes";
import { organizationSettingsRoutes } from "./organization/OrganizationSettingsRoutes";

export const organizationRoutes = [
  {
    path: "/organizations/:organizationId",
    name: "organization",
    redirect: (to: RouteLocation) => {
      return {
        name: "organizationDashboard",
        param: to.params,
      };
    },
    children: [
      ...organizationDashboardRoutes,
      ...organizationSettingsRoutes,
      ...organizationEntityRoutes,

      {
        path: "export",
        name: "organizationFilesExport",
        component: () => import("@/views/organization/export/FilesExport.vue"),
        meta: {
          title: "general.title.financial_files_export",
          activeNav: "organization_export_files",
        },
      },
      {
        path: "export/:organizationExportEntryId",
        name: "viewOrganizationExportEntry",
        component: () => import("@/views/organization/export/ViewExport.vue"),
        props: true,
        beforeEnter: (route: RouteLocationNormalizedLoaded) =>
          redirectIfMissingParams(route, {
            paramsToCheck: ["organizationExportEntryId"],
            redirectName: "organizationFilesExport",
            redirectParams: ["organizationId"],
          }),
        meta: {
          title: "general.title.financial_files_export",
          activeNav: "organization_export_files",
        },
      },
      {
        path: "developers/getting-started",
        name: "organizationDevelopersGettingStarted",
        component: () =>
          import("@/views/organization/developers/GettingStarted.vue"),
        meta: {
          title: "general.title.developers_getting_started",
          activeNav: "organization_developers_getting_started",
        },
      },
    ],
  },
];
