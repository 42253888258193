<script setup lang="ts">
import { withPopper } from "@/lib/Utils";
import { useFrontendStore } from "@/stores/Frontend";
import { computed } from "vue";
import IconChevronDown from "../icons/general/chevron/IconChevronDown.vue";

const frontend = useFrontendStore();

const localeOptions = computed(() => {
  return Object.keys(frontend.locales).map((key) => ({
    id: key,
    label: frontend.locales[key],
    flag: getFlag(key),
  }));
});

function selectOption(newValue: any) {
  if (frontend.locale != newValue.id) {
    frontend.updateLocale(newValue.id);
  }
}

function getFlag(locale: string) {
  return frontend.locale_to_country_code_iso[locale].toLowerCase();
}
</script>

<template>
  <div
    v-if="Object.keys(frontend.locales).length != 0"
    class="form-field select-group"
  >
    <vue3-select
      :modelValue="{
        label: frontend.locale.toUpperCase(),
        flag: getFlag(frontend.locale),
      }"
      :options="localeOptions"
      @update:modelValue="selectOption"
      :clearable="false"
      append-to-body
      :calculatePosition="withPopper"
      v-bind="$attrs"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes" class="arrow">
          <IconChevronDown color="var(--enlivy-grey-40-color)" />
        </span>
      </template>
      <template #selected-option="option">
        <span :class="'fi fi-' + option.flag"></span>
        {{ option.label }}
      </template>
      <template #option="option">
        <span :class="'fi fi-' + option.flag"></span>
        {{ option.label }}
      </template>
    </vue3-select>
  </div>
</template>

<style scoped lang="scss">
.form-field.select-group {
  &:deep(.v-select) {
    min-width: 106px;
    background-color: var(--enlivy-white-100-color);
    border: 1px solid var(--enlivy-white-100-color);
    border-radius: 10px;
    border-color: var(--enlivy-grey-15-color);
    // prettier-ignore
    padding: var(--enlivy-spacing-xs) calc(var(--enlivy-spacing-lg) - 1px);

    > div {
      margin: 0;
      padding: 0;
      border: 0 none;

      span,
      input {
        @include font-large();
        color: var(--enlivy-grey-75-color);
      }
      .vs__selected-options {
        margin: 0;
        padding: 0;
        flex-wrap: nowrap;

        .vs__selected {
          position: static;
          margin: 0;
          padding: 0;
          display: flex;
          gap: var(--enlivy-spacing-xs);
          background: inherit;
        }
        input {
          margin: 0;
          padding: 0;
        }
      }
      .vs__actions {
        margin: 0;
        padding: 0;
      }
    }
  }

  .arrow {
    width: 24px;
    height: 24px;
  }
}
</style>
