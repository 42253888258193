<script setup lang="ts">
import loadingAnimation from "@/assets/animations/loading.json";
import lottie from "lottie-web";
import { onMounted, ref } from "vue";

const animationRef = ref();

onMounted(() => {
  lottie.loadAnimation({
    container: animationRef.value,
    renderer: "svg",
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
  });
});
</script>

<template>
  <div ref="animationRef" class="loading"></div>
</template>

<style scoped lang="scss">
.loading {
  max-width: 220px;
  max-height: 220px;
  :deep(path[fill="rgb(71,67,85)"]) {
    fill: #515663;
  }
}
</style>
