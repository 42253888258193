import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationEntity } from "@/interfaces/organization/OrganizationEntity";
import type { Ref } from "vue";
import type { EntityStorage } from "./storage/EntityStorage";

export const subscribeToEntitySocket = (
  organizationId: string,
  entityName: string,
  handleEventCallback: (
    eventType: string,
    entityIdentifier: EntityIdentifier
  ) => Promise<void>
) => {
  window.Echo.private(`organization.${organizationId}.${entityName}`)
    .listen(`.${entityName}.created`, async (data: any) => {
      const entityIdentifier = {
        organizationId,
        id: data.id,
        userId: data.user_id,
      };
      handleEventCallback("created", entityIdentifier);
    })
    .listen(`.${entityName}.updated`, async (data: any) => {
      const entityIdentifier = {
        organizationId,
        id: data.id,
        userId: data.user_id,
      };
      handleEventCallback("updated", entityIdentifier);
    })
    .listen(`.${entityName}.deleted`, async (data: any) => {
      const entityIdentifier = {
        organizationId,
        id: data.id,
        userId: data.user_id,
      };
      handleEventCallback("deleted", entityIdentifier);
    });
};

export function useGenericPusherUtils<T extends OrganizationEntity>(
  entityName: string,
  storage: EntityStorage<T, any>,
  getById: (entityIdentifier: EntityIdentifier) => Promise<Ref<T | undefined>>,
  postChange?: (entityIdentifier: EntityIdentifier) => void,
  customChannel?: string
) {
  const subscribeToPusher = (organizationId: string) => {
    const environment = import.meta.env.VITE_APP_MODE;
    const channel = customChannel
      ? customChannel
      : `organization.${organizationId}.${entityName}`;

    window.Echo.private(channel)
      .listen(`.${entityName}.created`, async (data: any) => {
        if (environment == "development" || environment.includes("local")) {
          console.log(`pusher - entity created - ${JSON.stringify(data)}`);
        }

        const entityIdentifier = {
          organizationId,
          id: data.id,
          userId: data.user_id,
        };

        await getById(entityIdentifier);
        if (postChange) {
          postChange(entityIdentifier);
        }
      })

      .listen(`.${entityName}.updated`, async (data: any) => {
        if (environment == "development" || environment.includes("local")) {
          console.log(`pusher - entity updated - ${JSON.stringify(data)}`);
        }

        const entityIdentifier = {
          organizationId,
          id: data.id,
          userId: data.user_id,
        };

        storage.markStale(entityIdentifier);
        await getById(entityIdentifier);
        if (postChange) {
          postChange(entityIdentifier);
        }
      })

      .listen(`.${entityName}.deleted`, (data: any) => {
        if (environment == "development" || environment.includes("local")) {
          console.log(`pusher - entity deleted - ${JSON.stringify(data)}`);
        }

        const entityIdentifier = {
          organizationId,
          id: data.id,
          userId: data.user_id,
        };

        storage.remove(entityIdentifier);
        if (postChange) {
          postChange(entityIdentifier);
        }
      });
  };

  return { subscribeToPusher };
}
