import { useOrganizationBankTransactionCostTypeApi } from "@/api/organization/OrganizationBankTransactionCostTypeApi";
import type { OrganizationBankTransactionCostType } from "@/interfaces/organization/bank/OrganizationBankTransactionCostType";
import { isEmpty } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { useUserStore } from "@/stores/user/User";
import { defineStore } from "pinia";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";

export const useOrganizationBankTransactionCostTypesStore = defineStore(
  "organizationBankTransactionCostTypes",
  () => {
    const storage =
      new OrganizationEntityStorage<OrganizationBankTransactionCostType>();
    const pageCache =
      new OrganizationCache<OrganizationBankTransactionCostType>();

    const genericActions =
      new OrganizationGenericActions<OrganizationBankTransactionCostType>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationBankTransactionCostTypeApi(),
        enhanceEntity: enhanceEntity,
        prePersist: prePersist,
      });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationBankTransactionCostType,
  storage: OrganizationEntityStorage<OrganizationBankTransactionCostType>
) {
  const userStore = useUserStore();
  const organizationLanguage = userStore.organization.locale;
  const storedEntity = storage.get(getEntityIdentifier(entity));
  setEntityNameFields(entity, () =>
    storedEntity?.title_lang_map &&
    storedEntity?.title_lang_map[organizationLanguage]
      ? storedEntity?.title_lang_map[organizationLanguage]
      : storedEntity?.title
  );
}

function prePersist(
  entity: OrganizationBankTransactionCostType,
  existingEntity?: OrganizationBankTransactionCostType
) {
  // If it's an update operation, remove extra line items.
  if (existingEntity) {
    const remainingEntityLanguages =
      entity.title_lang_map && !isEmpty(entity.title_lang_map)
        ? Object.keys(entity.title_lang_map)
        : [];

    for (const lang in existingEntity.title_lang_map) {
      if (!remainingEntityLanguages.includes(lang)) {
        if (!entity.title_lang_map) {
          entity.title_lang_map = {};
        }

        entity.title_lang_map[lang] = "";
      }
    }
  }
}
