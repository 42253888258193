import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { deepClone } from "../Object";

export function dateToApiFormat(date: Date | string, includeTime = true) {
  if (date) {
    if (typeof date == "string") {
      date = new Date(date);
    }

    return format(date, `yyyy-MM-dd${includeTime ? " HH:mm:ss" : ""}`);
  }

  return undefined;
}

export function getToday() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate());
}

export function daysUntilDate(untilDate: string | Date) {
  const todayDate = getToday();
  const givenDate = new Date(untilDate);

  const timeDifferenceInMilliseconds =
    givenDate.getTime() - todayDate.getTime();
  const timeDifferenceInDays =
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24);

  return timeDifferenceInDays;
}

export function dateToDisplayFormat(date: Date | string | undefined) {
  if (date) {
    if (typeof date == "string") {
      date = new Date(date);
    }

    if (date.getTime() % (24 * 60 * 60 * 1000) == 0) {
      return formatInTimeZone(date, "UTC", "yyyy-MM-dd");
    }

    return format(date, "yyyy-MM-dd");
  }

  return undefined;
}

export function datetimeToDisplayFormat(date: Date | string | undefined) {
  if (date) {
    if (typeof date == "string") {
      date = new Date(date);
    }

    if (date.getTime() % (24 * 60 * 60 * 1000) == 0) {
      return formatInTimeZone(date, "UTC", "yyyy-MM-dd HH:mm");
    }

    return format(date, "yyyy-MM-dd HH:mm");
  }

  return undefined;
}

export function apiDateToJsObject(date: string) {
  return new Date(date);
}

// Update the interval with new start and end dates.
// If the start date & end date end up reversed, adjust with one month difference.
export function updateInterval(
  interval: { startDate?: Date; endDate?: Date },
  newStartDate?: Date,
  newEndDate?: Date
) {
  const newInterval = deepClone(interval);

  if (newStartDate) {
    newInterval.startDate = newStartDate;
    if (newInterval.endDate && newStartDate >= newInterval.endDate) {
      newInterval.endDate = new Date(newStartDate);
      newInterval.endDate.setMonth(newStartDate.getMonth() + 1);
    }
  }
  if (newEndDate) {
    newInterval.endDate = newEndDate;
    if (newInterval.startDate && newEndDate <= newInterval.startDate) {
      newInterval.startDate = new Date(newEndDate);
      newInterval.startDate.setMonth(newEndDate.getMonth() - 1);
    }
  }

  return newInterval;
}

export const RelativeDateIntervals = {
  "current-month": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  "previous-month": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth() - 1, 1),
    endDate: new Date(date.getFullYear(), date.getMonth(), 0),
  }),
  "last-3-months": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth() - 2, 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  "last-6-months": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth() - 5, 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  "last-12-months": (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth() - 11, 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  "current-year": (date: Date) => ({
    startDate: new Date(date.getFullYear(), 0, 1),
    endDate: new Date(date.getFullYear() + 1, 0, 0),
  }),
  "previous-year": (date: Date) => ({
    startDate: new Date(date.getFullYear() - 1, 0, 1),
    endDate: new Date(date.getFullYear(), 0, 0),
  }),
  monthly: (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
  yearly: (date: Date) => ({
    startDate: new Date(date.getFullYear(), 0, 1),
    endDate: new Date(date.getFullYear() + 1, 0, 0),
  }),
  custom: (date: Date) => ({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
  }),
};

export type RelativeDateInterval = keyof typeof RelativeDateIntervals;
export function getRelativeDateInterval(name: RelativeDateInterval) {
  return RelativeDateIntervals[name](new Date());
}

export function getDateFromDashString(monthDate: string) {
  const [year, month] = monthDate.split("-");
  return new Date(parseInt(year), parseInt(month), 0);
}
