import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { PaginatedCacheValue } from "@/interfaces/generic/PaginatedCacheValue";
import { clearObject } from "@/lib/Object";
import { GenericCache } from "./GenericCache";

// @TODO rename class to PaginatedCache
export class OrganizationCache<T> extends GenericCache<PaginatedCacheValue<T>> {
  clearCache(entityIdentifier: EntityIdentifier) {
    // TODO: There is a bug created by the socket where he triggers postChange()
    // for this = undefined instance
    if (!this) {
      // TODO: Delete this console log on 12/2023
      // On 04.03.2024 I see that this error is triggered in the PWA application
      // On 09.05.2024 I see that this error is triggered when updating and entity second time in a row
      console.log("Custom ERROR: clearCache called with this = undefined");
      return;
    }

    if (!entityIdentifier.organizationId) {
      clearObject(this.cache.value);
    } else {
      for (const key in this.cache.value) {
        if (key.startsWith(`${entityIdentifier.organizationId}.`)) {
          delete this.cache.value[key];
        }
      }
    }
  }
}
