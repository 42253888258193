import type { Iso3166Type } from "@/interfaces/organization/Iso3166Type";
import axios from "axios";
import { handleApiError } from "./generic/ApiErrorHandler";

class FrontendApi {
  async fetchFrontend(locale: string) {
    try {
      const frontend = await axios.get("frontend?locale=" + locale);

      return frontend.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async fetchFrontendIso3166(locale: string) {
    try {
      const iso3166 = await axios.get("frontend/iso3166/" + locale);

      return iso3166.data.data as Iso3166Type | null;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useFrontendApi() {
  return new FrontendApi();
}
