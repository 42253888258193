import { useOrganizationExportApi } from "@/api/organization/OrganizationExportApi";
import type { OrganizationExportEntry } from "@/interfaces/organization/export/OrganizationExportEntry";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";

export const useOrganizationExportEntriesStore = defineStore(
  "organizationExportEntries",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationExportEntry>();
    const pageCache = new OrganizationCache<OrganizationExportEntry>();

    const genericActions =
      new OrganizationGenericActions<OrganizationExportEntry>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationExportApi(),
        enhanceEntity: enhanceEntity,
      });

    return {
      ...genericActions,
    };
  }
);

export async function enhanceEntity(
  entity: OrganizationExportEntry,
  storage: OrganizationEntityStorage<OrganizationExportEntry>
) {
  setEntityNameFields(
    entity,
    () => storage.get(getEntityIdentifier(entity))?.id
  );
}
