import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationUserRole } from "@/interfaces/organization/user/OrganizationUserRole";
import { useOrganizationUserRoleAbilityApi } from "./OrganizationUserRoleAbilityApi";

class OrganizationUserRoleApi extends OrganizationGenericEntityApi<OrganizationUserRole> {
  apiIdentifier = "organization_user_role";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/user-roles`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_user_role")
      .asOrganizationEntity()
      .asId("parent_organization_user_role_id")
      .asIds("tag_ids")
      .asBool("can_be_invoiced")
      .asBool("can_be_invoicing")
      .asBool("can_be_payrolled")
      .asBool("can_use_backoffice")
      .asBool("is_business_entity")
      .build() as OrganizationUserRole;
  }

  async getById(entityIdentifier: EntityIdentifier, include?: string[]) {
    const userRole = await super.getById(entityIdentifier);

    if (include && include.includes("abilities")) {
      userRole.abilities = await useOrganizationUserRoleAbilityApi().get({
        userRole: userRole,
      });
    }

    return userRole;
  }

  getDefaultInclude() {
    return ["tag_ids"];
  }
}

export function useOrganizationUserRoleApi() {
  return new OrganizationUserRoleApi();
}
