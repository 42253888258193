import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiConverter } from "@/api/mapping/ApiConverter";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { OrganizationContractStatus } from "@/interfaces/organization/contract/OrganizationContractStatus";
import axios from "axios";

class OrganizationContractStatusApi extends OrganizationGenericEntityApi<OrganizationContractStatus> {
  apiIdentifier = "organization_contract_status";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/contract-statuses`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_contract_status")
      .asOrganizationEntity()
      .asLangMap("title_lang_map")
      .asLangMap("description_lang_map")
      .asId("action_success_advance_to_organization_contract_status_id")
      .asId("deleted_by_user_id")
      .asNumber("order")
      .build() as OrganizationContractStatus;
  }

  async reorder(args: { organizationId: string; newOrderIds: string[] }) {
    const url = `${this.getBaseUrl({ organizationId: args.organizationId })}/reorder`;

    try {
      const headers = authHeader();
      const entitiesList = [];

      const request = ApiConverter.toRequestData(
        { organization_contract_ids: args.newOrderIds },
        new URLSearchParams()
      );
      const response = await axios.put(url, request, { headers });

      for (const entityFromApi of response.data?.data || []) {
        const entity = this.parseEntity(entityFromApi);
        entitiesList.push(entity);
      }

      return entitiesList;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationContractStatusApi() {
  return new OrganizationContractStatusApi();
}
