import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { useOrganizationUserRoleApi } from "./OrganizationUserRoleApi";

class OrganizationUserApi extends OrganizationGenericEntityApi<OrganizationUser> {
  apiIdentifier = "organization_user";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/users`;
  }

  parseEntity(entityFromApi: any) {
    if (entityFromApi.user_role?.data) {
      entityFromApi.user_role = useOrganizationUserRoleApi().parseEntity(
        entityFromApi.user_role.data
      );
    }

    return new ApiResponseParser(entityFromApi, "organization_user")
      .asOrganizationEntity()
      .asId("organization_user_role_id")
      .asDate("birthdate")
      .asObject("bank_account_information")
      .asObject("information")
      .asObject("organization_information")
      .asBool("organization_is_eu_vat_registered")
      .build() as OrganizationUser;
  }

  getDefaultInclude() {
    return ["tag_ids", "user_role"];
  }
}

export function useOrganizationUserApi() {
  return new OrganizationUserApi();
}
