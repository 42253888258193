import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { Organization } from "@/interfaces/organization/Organization";
import type { AnafConnectResponse } from "@/interfaces/organization/integrations/AnafConnectResponse";
import type { StripeConnectResponse } from "@/interfaces/organization/integrations/StripeConnectResponse";
import axios from "axios";

class OrganizationServiceIntegrationApi {
  apiIdentifier = "organization_service_integration";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/service-integration`;
  }

  async anafConnect(args: {
    organization: Organization;
    params: { [key: string]: string };
  }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/anaf/connect`,
        args.params,
        { headers: authHeader() }
      );

      return response.data as AnafConnectResponse;
    } catch (error) {
      handleApiError(error);
    }
  }

  async stripeConnect(args: {
    organization: Organization;
    params: { [key: string]: string };
  }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/stripe/connect`,
        args.params,
        { headers: authHeader() }
      );

      return response.data as StripeConnectResponse;
    } catch (error) {
      handleApiError(error);
    }
  }

  async stripeConnectConfirm(args: {
    organization: Organization;
    params: { [key: string]: string };
  }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/stripe/connect-confirm`,
        args.params,
        { headers: authHeader() }
      );

      return response.data as any;
    } catch (error) {
      handleApiError(error);
    }
  }

  async postRequestToCustomUrl(args: {
    url: string;
    params: { [key: string]: string };
  }) {
    try {
      const response = await axios.post(args.url, args.params, {
        headers: authHeader(),
      });

      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationServiceIntegrationApi() {
  return new OrganizationServiceIntegrationApi();
}
