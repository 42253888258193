import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationTag } from "@/interfaces/organization/OrganizationTag";

class OrganizationTagApi extends OrganizationGenericEntityApi<OrganizationTag> {
  apiIdentifier = "organization_tag";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/tags`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_tag")
      .asOrganizationEntity()
      .build() as OrganizationTag;
  }
}

export function useOrganizationTagApi() {
  return new OrganizationTagApi();
}
