import { useOrganizationMembershipInvoiceApi } from "@/api/organization/OrganizationMembershipInvoiceApi";
import type { OrganizationMembershipInvoice } from "@/interfaces/organization/membership/OrganizationMembershipInvoice";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";

export const useOrganizationMembershipInvoicesStore = defineStore(
  "organizationMembershipInvoices",
  () => {
    const storage =
      new OrganizationEntityStorage<OrganizationMembershipInvoice>();
    const pageCache = new OrganizationCache<OrganizationMembershipInvoice>();
    const api = useOrganizationMembershipInvoiceApi();

    const genericActions =
      new OrganizationGenericActions<OrganizationMembershipInvoice>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
        enhanceEntity: enhanceEntity,
      });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationMembershipInvoice,
  storage: OrganizationEntityStorage<OrganizationMembershipInvoice>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  setEntityNameFields(entity, () => {
    const invoice = storage.get(entityIdentifier);
    return invoice?.number;
  });
}
