import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationBankTransactionCostType } from "@/interfaces/organization/bank/OrganizationBankTransactionCostType";

class OrganizationBankTransactionCostTypeApi extends OrganizationGenericEntityApi<OrganizationBankTransactionCostType> {
  apiIdentifier = "organization_bank_transaction_cost_type";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/bank-transaction-cost-types`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(
      entityFromApi,
      "organization_bank_transaction_cost_type"
    )
      .asOrganizationEntity()
      .asId("parent_organization_bank_transaction_cost_type_id")
      .asBool("connection_required")
      .asArray("connection_types")
      .build() as OrganizationBankTransactionCostType;
  }
  getDefaultInclude() {
    return ["tag_ids"];
  }
}

export function useOrganizationBankTransactionCostTypeApi() {
  return new OrganizationBankTransactionCostTypeApi();
}
