import type { User } from "@/interfaces/user/User";
import { setOpt } from "@/lib/Object";
import { useFrontendStore } from "@/stores/Frontend";
import type { AxiosRequestHeaders } from "axios";

export function authHeader() {
  const user: User = JSON.parse(localStorage.getItem("user") as string);
  if (user && user.accessToken) {
    return {
      ...getUserAppNamespaceHeader(),
      ...setOpt("X-Socket-ID", window.Echo?.socketId()),
      Authorization: "Bearer " + user.accessToken,
    } as any;
  } else {
    return {} as AxiosRequestHeaders;
  }
}

export function localeHeader() {
  const frontend = useFrontendStore();
  return {
    ...getUserAppNamespaceHeader(),
    "Accept-Language": frontend.locale,
  } as any;
}

function getUserAppNamespaceHeader() {
  return { "user-app-namespace": window.userAppNamespace };
}
