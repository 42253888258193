import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationReceipt } from "@/interfaces/organization/OrganizationReceipt";
import { dateToApiFormat } from "@/lib/common/DateUtils";

class OrganizationReceiptApi extends OrganizationGenericEntityApi<OrganizationReceipt> {
  apiIdentifier = "organization_receipt";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/receipts`;
  }

  parseEntity(entityFromApi: any) {
    if (entityFromApi?.taxes) {
      entityFromApi.taxes = entityFromApi.taxes.data;
    }

    return new ApiResponseParser(entityFromApi, "organization_receipt")
      .asOrganizationEntity()
      .asId("organization_bank_account_id")
      .asId("organization_sender_user_id")
      .asId("organization_receiver_user_id")
      .asIds("tag_ids")
      .asDate("issued_at")
      .asDate("paid_at")
      .asBool("is_reverse")
      .asBool("is_tax_charged")
      .build() as OrganizationReceipt;
  }

  prepareEntity(entity: any) {
    super.prepareEntity(entity);
    if (entity.issued_at) {
      entity.issued_at = dateToApiFormat(entity.issued_at);
    }

    if (entity.paid_at) {
      entity.paid_at = dateToApiFormat(entity.paid_at);
    }
  }

  getDefaultInclude() {
    return ["tag_ids", "taxes"];
  }
}

export function useOrganizationReceiptApi() {
  return new OrganizationReceiptApi();
}
