import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { UserOrganizationSetting } from "@/interfaces/user/UserOrganizationSetting";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";

class UserOrganizationSettingsApi extends OrganizationGenericEntityApi<UserOrganizationSetting> {
  apiIdentifier = "user_organization_setting";

  getBaseUrl(args: { userId: string; organizationId: string }) {
    return `/users/${args.userId}/organizations/${args.organizationId}/settings`;
  }

  prepareEntity(entity: any) {
    super.prepareEntity(entity);
    delete entity.user_id;
    delete entity.id;
  }

  parseEntity(entityFromApi: any) {
    // @TODO this is a bad assignment. We should keep the id of the setting
    // in case we need it and use the key to match the settings
    entityFromApi.id = entityFromApi.key;
    delete entityFromApi.key;

    return new ApiResponseParser(entityFromApi, "user_organization_setting")
      .asOrganizationEntity()
      .asId("user_id")
      .build() as UserOrganizationSetting;
  }

  async put(entity: UserOrganizationSetting) {
    const entityIdentifier = getEntityIdentifier(entity) as {
      id: string;
      userId: string;
      organizationId: string;
    };

    const rawEntity: any = { ...entity };
    this.prepareEntity(rawEntity);

    return this.baseApiPostEntity({
      url: `${this.getBaseUrl(entityIdentifier)}/${entityIdentifier.id}`,
      entity: rawEntity,
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as UserOrganizationSetting;
      },
    });
  }
}

export function useUserOrganizationSettingsApi() {
  return new UserOrganizationSettingsApi();
}
