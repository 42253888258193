import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { Organization } from "@/interfaces/organization/Organization";
import axios from "axios";
import type { ServiceConfiguration } from "@/interfaces/organization/integrations/ServiceConfiguration";
import { ApiConverter } from "@/api/mapping/ApiConverter";

class OrganizationCredentialsApi {
  apiIdentifier = "organization_credentials";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/api-credentials`;
  }

  async getApiCredentials(args: { organization: Organization; type: string }) {
    try {
      const response = await axios.get(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}`,
        { headers: authHeader() },
      );

      const services = response?.data?.data || [];

      return services.find((item: any) => item?.service == args.type);
    } catch (error) {
      handleApiError(error);
    }
  }

  async addCredentials(args: {
    organization: Organization;
    configuration: ServiceConfiguration;
  }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}`,
        args.configuration,
        { headers: authHeader() },
      );

      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async editCredentials(args: {
    organization: Organization;
    id: string;
    configuration: ServiceConfiguration;
  }) {
    try {
      const request = ApiConverter.toRequestData(
        args.configuration,
        new URLSearchParams(),
      );

      const response = await axios.put(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/${args.id}`,
        request,
        { headers: authHeader() },
      );

      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationCredentialsApi() {
  return new OrganizationCredentialsApi();
}
