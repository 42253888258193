import { useOrganizationUserAddressApi } from "@/api/organization/OrganizationUserAddressApi";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import type { OrganizationUserAddress } from "@/interfaces/organization/user/OrganizationUserAddress";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationUserAdressesStore = defineStore(
  "organizationUserAdresses",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationUserAddress>();
    const pageCache = new OrganizationCache<OrganizationUserAddress>();

    const genericActions =
      new OrganizationGenericActions<OrganizationUserAddress>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationUserAddressApi(),
        ignoredKeysForDeletion: [
          "_entity",
          "id",
          "organization_id",
          "organization_user_id",
        ],
        enhanceEntity: enhanceEntity,
      });

    return {
      ...genericActions,
    };
  }
);

function enhanceEntity(
  entity: OrganizationUserAddress,
  storage: OrganizationEntityStorage<OrganizationUserAddress>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  const usersStore = useOrganizationUsersStore();

  addComputedField<OrganizationUserAddress, OrganizationUser>(
    entity,
    "user",
    () => storage.get(entityIdentifier)?.organization_user_id,
    async () =>
      usersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_user_id,
      })
  );
  addComputedField<OrganizationUserAddress, OrganizationUser>(
    entity,
    "deletedByUser",
    () => storage.get(entityIdentifier)?.deleted_by_user_id,
    async () =>
      usersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.deleted_by_user_id,
      })
  );

  setEntityNameFields(
    entity,
    () =>
      storage.get(entityIdentifier)?.name || storage.get(entityIdentifier)?.id
  );
}
