import { useOrganizationMembershipPaymentMethodApi } from "@/api/organization/OrganizationMembershipPaymentMethodApi";
import type { OrganizationMembershipPaymentMethod } from "@/interfaces/organization/membership/OrganizationMembershipPaymentMethod";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";

export const useOrganizationMembershipPaymentMethodsStore = defineStore(
  "organizationMembershipPaymentMethods",
  () => {
    const storage =
      new OrganizationEntityStorage<OrganizationMembershipPaymentMethod>();
    const pageCache =
      new OrganizationCache<OrganizationMembershipPaymentMethod>();
    const api = useOrganizationMembershipPaymentMethodApi();

    const genericActions =
      new OrganizationGenericActions<OrganizationMembershipPaymentMethod>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
        enhanceEntity: enhanceEntity,
      });

    const getPaymentMethodSetupInformation = async (organizationId: string) => {
      return api.getPaymentMethodSetupInformation({ organizationId });
    };

    const postPaymentMethod = async (entity: any) => {
      return api.postPaymentMethod(entity);
    };

    const makePrimary = async (entity: OrganizationMembershipPaymentMethod) => {
      return api.makePrimary(entity);
    };

    return {
      ...genericActions,
      getPaymentMethodSetupInformation,
      postPaymentMethod,
      makePrimary,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationMembershipPaymentMethod,
  storage: OrganizationEntityStorage<OrganizationMembershipPaymentMethod>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.id);
}
