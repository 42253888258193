import type { Entity } from "@/interfaces/generic/Entity";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import { ref, type ComputedRef, type Ref } from "vue";

export abstract class EntityStorage<T extends Entity, S> {
  storage: Ref<S>;

  constructor(initialStorage: S) {
    this.storage = ref(initialStorage as any);
  }

  abstract get(entityIdentifier: EntityIdentifier): T | undefined;
  abstract getComputed(
    entityIdentifier: EntityIdentifier
  ): ComputedRef<T | undefined>;
  abstract set(
    entityIdentifier: EntityIdentifier,
    entity: T,
    initializationCallback?: (...args: any[]) => void
  ): T;
  abstract remove(entityIdentifier: EntityIdentifier): T | undefined;

  markStale(entityIdentifier: EntityIdentifier): void {
    const entity = this.get(entityIdentifier);
    if (entity) {
      entity.stale = true;
    }
  }

  isInStore(entityIdentifier: EntityIdentifier): boolean {
    return this.get(entityIdentifier) != undefined;
  }
}
