export const organizationDashboardRoutes = [
  {
    path: "dashboard",
    name: "organizationDashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/views/organization/dashboard/Dashboard.vue"
      ),
    meta: {
      title: "general.title.dashboard",
      activeNav: "organization_dashboard_overview",
    },
  },
];
