import type { Entity } from "@/interfaces/generic/Entity";
import { useFrontendStore } from "@/stores/Frontend";
import { computed, ref, watch, type Ref } from "vue";

export function addComputedField<T, U>(
  entity: T,
  field: keyof typeof entity,
  dependencyGetter: () => any,
  callback: () => Promise<Ref<U | undefined>> | Ref<U | undefined>
) {
  const resultValue = ref<U | undefined>();

  Object.defineProperty(entity, field, {
    configurable: true,
    get() {
      if (
        dependencyGetter() !== undefined &&
        dependencyGetter() !== null &&
        dependencyGetter() !== "null"
      ) {
        setupField<U>(resultValue, callback);
      }

      // Watch for any changes in the dependency & re-evaluate.
      watch(dependencyGetter, () => {
        if (
          dependencyGetter() !== undefined &&
          dependencyGetter() !== null &&
          dependencyGetter() !== "null"
        ) {
          setupField<U>(resultValue, callback);
        } else {
          resultValue.value = undefined;
        }
      });

      Object.defineProperty(entity, field, {
        value: resultValue,
        writable: false,
        configurable: true,
      });

      return resultValue;
    },
  });
}

function setupField<U>(
  resultValue: Ref<U | undefined>,
  callback: () => Promise<Ref<U | undefined>> | Ref<U | undefined>
) {
  // Check if the callback result is wrapped into a promise or not.
  const callbackResult = callback();
  if (typeof (callbackResult as any)?.then == "function") {
    // If it is, wait for the result.
    const promise = callbackResult as Promise<Ref<U | undefined>>;
    promise.then((result) => {
      resultValue.value = result.value;
      watch(result, () => {
        resultValue.value = result.value;
      }); // Watch for any changes in the value.
    });
  } else {
    // If it's not, avoid calling the "Promise.then()" making the method linear in such cases.
    resultValue.value = (callbackResult as Ref<U | undefined>).value;
    // Watch for any changes in the value.
    watch(callbackResult, () => {
      resultValue.value = (callbackResult as Ref<U | undefined>).value;
    });
  }
}

export function setEntityNameFields(entity: Entity, suffix: () => any) {
  const frontend = useFrontendStore();
  addComputedField<Entity, string>(entity, "_name", suffix, () =>
    computed(() => {
      return `${suffix()}`;
    })
  );

  addComputedField<Entity, string>(entity, "_fullName", suffix, () =>
    computed(() => {
      return `${frontend.trans(`${entity._entity}.title.entity`)} ${suffix()}`;
    })
  );
}
