import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationEntityStorageData } from "@/interfaces/generic/storage/OrganizationEntityStorageData";
import type { OrganizationEntity } from "@/interfaces/organization/OrganizationEntity";
import { updateObject } from "@/lib/Object";
import { computed } from "vue";
import { EntityStorage } from "./EntityStorage";

export class OrganizationEntityStorage<
  T extends OrganizationEntity
> extends EntityStorage<T, OrganizationEntityStorageData<T>> {
  constructor() {
    super({} as OrganizationEntityStorageData<T>);
  }

  get(entityIdentifier: EntityIdentifier): T | undefined {
    if (
      entityIdentifier.organizationId == undefined ||
      entityIdentifier.id == undefined
    ) {
      return undefined;
    }

    return this.storage.value?.[entityIdentifier.organizationId]?.entities?.[
      entityIdentifier.id
    ];
  }

  getComputed(entityIdentifier: EntityIdentifier) {
    return computed(() => this.get(entityIdentifier));
  }

  set(
    entityIdentifier: EntityIdentifier,
    entity: T,
    initializationCallback?: (entityIdentifier: EntityIdentifier) => void
  ) {
    if (
      entityIdentifier.organizationId == undefined ||
      entityIdentifier.id == undefined
    ) {
      // TODO: Throw error, set should not be called without all the identifiers present.
      return entity;
    }

    // If the entity is already in the store, update it.
    if (this.isInStore(entityIdentifier)) {
      const existingEntity = this.get(entityIdentifier);
      if (existingEntity != undefined) {
        updateObject(existingEntity, entity);
        return existingEntity;
      }
    }

    // Initialize the map if it's the first entity from this organization.
    if (!(entityIdentifier.organizationId in this.storage.value)) {
      this.storage.value[entityIdentifier.organizationId] = {
        entities: {},
      };

      initializationCallback?.(entityIdentifier);
    }

    this.storage.value[entityIdentifier.organizationId].entities[
      entityIdentifier.id
    ] = entity;

    return entity;
  }

  remove(entityIdentifier: EntityIdentifier): T | undefined {
    if (
      entityIdentifier.organizationId == undefined ||
      entityIdentifier.id == undefined
    ) {
      return undefined;
    }

    const entity = this.get(entityIdentifier);
    delete this.storage.value?.[entityIdentifier.organizationId]?.entities?.[
      entityIdentifier.id
    ];

    return entity;
  }

  isInStore(id: EntityIdentifier): boolean {
    if (id.id == undefined && id.organizationId != undefined) {
      return id.organizationId in this.storage.value;
    }

    return this.get(id) != undefined;
  }
}
