<script setup lang="ts">
import { useFrontendStore } from "@/stores/Frontend";

withDefaults(defineProps<{
    background?: string;
  }>(), {
    background: "var(--enlivy-grey-20-color)",
  }
);

const frontend = useFrontendStore();

function toggleDarkMode() {
  frontend.updateDarkMode(!frontend.darkMode);

  if (frontend.darkMode) {
    document.body.classList.add("dark-theme");
  } else {
    document.body.classList.remove("dark-theme");
  }
}
</script>

<template>
  <div
    class="toggle-dark-mode"
    :class="{ active: frontend.darkMode }"
    @click="toggleDarkMode()"
  >
    <span class="toggle-dark-mode-button">
      <span class="icon"></span>
    </span>
  </div>
</template>

<style scoped lang="scss">
.toggle-dark-mode {
  width: 65px;
  height: 35px;
  padding: 5px;
  border-radius: 32px;
  background: v-bind(background);
  position: relative;
  cursor: pointer;
  .toggle-dark-mode-button {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: var(--enlivy-white-100-color);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    left: 5px;
    transition: all 0.1s;
    .icon {
      width: 20px;
      height: 20px;
      background: url("@/assets/icons/IconLight.svg") no-repeat;
    }
  }

  &.active {
    .toggle-dark-mode-button {
      left: 35px;
      background: var(--enlivy-grey-100-color);
      .icon {
        width: 13px;
        height: 13px;
        background: url("@/assets/icons/IconDark.svg") no-repeat;
      }
    }
  }
  &::after {
    @include preload(
      url("@/assets/icons/IconLight.svg") url("@/assets/icons/IconDark.svg")
    );
  }
}
</style>
