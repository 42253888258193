import { useUserOrganizationSettingsApi } from "@/api/user/UserOrganizationSettingsApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { UserOrganizationSetting } from "@/interfaces/user/UserOrganizationSetting";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { useGenericPusherUtils } from "../generic/GenericPusherUtils";
import { OrganizationCache } from "../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../generic/store/OrganizationGenericActions";

export const useUserOrganizationSettingsStore = defineStore(
  "userOrganizationSettings",
  () => {
    const storage = new OrganizationEntityStorage<UserOrganizationSetting>();
    const pageCache = new OrganizationCache<UserOrganizationSetting>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<UserOrganizationSetting>(
        "user.organization-settings",
        storage,
        genericActions.getById,
        pageCache.clearCache,
        `user.${entityIdentifier.userId}`
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions =
      new OrganizationGenericActions<UserOrganizationSetting>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useUserOrganizationSettingsApi(),
        ignoredKeysForDeletion: ["user_id"],
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
      });

    return {
      ...genericActions,
      create: undefined,
    };
  }
);

export function enhanceEntity(
  entity: UserOrganizationSetting,
  storage: OrganizationEntityStorage<UserOrganizationSetting>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.id);
}
