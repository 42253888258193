import { useOrganizationReceiptApi } from "@/api/organization/OrganizationReceiptApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationReceipt } from "@/interfaces/organization/OrganizationReceipt";
import type { OrganizationContract } from "@/interfaces/organization/contract/OrganizationContract";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { isEmpty } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { useOrganizationContractsStore } from "./OrganizationContracts";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationReceiptsStore = defineStore(
  "organizationReceipts",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationReceipt>();
    const pageCache = new OrganizationCache<OrganizationReceipt>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationReceipt>(
        "receipt",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationReceipt>({
      storage: storage,
      pageCache: pageCache,
      entityApi: useOrganizationReceiptApi(),
      enhanceEntity: enhanceEntity,
      initializationCallback: subscribeToSocket,
      prePersist: prePersist,
    });

    return {
      ...genericActions,
    };
  }
);

function enhanceEntity(
  entity: OrganizationReceipt,
  storage: OrganizationEntityStorage<OrganizationReceipt>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const organizationUsersStore = useOrganizationUsersStore();
  const organizationContractsStore = useOrganizationContractsStore();

  addComputedField<OrganizationReceipt, OrganizationContract>(
    entity,
    "contract",
    () => storage.get(entityIdentifier)?.organization_contract_id,
    async () =>
      organizationContractsStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_contract_id,
      })
  );

  addComputedField<OrganizationReceipt, OrganizationUser>(
    entity,
    "sender",
    () => storage.get(entityIdentifier)?.organization_sender_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_sender_user_id,
      })
  );

  addComputedField<OrganizationReceipt, OrganizationUser>(
    entity,
    "receiver",
    () => storage.get(entityIdentifier)?.organization_receiver_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_receiver_user_id,
      })
  );

  setEntityNameFields(
    entity,
    () => storage.get(entityIdentifier)?.receipt_number
  );
}

function prePersist(
  entity: OrganizationReceipt,
  existingEntity?: OrganizationReceipt
) {
  // Remove _entity from taxes
  if (entity.taxes) {
    for (const taxPackage of entity.taxes) {
      delete (taxPackage as { _entity?: any })["_entity"];
    }
  }

  // If it's an update operation...
  if (existingEntity) {
    // Remove extra tax packages.
    const remainingTaxesEntityIds =
      entity.taxes && !isEmpty(entity.taxes)
        ? entity.taxes.map((taxPackage) => taxPackage.id)
        : [];

    for (const taxPackage of existingEntity.taxes) {
      if (!remainingTaxesEntityIds.includes(taxPackage.id)) {
        if (!entity.taxes) {
          entity.taxes = [];
        }

        entity.taxes.push({
          id: taxPackage.id,
          _deleted: true,
        } as any);
      }
    }
  }
}
