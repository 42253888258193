import { useOrganizationUserRoleAbilityApi } from "@/api/organization/OrganizationUserRoleAbilityApi";
import { useOrganizationUserRoleApi } from "@/api/organization/OrganizationUserRoleApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationUserRole } from "@/interfaces/organization/user/OrganizationUserRole";
import type { OrganizationUserRoleAbility } from "@/interfaces/organization/user/OrganizationUserRoleAbility";
import { deepClone } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { watch } from "vue";

export const useOrganizationUserRolesStore = defineStore(
  "organizationUserRoles",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationUserRole>();
    const pageCache = new OrganizationCache<OrganizationUserRole>();
    const api = useOrganizationUserRoleApi();
    const abilityApi = useOrganizationUserRoleAbilityApi();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationUserRole>(
        "user_role",
        storage,
        asyncGetById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationUserRole>(
      {
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationUserRoleApi(),
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
      }
    );

    const addAbilities = async (entityIdentifier: EntityIdentifier) => {
      const entity = storage.get(entityIdentifier);
      if (entity) {
        // If user role is defined, get the abilities as well.
        entity.abilities = await abilityApi.get({
          userRole: entity,
        });
      } else {
        // Otherwise wait for the user role & then load the abilities.
        const userRole = genericActions.getEntityRef(entityIdentifier);
        watch(
          () => userRole.value?.id,
          async () => {
            const entity = storage.get(entityIdentifier);
            if (entity && !entity.abilities) {
              entity.abilities = await abilityApi.get({
                userRole: entity,
              });
            }
          }
        );
      }
    };

    const updateAbilities = async (
      newUserRole: OrganizationUserRole,
      inputAbilityList: string[],
      oldAbilityList: OrganizationUserRoleAbility[] | undefined
    ) => {
      const newAbilityList = inputAbilityList;
      const abilitiesForDeletion: string[] = [];
      const existentAbilities: string[] = [];

      if (oldAbilityList) {
        let index = oldAbilityList.length;
        while (index--) {
          const ability = oldAbilityList[index];
          if (newAbilityList.includes(ability.ability)) {
            // Removed already existing abilities form input list
            newAbilityList.splice(newAbilityList.indexOf(ability.ability), 1);
            existentAbilities.push(ability.ability);
          } else {
            abilitiesForDeletion.push(ability.ability);
          }
        }
      }

      newUserRole.abilities = existentAbilities
        .concat(newAbilityList)
        .map((item) => {
          return { ability: item } as OrganizationUserRoleAbility;
        });

      // Delete unselected, existing abilities
      if (abilitiesForDeletion.length != 0) {
        await abilityApi.deleteArray({
          userRole: newUserRole,
          abilityList: abilitiesForDeletion,
        });
      }

      // Add the new abilities
      if (newAbilityList.length != 0) {
        await abilityApi.post({
          userRole: newUserRole,
          abilities: newAbilityList,
        });
      }
    };

    const lazyGetById = (
      entityIdentifier: EntityIdentifier,
      criticalFetch?: boolean,
      includeAbilities = false
    ) => {
      const userRole = genericActions.lazyGetById(
        entityIdentifier,
        criticalFetch
      );

      if (includeAbilities) {
        addAbilities(entityIdentifier);
      }

      return userRole;
    };

    const asyncGetById = async (
      entityIdentifier: EntityIdentifier,
      includeAbilities = false
    ) => {
      const userRole = await genericActions.getById(entityIdentifier);

      if (includeAbilities) {
        addAbilities(entityIdentifier);
      }

      return userRole;
    };

    const create = async (
      userRole: OrganizationUserRole,
      abilityList: string[]
    ) => {
      const createduserRole = await genericActions.create(userRole);

      // Add new abilities
      if (createduserRole && abilityList && abilityList.length != 0) {
        createduserRole.abilities = await abilityApi.post({
          userRole: createduserRole,
          abilities: abilityList,
        });
      }

      return createduserRole;
    };

    const update = async (
      userRole: OrganizationUserRole,
      abilityList: string[]
    ) => {
      userRole = deepClone(userRole);

      const entityIdentifier = getEntityIdentifier(userRole);
      const oldUserRole = deepClone(
        storage.get(entityIdentifier)
      ) as OrganizationUserRole;

      delete userRole["abilities"];
      genericActions.prepareEntityUpdate(userRole);

      let newUserRole: OrganizationUserRole;
      if (Object.keys(userRole).length > 3) {
        // Update the organizaion user role
        const createdUserRole = await api.put(userRole);
        newUserRole = genericActions.storeEntities([createdUserRole])[0];
      } else {
        newUserRole = storage.get(entityIdentifier) as OrganizationUserRole;
      }

      if (abilityList) {
        await updateAbilities(newUserRole, abilityList, oldUserRole.abilities);
      }

      pageCache.clearCache(entityIdentifier);
      return newUserRole;
    };

    return {
      ...genericActions,
      lazyGetById,
      asyncGetById,
      create,
      update,
    };
  }
);

function enhanceEntity(
  entity: OrganizationUserRole,
  storage: OrganizationEntityStorage<OrganizationUserRole>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  const store = useOrganizationUserRolesStore();
  addComputedField<OrganizationUserRole, OrganizationUserRole>(
    entity,
    "parentUserRole",
    () => storage.get(entityIdentifier)?.parent_organization_user_role_id,
    async () =>
      store.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.parent_organization_user_role_id,
      })
  );

  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.name);
}
