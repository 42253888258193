import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { User } from "@/interfaces/user/User";
import { useAuthStore } from "@/stores/Auth";
import axios from "axios";

class UserApi {
  getBaseUrl(id?: string) {
    return `/users/${id ? id : "me"}`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "user")
      .asOrganizationEntity()
      .asDate("phone_verified_at")
      .asDate("phone_verification_sent_at")
      .asDate("email_verified_at")
      .asDate("email_verification_sent_at")
      .build() as User;
  }

  async getLoggedUserInfo() {
    try {
      const response = await axios.get(this.getBaseUrl(), {
        headers: authHeader(),
      });

      return this.parseEntity(response.data.data);
    } catch (error) {
      useAuthStore().clearUserSession();
      handleApiError(error);
    }
  }

  async updateUser(user: User) {
    try {
      const response = await axios.put(
        this.getBaseUrl(),
        { ...user },
        { headers: authHeader() }
      );

      return this.parseEntity(response.data.data);
    } catch (error) {
      handleApiError(error);
    }
  }

  async updatePhone(phone_number: string, phone_number_country_code: string) {
    try {
      const response = await axios.put(
        `${this.getBaseUrl()}/phone`,
        {
          phone_number: phone_number,
          phone_number_country_code: phone_number_country_code,
        },
        { headers: authHeader() }
      );

      return this.parseEntity(response.data.data);
    } catch (error) {
      handleApiError(error);
    }
  }

  async sendEmailVerificationToken(id?: string) {
    try {
      await axios.get(`${this.getBaseUrl(id)}/email/verification-token`, {
        headers: authHeader(),
      });
    } catch (error) {
      handleApiError(error);
    }
  }

  async checkEmailVerificationToken(token: string, id?: string) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl(id)}/email/confirm-verification-token`,
        { token: token },
        { headers: authHeader() }
      );

      return this.parseEntity(response.data.data);
    } catch (error) {
      handleApiError(error);
    }
  }

  async sendNewPhoneVerificationToken(id?: string) {
    try {
      await axios.get(`${this.getBaseUrl(id)}/phone/verification-token`, {
        headers: authHeader(),
      });
    } catch (error) {
      handleApiError(error);
    }
  }

  async requestPhoneUnlink(id?: string) {
    try {
      await axios.post(`${this.getBaseUrl(id)}/phone/request-unlink`, null, {
        headers: authHeader(),
      });
    } catch (error) {
      handleApiError(error);
    }
  }

  async unlinkPhone(token: string, id?: string) {
    try {
      const response = await axios.delete(`${this.getBaseUrl(id)}/phone`, {
        params: { token: token },
        headers: authHeader(),
      });

      return this.parseEntity(response.data.data);
    } catch (error) {
      handleApiError(error);
    }
  }

  async checkPhoneVerificationToken(token: string, id?: string) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl(id)}/phone/confirm-verification-token`,
        { token: token },
        { headers: authHeader() }
      );

      return this.parseEntity(response.data.data);
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useUserApi() {
  return new UserApi();
}
