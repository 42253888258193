import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationTaxRate } from "@/interfaces/organization/tax/tax-rates/OrganizationTaxRate";

class OrganizationTaxRateApi extends OrganizationGenericEntityApi<OrganizationTaxRate> {
  apiIdentifier = "organization_tax_rate";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/tax-rates`;
  }

  parseEntity(entityFromApi: any) {
    if (entityFromApi?.locations) {
      entityFromApi.locations = entityFromApi.locations.data;
    }

    return new ApiResponseParser(entityFromApi, "organization_tax_rate")
      .asOrganizationEntity()
      .asString("name")
      .asNumber("rate")
      .asNumber("priority")
      .asString("eu_vat_class")
      .asBool("has_locations")
      .asBool("is_business_entity")
      .asBool("is_compound")
      .asBool("is_shipping")
      .asBool("is_inclusive")
      .asBool("is_eu_vat_registered")
      .asBool("has_eu_vat_properties")
      .asId("deleted_by_user_id")
      .build() as OrganizationTaxRate;
  }

  getDefaultInclude() {
    return ["tag_ids", "locations"];
  }
}

export function useOrganizationTaxRateApi() {
  return new OrganizationTaxRateApi();
}
