import axios from "axios";
import { handleApiError } from "./generic/ApiErrorHandler";

class TimeZoneApi {
  async fetchTimeZones() {
    try {
      const timeZones = await axios.get("misc/timezones");

      return timeZones.data;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useTimeZoneApi() {
  return new TimeZoneApi();
}
