import type { Entity } from "@/interfaces/generic/Entity";

const dummyMap: { [key: string]: string } = {
  administration_console_job: "",
  administration_membership: "",
  administration_user_account_type: "",
  administration_user_invitation_code: "",
  organization_analytics: "",
  organization: "",
  organization_bank_account: "",
  organization_bank_account_data_bridge: "",
  organization_bank_transaction: "",
  organization_bank_transaction_cost_type: "",

  organization_bank_transaction_import: "",
  organization_contract: "",
  organization_contract_status: "",
  organization_credentials: "",
  organization_data_push: "",
  organization_export_entry: "",
  organization_file: "",
  organization_billing_schedule: "",
  organization_invoice: "",
  organization_invoice_network_exchange: "",
  organization_invoice_notification_log: "",
  organization_invoice_prefix: "",
  organization_membership: "",
  organization_membership_invoice: "",
  organization_membership_payment_method: "",
  organization_misc: "",
  organization_payslip: "",
  organization_payslip_schema: "",
  organization_product: "",
  organization_receipt: "",
  organization_report: "",
  organization_report_schema: "",
  organization_report_schema_field: "",
  organization_service_integration: "",
  organization_tag: "",
  organization_task: "",
  organization_tax_class: "",
  organization_tax_rate: "",
  organization_user_activity: "",
  organization_user_address: "",
  organization_user: "",
  organization_user_role_ability: "",
  organization_user_role: "",
  organization_webhook: "",
  organization_webhook_notification: "",
  user: "",
  user_api_token: "",
  user_organization_setting: "",
  auth: "",
  timezone: "",
};

const entityToAbortControllerMap: { [key: string]: AbortController } =
  Object.fromEntries(
    Object.keys(dummyMap).map((key) => [key, new AbortController()])
  );

export function getAbortController(entity: Entity | string) {
  return typeof entity == "string"
    ? entityToAbortControllerMap[entity]
    : entityToAbortControllerMap[entity._entity];
}
