<script setup lang="ts">
import IconInfo from "@/components/icons/general/notice/IconInfo.vue";
import { createTooltip } from "@/composables/Tooltip";
import type { TooltipOrientation } from "@/interfaces/common/Tooltip";
import { ref, useSlots } from "vue";

const props = withDefaults(defineProps<{
  orientation?: TooltipOrientation;
  iconWidth?: number;
  iconHeight?: number;
}>(), {
  orientation: "bottom",
});

const helpRef = ref();
const slots = useSlots();
const tooltip = createTooltip(slots.default);

function displayTooltip() {
  // The component has been unmounted
  if (helpRef.value == null) return;

  tooltip.attachRect = helpRef.value.getBoundingClientRect();
  tooltip.orientation = props.orientation;
  tooltip.visible = true;
}
</script>

<template>
  <div
    ref="helpRef"
    class="help"
    v-if="$slots.default"
    @mouseenter="displayTooltip()"
    @mouseleave="tooltip.visible = false"
  >
    <slot name="trigger">
      <IconInfo
        :width="iconWidth"
        :height="iconHeight"
        color="var(--enlivy-grey-40-color)"
      />
    </slot>
  </div>
</template>

<style scoped lang="scss">
.help {
  display: flex;
  align-items: center;
}
</style>
