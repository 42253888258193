import { useOrganizationUserApi } from "@/api/organization/OrganizationUserApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import type { OrganizationUserRole } from "@/interfaces/organization/user/OrganizationUserRole";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { useOrganizationUserRolesStore } from "./OrganizationUserRoles";

export const useOrganizationUsersStore = defineStore(
  "organizationUsers",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationUser>();
    const pageCache = new OrganizationCache<OrganizationUser>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationUser>(
        "user",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationUser>({
      storage: storage,
      pageCache: pageCache,
      entityApi: useOrganizationUserApi(),
      enhanceEntity: enhanceEntity,
      initializationCallback: subscribeToSocket,
      preStore: preStore,
    });

    return {
      ...genericActions,
    };
  }
);

function enhanceEntity(
  entity: OrganizationUser,
  storage: OrganizationEntityStorage<OrganizationUser>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  const userRolesStore = useOrganizationUserRolesStore();
  addComputedField<OrganizationUser, OrganizationUserRole>(
    entity,
    "userRole",
    () => storage.get(entityIdentifier)?.organization_user_role_id,
    async () =>
      userRolesStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_user_role_id,
      })
  );

  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.name);
}

function preStore(entity: any) {
  const userRolesStore = useOrganizationUserRolesStore();
  if (entity.user_role) {
    userRolesStore.storeEntities([entity.user_role]);
    delete entity.user_role;
  }
}
