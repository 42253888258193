import { useOrganizationInvoicePrefixApi } from "@/api/organization/OrganizationInvoicePrefixApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationInvoicePrefix } from "@/interfaces/organization/invoice/OrganizationInvoicePrefix";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { useGenericPusherUtils } from "../../generic/GenericPusherUtils";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";

export const useOrganizationInvoicePrefixesStore = defineStore(
  "organizationInvoicePrefixes",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationInvoicePrefix>();
    const pageCache = new OrganizationCache<OrganizationInvoicePrefix>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationInvoicePrefix>(
        "invoice_prefix",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions =
      new OrganizationGenericActions<OrganizationInvoicePrefix>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationInvoicePrefixApi(),
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
      });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationInvoicePrefix,
  storage: OrganizationEntityStorage<OrganizationInvoicePrefix>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.alias);
}
