import { redirectIfAuth } from "../RedirectBeforeEnter";

export const basicAppRoutes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "login" },
  },
  {
    path: "/",
    name: "BasicLayout",
    component: () => import("@/views/layout/BasicLayout.vue"),
    meta: { layout: "BasicLayout", has_dot_animation: true },
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/LoginView.vue"),
        beforeEnter: redirectIfAuth,
        meta: { title: "authentication.title.login" },
      },

      {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/auth/LogoutView.vue"),
      },

      {
        path: "/register",
        name: "register",
        component: () => import("@/views/auth/RegisterView.vue"),
        beforeEnter: redirectIfAuth,
        meta: { title: "authentication.title.register" },
      },
      {
        path: "/confirm-email-address",
        name: "confirmEmailAddress",
        component: () => import("@/views/auth/ConfirmEmailAddress.vue"),
        meta: { title: "authentication.title.register" },
      },
      {
        path: "/forgot-password",
        name: "forgotPassword",
        component: () => import("@/views/auth/ForgotPassword.vue"),
        beforeEnter: redirectIfAuth,
        meta: { title: "authentication.title.reset_password" },
      },
      {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("@/views/auth/ResetPassword.vue"),
        beforeEnter: redirectIfAuth,
        meta: { title: "authentication.title.reset_password" },
      },
      {
        path: "/reset-password-success",
        name: "resetPasswordSuccess",
        component: () => import("@/views/auth/ResetPasswordSuccess.vue"),
        beforeEnter: redirectIfAuth,
        meta: { title: "authentication.title.reset_password" },
      },
    ],
  },

  {
    path: "/policies/terms-and-conditions",
    name: "policiesTermsAndConditions",
    component: () => import("@/views/policies/TermsAndConditions.vue"),
    meta: { title: "general.policies.terms_and_conditions.title" },
  },
];
