import { useOrganizationUserActivityApi } from "@/api/organization/OrganizationUserActivityApi";
import type { Organization } from "@/interfaces/organization/Organization";
import type { OrganizationEntity } from "@/interfaces/organization/OrganizationEntity";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import type { OrganizationUserActivity } from "@/interfaces/organization/user/OrganizationUserActivity";
import { setIf } from "@/lib/Object";
import { dateToApiFormat } from "@/lib/common/DateUtils";
import { getStore } from "@/lib/generic/GenericUtils";
import { defineStore } from "pinia";
import type { ComputedRef } from "vue";
import { OrganizationCache } from "../generic/cache/OrganizationCache";
import { AdministrationEntityStorage } from "../generic/storage/AdministrationEntityStorage";
import { OrganizationGenericActions } from "../generic/store/OrganizationGenericActions";

export const useOrganizationUserActivitiesStore = defineStore(
  "organizationActivity",
  () => {
    const storage = new AdministrationEntityStorage<OrganizationUserActivity>();
    const pageCache = new OrganizationCache<OrganizationUserActivity>();

    const api = useOrganizationUserActivityApi();

    const genericActions =
      new OrganizationGenericActions<OrganizationUserActivity>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
      });

    const getUserActivity = async (args: {
      organization: Organization;
      user: OrganizationUser;
      startDate?: Date;
      endDate?: Date;
      page?: number;
      limit?: number;
    }) => {
      const keyElems = [
        args.organization.id,
        args.user.id,
        "user_activity",
        args.page,
        args.limit,
      ];
      if (args.startDate) {
        keyElems.push(dateToApiFormat(args.startDate, false));
      }
      if (args.endDate) {
        keyElems.push(dateToApiFormat(args.endDate, false));
      }

      const key = keyElems.join(".");
      const entityMap = new Map<string, OrganizationUserActivity[]>();
      const fetches = [];

      const cachedResponse = pageCache.get(key);
      if (cachedResponse) {
        return cachedResponse;
      }

      const apiResponse = await api.getUserActivity({
        organization: args.organization,
        user: args.user,
        page: args.page,
        limit: args.limit,
        params: {
          ...setIf(
            args.startDate != undefined,
            "created_at_from",
            dateToApiFormat(args.startDate!)?.split(" ")[0]
          ),
          ...setIf(
            args.endDate != undefined,
            "created_at_to",
            dateToApiFormat(args.endDate!)?.split(" ")[0]
          ),
        },
      });

      // Map activities based on entity type
      for (const activity of apiResponse.entities) {
        const mapEntry = entityMap.get(activity.entity);
        if (mapEntry) {
          mapEntry.push(activity);
        } else {
          entityMap.set(activity.entity, [activity]);
        }
      }

      // Call each store and get the actual entity
      for (const [entityType, activities] of entityMap) {
        const entityIds = activities.map((el) => el.id);

        const promise = getStore(entityType).getByIds(entityIds, {
          organizationId: args.organization.id,
          userId: args.user.id,
        });

        fetches.push(promise);
      }

      // Attach the actual entity to the OrganizationUserActivity object
      (await Promise.all(fetches)).forEach(
        (result: ComputedRef<OrganizationEntity[]>) => {
          result.value.forEach((entity: OrganizationEntity) => {
            const entityMapArrayForEntity = entityMap.get(entity._entity);
            if (entityMapArrayForEntity) {
              for (const activity of entityMapArrayForEntity) {
                if (activity.id == entity.id) {
                  activity.actualEntity = entity;
                  break;
                }
              }
            } else {
              console.error(
                "Error when fetching the actual entity for user activity",
                entity.id
              );
            }
          });
        }
      );

      // Store and return the response
      const storedEntities = genericActions.storeEntities(apiResponse.entities);

      return pageCache.set(key, {
        entities: genericActions.getEntityRefs(storedEntities),
        pagination: apiResponse.pagination,
        navigation: apiResponse.navigation,
        navigationByState: apiResponse.navigationByState,
      });
    };

    return {
      getUserActivity,
    };
  }
);
