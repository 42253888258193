import { useOrganizationReportApi } from "@/api/organization/OrganizationReportApi";
import type { OrganizationReport } from "@/interfaces/organization/report/OrganizationReport";
import type { OrganizationReportSchema } from "@/interfaces/organization/report/OrganizationReportSchema";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { useOrganizationReportSchemasStore } from "./OrganizationReportSchemas";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationReportsStore = defineStore(
  "organizationReports",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationReport>();
    const pageCache = new OrganizationCache<OrganizationReport>();

    const genericActions = new OrganizationGenericActions<OrganizationReport>({
      storage: storage,
      pageCache: pageCache,
      entityApi: useOrganizationReportApi(),
      ignoredKeysForDeletion: [
        "_entity",
        "id",
        "organization_id",
        "report_map",
      ],
      enhanceEntity: enhanceEntity,
    });

    return {
      ...genericActions,
    };
  }
);

function enhanceEntity(
  entity: OrganizationReport,
  storage: OrganizationEntityStorage<OrganizationReport>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const organizationUsersStore = useOrganizationUsersStore();
  addComputedField<OrganizationReport, OrganizationUser>(
    entity,
    "user",
    () => storage.get(entityIdentifier)?.organization_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_user_id,
      })
  );

  const reportSchemasStore = useOrganizationReportSchemasStore();
  addComputedField<OrganizationReport, OrganizationReportSchema>(
    entity,
    "reportSchema",
    () => storage.get(entityIdentifier)?.organization_report_schema_id,
    async () =>
      reportSchemasStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_report_schema_id,
      })
  );

  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.id);
}
