import { useOrganizationBankAccountDataApi } from "@/api/organization/OrganizationBankAccountDataApi";
import type { OrganizationBankAccountDataBridge } from "@/interfaces/organization/bank/account-data/OrganizationBankAccountDataBridge";
import type { OrganizationBankAccount } from "@/interfaces/organization/bank/OrganizationBankAccount";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { deepClone } from "@/lib/Object";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";
import { useOrganizationBankAccountsStore } from "./OrganizationBankAccounts";

export const useOrganizationBankAccountDataStore = defineStore(
  "organizationBankAccountData",
  () => {
    const bridgeStorage =
      new OrganizationEntityStorage<OrganizationBankAccountDataBridge>();
    const pageCache =
      new OrganizationCache<OrganizationBankAccountDataBridge>();
    const api = useOrganizationBankAccountDataApi();

    const genericActions =
      new OrganizationGenericActions<OrganizationBankAccountDataBridge>({
        storage: bridgeStorage,
        pageCache: pageCache,
        entityApi: api,
        enhanceEntity: enhanceEntity,
      });

    const saveToLocalStorage = (
      bridge: OrganizationBankAccountDataBridge[]
    ) => {
      localStorage.setItem("bankAccountDataBridge", JSON.stringify(bridge));
    };

    const getFromLocalStorage = () => {
      const bridge: OrganizationBankAccountDataBridge[] = JSON.parse(
        localStorage.getItem("bankAccountDataBridge") as string
      );
      if (!Array.isArray(bridge)) {
        return [];
      }

      return bridge;
    };

    const updateBankAccountDataSession = (
      institutionId: string,
      bridge: OrganizationBankAccountDataBridge
    ) => {
      const localStorageBridge = getFromLocalStorage();

      // Make sure the institution id is set.
      bridge.institution_id = institutionId;

      // Update session
      const existingSessionIndex = localStorageBridge.findIndex(
        (session) => session.institution_id == institutionId
      );
      if (existingSessionIndex != -1) {
        localStorageBridge[existingSessionIndex] = bridge;
      } else {
        localStorageBridge.push(bridge);
      }

      saveToLocalStorage(localStorageBridge);
    };

    const getBankAccountDataBridgeByRequisitionId = (requisitionId: string) => {
      const bridge = getFromLocalStorage();
      return bridge.find((session) => session.requisition_id == requisitionId);
    };

    const initSession = async (
      organizationId: string,
      institutionId: string,
      redirectUrl: string
    ) => {
      const activeBankAccountDataBridge = await api.initSession({
        organizationId: organizationId,
        institutionId: institutionId,
        redirectUrl: redirectUrl,
      });

      if (activeBankAccountDataBridge) {
        updateBankAccountDataSession(
          institutionId,
          activeBankAccountDataBridge
        );
      }

      return activeBankAccountDataBridge;
    };

    const requisition = async (
      organizationId: string,
      bridgeId: string,
      institutionId: string
    ) => {
      const activeBankAccountDataBridge = await api.requisition({
        organizationId: organizationId,
        bridgeId: bridgeId,
      });

      if (activeBankAccountDataBridge) {
        updateBankAccountDataSession(
          institutionId,
          activeBankAccountDataBridge
        );
      }

      return activeBankAccountDataBridge;
    };

    const getAccount = async (organizationId: string, bridgeId: string) => {
      // TODO: Store entities for BankAccountData Accounts
      return await api.getAccount({
        organizationId: organizationId,
        bridgeId: bridgeId,
      });
    };

    const pair = async (args: {
      organizationId: string;
      bridgeId: string;
      accountId: string;
      organizationBankAccountId: string;
      sync_from?: Date;
    }) => {
      const bridge = await api.pair(args);
      const storedEntities = genericActions.storeEntities([bridge]);

      return storedEntities[0];
    };

    const unPair = async (args: {
      organizationId: string;
      bridgeId: string;
      organizationBankAccountId: string;
    }) => {
      const unpairedBridge = await api.unPair(args);
      const storedEntities = genericActions.storeEntities([unpairedBridge]);

      // Set the bank account bridge fields as undefined
      const bankAccountsStore = useOrganizationBankAccountsStore();
      const bankAccount = await bankAccountsStore.getById({
        organizationId: args.organizationId,
        id: args.organizationBankAccountId,
      });

      if (bankAccount.value) {
        const newEntity = {
          ...deepClone(bankAccount.value),
          organization_bank_account_data_bridge_id: undefined,
          organization_bank_account_data_bridge_account: undefined,
          organization_bank_account_data_bridge_last_synced_at: undefined,
          organization_bank_account_data_bridge_next_sync_at: undefined,
          organization_bank_account_data_bridge_sync_from: undefined,
        } as OrganizationBankAccount;

        bankAccountsStore.storeEntities([newEntity]);
      }

      return storedEntities[0];
    };

    return {
      ...genericActions,
      initSession,
      requisition,
      getAccount,
      pair,
      unPair,
      getBankAccountDataBridgeByRequisitionId,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationBankAccountDataBridge,
  storage: OrganizationEntityStorage<OrganizationBankAccountDataBridge>
) {
  setEntityNameFields(
    entity,
    () => storage.get(getEntityIdentifier(entity))?.id
  );
}
