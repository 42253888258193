<script setup lang="ts">
import Button from "@/components/elements/buttons/Button.vue";
import { useFrontendStore } from "@/stores/Frontend";
import { useUserStore } from "@/stores/user/User";
import { closeDialog } from "vue3-promise-dialog";
import BaseDialog from "./BaseDialog.vue";

defineProps<{
  title: string;
  text?: string;
  error?: string;
}>();

const frontend = useFrontendStore();
const userStore = useUserStore();
</script>

<template>
  <BaseDialog :title="title" @close="closeDialog(false)" width="500px">
    <p v-if="text" v-html="text"></p>
    <p v-if="error" v-html="error"></p>

    <template #actions>
      <RouterLink
        class="button"
        @click="closeDialog(false)"
        :to="{
          name: 'organizationMembership',
          params: { organizationId: userStore.activeOrganization?.id },
        }"
        >{{ frontend.trans("membership.actions.upgrade") }}</RouterLink
      >
      <Button class="secondary" @clicked="closeDialog(false)">
        {{ frontend.trans("general.operation.close") }}
      </Button>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
p {
  font-size: 16px;
  color: var(--enlivy-grey-100-color);
}
</style>
