import { useAdministrationUserAccountTypeApi } from "@/api/administration/AdministrationUserAccountTypeApi";
import type { AdministrationUserAccountType } from "@/interfaces/administration/user-account-type/AdministrationUserAccountType";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { AdministrationEntityStorage } from "@/stores/generic/storage/AdministrationEntityStorage";
import { AdministrationGenericActions } from "@/stores/generic/store/AdministrationGenericActions";
import { defineStore } from "pinia";

export const useAdministrationUserAccountTypesStore = defineStore(
  "administrationUserAccountType",
  () => {
    const storage =
      new AdministrationEntityStorage<AdministrationUserAccountType>();
    const pageCache = new OrganizationCache<AdministrationUserAccountType>();

    const genericActions =
      new AdministrationGenericActions<AdministrationUserAccountType>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useAdministrationUserAccountTypeApi(),
        enhanceEntity: enhanceEntity,
      });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: AdministrationUserAccountType,
  storage: AdministrationEntityStorage<AdministrationUserAccountType>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.name);
}
