import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { AdministrationConsoleJob } from "@/interfaces/administration/console-job/AdministrationConsoleJob";
import axios from "axios";
import { AdministrationGenericEntityApi } from "../generic/AdministrationGenericEntityApi";
import { handleApiError } from "../generic/ApiErrorHandler";
import { ApiConverter } from "../mapping/ApiConverter";
import { ApiResponseParser } from "../mapping/ApiResponseParser";

class AdministrationConsoleJobApi extends AdministrationGenericEntityApi<AdministrationConsoleJob> {
  apiIdentifier = "administration_console_job";

  getBaseUrl(): string {
    return `/console-jobs`;
  }

  parseEntity(entityFromApi: any) {
    const entityParsed = new ApiResponseParser(
      entityFromApi,
      "administration_console_job"
    ).build() as AdministrationConsoleJob;

    entityParsed.id = entityFromApi.command;
    return entityParsed;
  }

  async runJob(job: AdministrationConsoleJob) {
    try {
      const headers = authHeader();
      const request = ApiConverter.toRequestData(job, new FormData());
      const response = await axios.post(this.getBaseUrl(), request, {
        headers,
      });

      return response.data as { status: string; output: string[] };
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useAdministrationConsoleJobApi() {
  return new AdministrationConsoleJobApi();
}
