import type { User } from "@/interfaces/user/User";
import { criticalFetchErrorGlobalFlag } from "@/lib/handler/ErrorHandler";
import { useFrontendStore } from "@/stores/Frontend";
import { useUserStore } from "@/stores/user/User";
import { createRouter, createWebHistory } from "vue-router";
import { administrationRoutes } from "./routes/AdministrationRoutes";
import { basicAppRoutes } from "./routes/BasicAppRoutes";
import { loggedUserRoutes } from "./routes/LoggedUserRoutes";
import { organizationRoutes } from "./routes/OrganizationRoutes";
import { setupProcessRoutes } from "./routes/SetupProcessRoutes";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes: [
    ...basicAppRoutes,
    ...setupProcessRoutes,

    {
      path: "/",
      name: "AppLayout",
      component: () => import("@/views/layout/AppLayout.vue"),
      meta: { layout: "AppLayout" },
      children: [
        ...administrationRoutes,
        ...loggedUserRoutes,
        ...organizationRoutes,
      ],
    },

    {
      path: "/:pathMatch(.*)*",
      name: "pageNotFound",
      component: () => import("@/views/PageNotFound.vue"),
    },
  ],
});

router.beforeEach(async (to) => {
  const userStore = useUserStore();
  const frontend = useFrontendStore();

  // Reset the global error flags.
  criticalFetchErrorGlobalFlag.value = false;

  const user: User = JSON.parse(localStorage.getItem("user") as string);
  if (user) {
    if (!userStore.isLoaded) {
      await userStore.fetchLoggedUserInfo();
    }
  }

  if (!frontend.isLoaded) {
    await frontend.fetchFrontend();
  }

  if (user) {
    // If the user didn't confirm the email address yet, redirect to that page.
    if (
      userStore.loggedUserInfo.email_verified_at == null &&
      to.name != "confirmEmailAddress" &&
      to.name != "logout"
    ) {
      return { name: "confirmEmailAddress" };
    }

    // This call is needed when the user refreshes the page or opens a new tab
    try {
      await userStore.setActiveOrganization(to.params.organizationId as string);
    } catch {
      throw new Error("internal_app_failed_to_retrieve_organization");
    }

    if (to.meta.acl && !userStore.hasAccess(to.meta.acl as string)) {
      if (!(to.meta.acl as string).includes("administration")) {
        return { name: "pageNotFound" };
      }
    }
  }

  const publicPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/reset-password",
    "/reset-password-success",
    "/confirm-email-address",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  if (authRequired && !loggedIn) {
    return { name: "login" };
  }

  frontend.setTitlePage(to.meta.title as string | undefined);

  // @todo we have a bug, if user is clicking on the dashboard icon, when on a page.
  // It will refresh instead of redirecting, this is only when the target url
  // will be equal to /dashboard, not the current logic to go to current org dashboard
  // if one item, this might be an issue that can be hidden under the rug.
  // as it might not be a problem if 2 organizations are present.
  // Leaving it for now.
});

export default router;
