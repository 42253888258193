import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { Navigation } from "@/interfaces/common/Navigation";
import type { NavigationByState } from "@/interfaces/common/NavigationByState";
import type { Pagination } from "@/interfaces/common/Pagination";
import type { Organization } from "@/interfaces/organization/Organization";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import type { OrganizationUserActivity } from "@/interfaces/organization/user/OrganizationUserActivity";

class OrganizationUserActivityApi extends OrganizationGenericEntityApi<OrganizationUserActivity> {
  apiIdentifier = "organization_user_activity";

  // @TODO adjust the path when we'll introduce the organization activity feature
  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/users`;
  }

  parseEntity(entityFromApi: any): OrganizationUserActivity {
    return new ApiResponseParser(entityFromApi, "organization_user_activity")
      .asOrganizationEntity()
      .build() as OrganizationUserActivity;
  }

  async getUserActivity(args: {
    organization: Organization;
    user: OrganizationUser;
    page?: number;
    limit?: number;
    params?: { [key: string]: any };
  }): Promise<{
    entities: OrganizationUserActivity[];
    pagination: Pagination;
    navigation: Navigation;
    navigationByState: NavigationByState;
  }> {
    const { organization, user, params, ...extraArgs } = args;
    const url =
      this.getBaseUrl({ organizationId: organization.id }) +
      `/${user.id}/activity`;

    return this.baseApiGetEntitiesPaginated({
      url: url,
      ...extraArgs,
      params: params,
      parseEntity: (entityFromApi) => {
        return this.parseEntity(entityFromApi);
      },
    });
  }
}

export function useOrganizationUserActivityApi() {
  return new OrganizationUserActivityApi();
}
