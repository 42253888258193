<script setup lang="ts">
import ProfilePictureTooltip from "@/components/elements/tooltip/ProfilePictureTooltip.vue";
import { useFrontendStore } from "@/stores/Frontend";
import { useUserStore } from "@/stores/user/User";
import { ref } from "vue";
import { useRouter } from "vue-router";
import IconLogout from "../icons/general/actions/IconLogout.vue";
import IconUser from "../icons/general/utils/IconUser.vue";

const userStore = useUserStore();
const frontend = useFrontendStore();
const router = useRouter();
const menuUserProfile = ref();

async function logout() {
  menuUserProfile.value.hideTooltip();
  router.push({ name: "logout" });
}
</script>

<template>
  <div class="profile-menu" v-if="userStore.isAuthenticated">
    <ProfilePictureTooltip ref="menuUserProfile">
      <ul class="menu-list">
        <li v-if="userStore.loggedUserInfo.email_verified_at != null">
          <RouterLink
            class="unstyled"
            :to="{ name: 'myProfile' }"
            @click="menuUserProfile.hideTooltip()"
          >
            <IconUser :width="18" :height="18" :stroke-width="3" />
            {{ frontend.trans("general.operation.my_profile") }}
          </RouterLink>
        </li>
        <li v-if="userStore.loggedUserInfo.email_verified_at != null">
          <RouterLink
            class="unstyled"
            :to="{ name: 'userApiTokens' }"
            @click="menuUserProfile.hideTooltip()"
          >
            <IconUser :width="18" :height="18" :stroke-width="3" />
            {{ frontend.trans("user_api_token.title.list") }}
          </RouterLink>
        </li>
        <li>
          <a href="#" class="unstyled" @click.prevent="logout()">
            <IconLogout :width="18" :height="18" :strokeWidth="2" />
            {{ frontend.trans("general.operation.logout") }}
          </a>
        </li>
      </ul>
    </ProfilePictureTooltip>
  </div>
</template>

<style scoped lang="scss">
.profile-menu {
  display: flex;
  align-items: center;
  gap: var( --enlivy-spacing-divider-sm);
}

.menu-list {
  display: flex;
  flex-direction: column;
  gap: var(--enlivy-spacing-xs);
  li {
    white-space: nowrap;
    a {
      display: flex;
      align-items: center;
      gap: var(--enlivy-spacing-lg);
    }
  }
}
</style>
