import { AdministrationGenericEntityApi } from "@/api/generic/AdministrationGenericEntityApi";
import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { AdministrationUserInvitationCode } from "@/interfaces/administration/user-invitation-codes/AdministrationUserInvitationCode";
import type { User } from "@/interfaces/user/User";
import axios from "axios";

class AdministrationUserInvitationCodeApi extends AdministrationGenericEntityApi<AdministrationUserInvitationCode> {
  apiIdentifier = "administration_user_invitation_code";

  getBaseUrl(): string {
    return `/invitation-codes`;
  }
  parseEntity(entityFromApi: any): AdministrationUserInvitationCode {
    return new ApiResponseParser(
      entityFromApi,
      "administration_user_invitation_code"
    )
      .asId("id")
      .asId("user_account_type_id")
      .asNumber("redeem_count")
      .asNumber("remaining_redeems")
      .asDate("created_at")
      .asDate("updated_at")
      .asDate("deleted_at")
      .asId("deleted_by_user_id")
      .build() as AdministrationUserInvitationCode;
  }

  async getReferralsByUser() {
    try {
      const response = await axios.get(`${this.getBaseUrl()}/referrals`, {
        headers: authHeader(),
      });

      return response.data as User[];
    } catch (error) {
      handleApiError(error);
    }
  }

  async getReferralsByInvitationCodeId(invitationCodeId: string) {
    try {
      const response = await axios.get(
        `${this.getBaseUrl()}/${invitationCodeId}/referrals`,
        {
          headers: authHeader(),
        }
      );

      return response.data as User[];
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useAdministrationUserInvitationCodeApi() {
  return new AdministrationUserInvitationCodeApi();
}
