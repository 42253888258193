import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { OrganizationFile } from "@/interfaces/organization/OrganizationFile";
import type { OrganizationFileUploadInfo } from "@/interfaces/organization/OrganizationFileUploadInfo";
import axios from "axios";

class OrganizationFileApi extends OrganizationGenericEntityApi<OrganizationFile> {
  apiIdentifier = "organization_file";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/files`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_file")
      .asOrganizationEntity()
      .asIds("tag_ids")
      .build() as OrganizationFile;
  }

  getDefaultInclude() {
    return ["tag_ids"];
  }

  async getUploadInfo(organizationId: string, extension: string) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({ organizationId })}`,
        { extension },
        { headers: authHeader() }
      );

      return response.data as OrganizationFileUploadInfo;
    } catch (error) {
      handleApiError(error);
    }
  }

  async uploadFile(fileUploadInfo: OrganizationFileUploadInfo, file: File) {
    await axios({
      method: fileUploadInfo.method,
      url: fileUploadInfo.url,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
    });
  }

  async uploadComplete(
    organizationId: string,
    params: {
      context: string;
      description: string;
      source_file_name: string;
      name: string;
    }
  ) {
    return (await this.baseApiPostEntity({
      url: `${this.getBaseUrl({ organizationId })}?_action=completed`,
      entity: params,
      parseEntity: (entityFromApi) => {
        entityFromApi.organization_id = organizationId;
        return this.parseEntity(entityFromApi) as OrganizationFile;
      },
    })) as OrganizationFile;
  }
}

export function useOrganizationFileApi() {
  return new OrganizationFileApi();
}
