import { useOrganizationInvoiceNotificationLogApi } from "@/api/organization/OrganizationInvoiceNotificationLogApi";
import type { OrganizationInvoiceNotificationLog } from "@/interfaces/organization/invoice/OrganizationInvoiceNotificationLog";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import type { EntityStorage } from "../../generic/storage/EntityStorage";
import { OrganizationSubEntityStorage } from "../../generic/storage/OrganizationSubEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";

export const useOrganizationInvoiceNotificationLogsStore = defineStore(
  "organizationInvoiceNotificationLogs",
  () => {
    const storage =
      new OrganizationSubEntityStorage<OrganizationInvoiceNotificationLog>(
        "invoiceId"
      );
    const pageCache =
      new OrganizationCache<OrganizationInvoiceNotificationLog>();

    const genericActions =
      new OrganizationGenericActions<OrganizationInvoiceNotificationLog>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationInvoiceNotificationLogApi(),
        enhanceEntity: enhanceEntity,
      });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationInvoiceNotificationLog,
  storage: EntityStorage<OrganizationInvoiceNotificationLog, any>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => {
    const notificationLog = storage.get(entityIdentifier);
    return notificationLog ? notificationLog.id : undefined;
  });
}
