<script setup lang="ts">
import { useFrontendStore } from "@/stores/Frontend";
import { useUserStore } from "@/stores/user/User";
import { ref } from "vue";
import LocaleSelector from "./LocaleSelector.vue";
import MobileNav from "./MobileNav.vue";
import ThemeSelector from "./ThemeSelector.vue";
import UserNav from "./UserNav.vue";
import UserProfile from "./UserProfile.vue";

defineProps<{
  sticky: boolean;
}>();

const userStore = useUserStore();
const frontend = useFrontendStore();

const mobileMenuVisible = ref(false);

function onMobileMenuToggled(menuVisible: boolean) {
  mobileMenuVisible.value = menuVisible;
}
</script>

<template>
  <header :class="{ 'open-menu': mobileMenuVisible, sticky: sticky }">
    <div>
      <RouterLink
        v-if="userStore.isAuthenticated"
        :to="{ name: 'accountOrganizationsDashboard' }"
      >
        <img v-if="mobileMenuVisible" class="logo" src="@/assets/logo.png" />
        <!-- <img v-else class="small-logo" src="@/assets/favicon.png" /> -->
      </RouterLink>
    </div>

    <div class="actions">
      <Suspense>
        <UserNav
          v-if="
          userStore.isAuthenticated &&
          frontend.windowWidth >= frontend.deviceBreakpoints.landscapeTablet
        "
        />
      </Suspense>

      <LocaleSelector v-if="!userStore.isAuthenticated" />

      <ThemeSelector v-show="!mobileMenuVisible" />

      <UserProfile
        v-if="userStore.isAuthenticated"
        v-show="!mobileMenuVisible"
      />

      <Suspense>
        <MobileNav
          v-if=" userStore.isAuthenticated &&
            frontend.windowWidth < frontend.deviceBreakpoints.landscapeTablet"
          @menuToggled="onMobileMenuToggled"
        />
      </Suspense>
    </div>
  </header>
  <div v-if="sticky" class="header-placeholder"></div>
</template>

<style scoped lang="scss">
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var( --enlivy-spacing-divider-sm);
  width: 100%;
  background: var(--enlivy-background-100-color);
  z-index: 10;

  &.open-menu {
    background-color: var(--enlivy-white-100-color);
    z-index: 15;
  }
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
  }
}

.header-placeholder {
  height: calc(35px + 2 * var( --enlivy-spacing-divider-sm));
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--enlivy-spacing-divider-md);
}

.logo {
  max-height: 24px;
}
.small-logo {
  max-height: 32px;
}

@media only screen and (min-width: $breakpoint-portrait-tablet) {
  header {
    padding: var( --enlivy-spacing-divider-sm) calc(var( --enlivy-spacing-divider-sm) * 2);
  }
}
@media only screen and (min-width: $breakpoint-desktop) {
  header {
    padding: var( --enlivy-spacing-divider-sm) 50px;
  }
}
</style>
