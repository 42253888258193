import { useAdministrationUserInvitationCodeApi } from "@/api/administration/AdministrationUserInvitationCodeApi";
import type { AdministrationUserInvitationCode } from "@/interfaces/administration/user-invitation-codes/AdministrationUserInvitationCode";
import type { User } from "@/interfaces/user/User";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { AdministrationEntityStorage } from "@/stores/generic/storage/AdministrationEntityStorage";
import { AdministrationGenericActions } from "@/stores/generic/store/AdministrationGenericActions";
import { defineStore } from "pinia";

export const useAdministrationUserInvitationCodesStore = defineStore(
  "administrationUserInvitationCode",
  () => {
    const storage =
      new AdministrationEntityStorage<AdministrationUserInvitationCode>();
    const referralStorage = new AdministrationEntityStorage<User>();
    const pageCache = new OrganizationCache<AdministrationUserInvitationCode>();
    const api = useAdministrationUserInvitationCodeApi();

    const genericActions =
      new AdministrationGenericActions<AdministrationUserInvitationCode>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
        enhanceEntity: enhanceEntity,
      });

    const getReferralsByUser = async () => {
      const users = (await api.getReferralsByUser()) as User[];

      // Store users
      for (const entity of users) {
        entity.stale = false;

        const entityId = getEntityIdentifier(entity);
        referralStorage.set(entityId, entity);
      }

      return users;
    };

    const getReferralsByInvitationCodeId = async (
      invitationCode: AdministrationUserInvitationCode
    ) => {
      const users = (await api.getReferralsByInvitationCodeId(
        invitationCode.id
      )) as User[];

      // Store users
      for (const entity of users) {
        entity.stale = false;

        const entityId = getEntityIdentifier(entity);
        referralStorage.set(entityId, entity);
      }

      return users;
    };

    return {
      ...genericActions,
      getReferralsByUser,
      getReferralsByInvitationCodeId,
    };
  }
);

export function enhanceEntity(
  entity: AdministrationUserInvitationCode,
  storage: AdministrationEntityStorage<AdministrationUserInvitationCode>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.code);
}
