import { GenericEntityApi } from "@/api/generic/GenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { UserApiToken } from "@/interfaces/user/UserApiToken";

class UserApiTokenApi extends GenericEntityApi<UserApiToken> {
  apiIdentifier = "user_api_token";

  getBaseUrl() {
    return `/users/me/api-tokens`;
  }

  prepareEntity() {}

  fillWithEntityIdentifierFields() {}

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "user_api_token")
      .asOrganizationEntity()
      .asIds("organizations")
      .asDate("last_used_at")
      .build() as UserApiToken;
  }
}

export function useUserApiTokenApi() {
  return new UserApiTokenApi();
}
