import { useOrganizationsStore } from "@/stores/organization/entity/Organizations";
import { useUserStore } from "@/stores/user/User";
import { useRouter, type RouteLocationNormalizedLoaded } from "vue-router";

export const redirectIfNoOrganization = async () => {
  if (useUserStore().isAuthenticated) {
    const router = useRouter();
    const organizations = await useOrganizationsStore().getAll();

    if (organizations.value.length == 0) {
      await router.push({ name: "createOrganization" });
    }
  }
};

export const redirectIfOneOrganization = async (
  to: RouteLocationNormalizedLoaded,
  from: RouteLocationNormalizedLoaded,
  next: Function
) => {
  if (useUserStore().isAuthenticated) {
    const organizations = await useOrganizationsStore().getAll();

    if (organizations.value.length == 1) {
      if (
        from.name == "organizationDashboard" &&
        from.params?.organizationId == organizations.value[0].id
      ) {
        // Robert decision :
        //   Going by the user behaviour, if he is on the current
        //   dashboard, and navigating to the Dashboard list, while having no organizations,
        //   he's looking for a forced refresh most likely.
        window.location.reload();
      }

      return next({
        name: "organizationDashboard",
        params: { organizationId: organizations.value[0].id },
      });
    }
  }
  next(); // Proceed to the route as usual if no redirection is needed
};

export const redirectIfAuth = async () => {
  if (useUserStore().isAuthenticated) {
    const router = useRouter();
    const organizations = await useOrganizationsStore().getAll();

    if (organizations.value.length >= 1) {
      await router.push({ name: "accountOrganizationsDashboard" });
    } else {
      await router.push({ name: "createOrganization" });
    }
  }
};

export const redirectIfNotAdmin = async () => {
  if (
    useUserStore().loggedUserInfo.capabilities.includes("administrator") !==
    true
  ) {
    redirectIfAuth();
  }
};

export const redirectIfMissingParams = async (
  route: RouteLocationNormalizedLoaded,
  args: {
    paramsToCheck: string[];
    redirectName: string;
    redirectParams: string[];
  }
) => {
  const missingParams = args.paramsToCheck.some(
    (param) => !route.params[param]
  );

  if (missingParams) {
    const redirectParams = args.redirectParams.reduce((params, key) => {
      params[key] = route.params[key];
      return params;
    }, {} as Record<string, any>);

    useRouter().push({ name: args.redirectName, params: redirectParams });
  }
};

export const redirectIfDoesNotOwnOrganization = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  organizationId: string | string[]
) => {
  // @TODO uncomment this code
  // if (Array.isArray(organizationId)) {
  //   await router.push({ name: "setupProcessOrganizationCreate" });
  // }
  // const userStore = useUserStore();
  // if (userStore.isAuthenticated) {
  //   const organization = userStore.getActiveOrganization();
  //   // Check if some active organization exists
  //   if (userStore.loggedUserInfo.id != organization.value?.user_id) {
  //     await router.push({ name: "setupProcessOrganizationCreate" });
  //   }
  //   // Check if the active organization is the actual value inserted in the URL
  //   if (organizationId != organization.value?.id) {
  //     await router.push({ name: "setupProcessOrganizationCreate" });
  //   }
  // }
};
