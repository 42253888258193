import { deepClone } from "@/lib/Object";
import { apiDateToJsObject } from "@/lib/common/DateUtils";

// Helper class to parse incoming data from the API.
export class ApiResponseParser {
  object: any;

  constructor(objectFromApi: any, entityName?: string) {
    this.object = deepClone(objectFromApi);
    if (this.object && entityName) {
      this.object._entity = entityName;
    }
  }

  build() {
    return this.object;
  }

  asOrganizationEntity() {
    this.asId("id")
      .asId("organization_id")
      .asIds("tag_ids")
      .asDate("created_at")
      .asDate("updated_at")
      .asDate("deleted_at");

    return this;
  }

  asId(property: string) {
    if (!this.object) return this;

    const value = this.object[property];
    if (value === undefined || value === 0 || value === "0") {
      delete this.object[property];
    } else if (value === null || value === "null") {
      this.object[property] = null;
    } else {
      this.object[property] = String(value);
    }

    return this;
  }

  asIds(property: string) {
    if (!this.object) return this;

    const value = this.object[property];

    if (value === null || value === "null") {
      this.object[property] = null;
    } else if (value === undefined || !Array.isArray(value)) {
      delete this.object[property];
    } else {
      value.forEach((id: any, index: number) => {
        value[index] = String(id);
      });
    }

    return this;
  }

  asNumber(property: string) {
    if (!this.object) return this;

    const value = this.object[property];
    if (value === undefined) {
      delete this.object[property];
    } else if (value === null || value === "null") {
      this.object[property] = null;
    } else {
      this.object[property] = parseFloat(value);
    }

    return this;
  }

  asDate(property: string) {
    if (!this.object) return this;

    const value = this.object[property];
    if (value === undefined) {
      delete this.object[property];
    } else if (value === null || value === "null") {
      this.object[property] = null;
    } else {
      this.object[property] = apiDateToJsObject(value);
    }

    return this;
  }

  asString(property: string) {
    if (!this.object) return this;

    const value = this.object[property];
    if (value === undefined || value === null) {
      delete this.object[property];
    } else {
      this.object[property] = value;
    }

    return this;
  }

  asBool(property: string) {
    if (!this.object) return this;

    const value = this.object[property];
    if (value === undefined) {
      delete this.object[property];
    } else {
      this.object[property] = value === true || value === 1 || value === "1";
    }

    return this;
  }

  asLangMap(property: string) {
    if (!this.object) return this;

    const value = this.object[property];
    // If it's an array it means the API found an empty object and returned it as Array.
    if (Array.isArray(value)) {
      delete this.object[property];
    }

    return this;
  }

  asObject(property: string) {
    if (!this.object) return this;

    const value = this.object[property];
    // If it's an array it means the API found an empty object and returned it as Array.
    if (Array.isArray(value)) {
      delete this.object[property];
    }

    return this;
  }

  asArray(property: string, innerProperty: boolean = false) {
    if (!this.object) return this;

    let objectPropertyReference = this.object;
    let lastKey = property;

    if (innerProperty) {
      // Move through the inner properties until the last one.
      const properties = property.split(".");

      // In case we are not passing the right property argument we should get the error
      lastKey = properties.pop() as string;
      for (const key of properties) {
        if (
          objectPropertyReference[key] === undefined ||
          objectPropertyReference[key] == null
        ) {
          delete objectPropertyReference[key];
          return this;
        }
        objectPropertyReference = objectPropertyReference[key];
      }
    }

    // Handle the property type
    if (
      objectPropertyReference[lastKey] === undefined ||
      objectPropertyReference[lastKey] === null
    ) {
      delete objectPropertyReference[lastKey];
      return this;
    }
    if (!Array.isArray(objectPropertyReference[lastKey])) {
      objectPropertyReference[lastKey] = Object.values(
        objectPropertyReference[lastKey]
      );
    }

    return this;
  }
}
