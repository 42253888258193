<script setup lang="ts">
import Button from "@/components/elements/buttons/Button.vue";
import { useAuthStore } from "@/stores/Auth";
import { closeDialog } from "vue3-promise-dialog";
import BaseDialog from "./BaseDialog.vue";

defineProps<{
  title: string;
  text?: string;
  error?: string;
}>();

const authStore = useAuthStore();
const goToLogin = () => {
  authStore.logoutBehaviour();
}
</script>

<template>
  <BaseDialog :title="title" @close="closeDialog(false)" width="500px">
    <p v-if="text" v-html="text"></p>
    <p v-if="error" class="error" v-html="error"></p>

    <template #actions>
      <Button @clicked="goToLogin"> To Login </Button>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
p {
  font-size: 16px;
  color: var(--enlivy-grey-100-color);

  &.error {
    color: var( --enlivy-red-100-color );
  }
}
</style>
