import type { GenericEntityApi } from "@/api/generic/GenericEntityApi";
import type { Entity } from "@/interfaces/generic/Entity";
import type { PaginatedCacheValue } from "@/interfaces/generic/PaginatedCacheValue";
import type { GenericCache } from "../cache/GenericCache";
import type { EntityStorage } from "../storage/EntityStorage";
import { GenericActions } from "./GenericActions";

// @TODO find a better name for this as it will be used for all
// non-organization entities, not only for the administration features
export class AdministrationGenericActions<
  T extends Entity
> extends GenericActions<T> {
  constructor(args: {
    storage: EntityStorage<T, any>;
    pageCache: GenericCache<PaginatedCacheValue<T>>;
    entityApi: GenericEntityApi<T>;
    ignoredKeysForDeletion?: string[];
    enhanceEntity?: (entity: T, storage: EntityStorage<T, any>) => void;
    initializationCallback?: (...args: any[]) => void;
    preStore?: (entity: T) => void;
    prePersist?: (entity: T, existingEntity?: T) => void;
  }) {
    super(args);
  }

  getByIds = async () => {
    throw new Error("getByIds not implemented");
  };
}
