import type { Entity } from "@/interfaces/generic/Entity";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { AdministrationEntityStorageData } from "@/interfaces/generic/storage/AdministrationEntityStorageData";
import { updateObject } from "@/lib/Object";
import { computed } from "vue";
import { EntityStorage } from "./EntityStorage";

// @TODO find a better name for this as it will be used for all
// non-organization entities, not only for the administration features
export class AdministrationEntityStorage<
  T extends Entity
> extends EntityStorage<T, AdministrationEntityStorageData<T>> {
  constructor() {
    super({} as AdministrationEntityStorageData<T>);
  }

  get(entityIdentifier: EntityIdentifier): T | undefined {
    if (entityIdentifier.id == undefined) {
      return undefined;
    }

    return this.storage.value?.[entityIdentifier.id];
  }

  getComputed(entityIdentifier: EntityIdentifier) {
    return computed(() => this.get(entityIdentifier));
  }

  set(
    entityIdentifier: EntityIdentifier,
    entity: T,
    initializationCallback?: (...args: any[]) => void
  ) {
    if (entityIdentifier.id == undefined) {
      // TODO: Throw error, set should not be called without all the identifiers present.
      return entity;
    }

    // If the entity is already in the store, update it.
    if (this.isInStore(entityIdentifier)) {
      const existingEntity = this.get(entityIdentifier);
      if (existingEntity != undefined) {
        updateObject(existingEntity, entity);
        return existingEntity;
      }
    } else if (!entity.deleted_at) {
      initializationCallback?.(entityIdentifier.id);
    }

    this.storage.value[entityIdentifier.id] = entity;

    return entity;
  }

  remove(entityIdentifier: EntityIdentifier): T | undefined {
    if (entityIdentifier.id == undefined) {
      return undefined;
    }

    const entity = this.get(entityIdentifier);
    delete this.storage.value?.[entityIdentifier.id];

    return entity;
  }
}
