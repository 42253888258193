import { useOrganizationBillingScheduleApi } from "@/api/organization/OrganizationBillingScheduleApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationContract } from "@/interfaces/organization/contract/OrganizationContract";
import type { OrganizationBillingSchedule } from "@/interfaces/organization/billing-schedule/OrganizationBillingSchedule";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
} from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { useGenericPusherUtils } from "../../generic/GenericPusherUtils";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";
import { useOrganizationContractsStore } from "./OrganizationContracts";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationBillingScheduleStore = defineStore(
  "organizationBillingSchedules",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationBillingSchedule>();
    const pageCache = new OrganizationCache<OrganizationBillingSchedule>();
    const api = useOrganizationBillingScheduleApi();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationBillingSchedule>(
        "organization_billing_schedule",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationBillingSchedule>({
      storage: storage,
      pageCache: pageCache,
      entityApi: api,
      enhanceEntity: enhanceEntity,
      initializationCallback: subscribeToSocket,
    });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationBillingSchedule,
  storage: OrganizationEntityStorage<OrganizationBillingSchedule>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const organizationUsersStore = useOrganizationUsersStore();
  const organizationContractsStore = useOrganizationContractsStore();

  addComputedField<OrganizationBillingSchedule, OrganizationContract>(
    entity,
    "contract",
    () => storage.get(entityIdentifier)?.organization_contract_id,
    async () =>
      organizationContractsStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_contract_id,
      })
  );
  addComputedField<OrganizationBillingSchedule, OrganizationUser>(
    entity,
    "sender",
    () => storage.get(entityIdentifier)?.organization_sender_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_sender_user_id,
      })
  );
  addComputedField<OrganizationBillingSchedule, OrganizationUser>(
    entity,
    "receiver",
    () => storage.get(entityIdentifier)?.organization_receiver_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_receiver_user_id,
      })
  );
}
