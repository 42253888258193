import { useOrganizationFileApi } from "@/api/organization/OrganizationFileApi";
import type { OrganizationFile } from "@/interfaces/organization/OrganizationFile";
import type { OrganizationFileUploadInfo } from "@/interfaces/organization/OrganizationFileUploadInfo";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";

export const useOrganizationFilesStore = defineStore(
  "organizationFiles",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationFile>();
    const pageCache = new OrganizationCache<OrganizationFile>();

    const api = useOrganizationFileApi();

    const genericActions = new OrganizationGenericActions<OrganizationFile>({
      storage: storage,
      pageCache: pageCache,
      entityApi: api,
      enhanceEntity: enhanceEntity,
    });

    const uploadComplete = async (
      organizationId: string,
      params: {
        context: string;
        description: string;
        source_file_name: string;
        name: string;
      }
    ) => {
      const createdEntity = await api.uploadComplete(organizationId, params);
      const storedEntities = genericActions.storeEntities([createdEntity]);

      return storedEntities[0];
    };

    const upload = async (
      organizationId: string,
      file: File,
      fileName?: string,
      context = "upload",
      description?: string
    ): Promise<OrganizationFile | undefined> => {
      const extension = file?.name.split(".").pop();
      if (!file || !extension || !organizationId) {
        return undefined;
      }

      // Step 1: Init upload. Get pre-signed url.
      const fileUploadInfo = (await api.getUploadInfo(
        organizationId,
        extension
      )) as OrganizationFileUploadInfo;

      // Step 2: Upload file.
      await api.uploadFile(fileUploadInfo, file);

      // Step 3: Signal upload completed & receive the new file entity's data.
      return await uploadComplete(organizationId, {
        name: fileName ? fileName : file.name,
        context,
        description: description ? description : context,
        source_file_name: fileUploadInfo.file_name,
      });
    };

    // Re-map the create function to use the upload function.
    const create = async (entity: OrganizationFile, file: File) => {
      return await upload(
        entity.organization_id,
        file,
        entity.name,
        entity.context,
        entity.description
      );
    };

    return {
      ...genericActions,
      create,
      upload,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationFile,
  storage: OrganizationEntityStorage<OrganizationFile>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.name);
}
