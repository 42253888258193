import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type {
  OrganizationBillingScheduledPayment
} from "@/interfaces/organization/billing-schedule/OrganizationBillingScheduledPayment";

class OrganizationBillingScheduledPaymentApi extends OrganizationGenericEntityApi<OrganizationBillingScheduledPayment> {
  apiIdentifier = "organization_billing_schedule_payment";

  getBaseUrl(args: { organizationId: string }) {
    // todo: this is not yet implemented
    return `/organizations/${args.organizationId}/billing-scheduled-payments`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_billing_schedule_payment")
      .asOrganizationEntity()
      .asId("id")
      .asId("organization_billing_schedule_id")
      .asId("organization_contract_id")
      .asNumber("sub_total")
      .asNumber("total")
      .asNumber("tax_total")
      .asDate("starts_at")
      .asDate("issued_at")
      .asDate("due_at")
      .asDate("paid_at")
      .build() as OrganizationBillingScheduledPayment;
  }
}

export function useOrganizationBillingScheduledPaymentApi() {
  return new OrganizationBillingScheduledPaymentApi();
}
