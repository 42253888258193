<script setup lang="ts">
import Table from "@/components/elements/Table.vue";
import Button from "@/components/elements/buttons/Button.vue";
import Help from "@/components/elements/tooltip/Help.vue";
import Header from "@/components/header/Header.vue";
import AnimationServerError from "@/components/illustrations/AnimationServerError.vue";
import { useFrontendStore } from "@/stores/Frontend";
import { useUserStore } from "@/stores/user/User";
import { useRouter } from "vue-router";

const router = useRouter();
const userStore = useUserStore();
const frontend = useFrontendStore();

const activeOrganization = userStore.getActiveOrganization();
const url = window.location.href;
const match = url.match(/\/organizations\/([^/]+)(?:\/|$)/);
const organizationFromUrl = match ? match[1] : null;

const handleRefresh = () => {
  window.location.reload();
};

const handleLogout = () => {
  router.push({ name: "logout" });
}
</script>

<template>
  <Header :sticky="true" />
  <main>
    <AnimationServerError />

    <h1>{{ frontend.trans("general.title.page_error") }}</h1>

    <div class="reason-container">
      <h2>{{ frontend.trans("general.notification.connection_failed") }}</h2>
      <Help>
        <p>
          {{ frontend.trans("general.notification.connection_failed_instructions") }}
        </p>
      </Help>
    </div>

    <p>{{ frontend.trans("general.notification.page_error") }}</p>

    <div class="actions">
      <Button @clicked="handleRefresh">
        {{ frontend.trans('general.operation.refresh') }}
      </Button>
      <Button class="secondary" @clicked="handleLogout">
        {{ frontend.trans('general.operation.logout') }}
      </Button>
    </div>

    <div class="table-wrapper">
      <Table>
        <template #rows>
          <tr>
            <td>user_id</td>
            <td>{{ userStore?.loggedUserInfo?.id || "-" }}</td>
          </tr>
          <tr>
            <td>organization_id</td>
            <td>{{ activeOrganization?.id || organizationFromUrl ||"-" }}</td>
          </tr>
          <tr>
            <td>url_path</td>
            <td>{{ url }}</td>
          </tr>
        </template>
      </Table>
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var( --enlivy-spacing-divider-sm);
  max-width : 600px;
  margin : var( --enlivy-spacing-divider-sm ) auto 0 auto;

  .reason-container {
    display: flex;
    flex-direction: row;
    gap: var(--enlivy-spacing-xs);
  }

  h1 {
    @include font-title();
    color: var(--enlivy-grey-75-color);
    text-align : center;
  }

  .actions {
    display: flex;
    gap: var(--enlivy-spacing-md);
    margin-top: var(--enlivy-spacing-lg);
  }
}
</style>
