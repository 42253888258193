import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationBankAccount } from "@/interfaces/organization/bank/OrganizationBankAccount";

class OrganizationBankAccountApi extends OrganizationGenericEntityApi<OrganizationBankAccount> {
  apiIdentifier = "organization_bank_account";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/bank-accounts`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_bank_account")
      .asOrganizationEntity()
      .asNumber("balance")
      .asId("organization_bank_account_data_bridge_id")
      .asDate("organization_bank_account_data_bridge_last_synced_at")
      .asDate("organization_bank_account_data_bridge_sync_from")
      .asDate("organization_bank_account_data_bridge_next_sync_at")
      .build() as OrganizationBankAccount;
  }

  getDefaultInclude() {
    return ["tag_ids"];
  }
}

export function useOrganizationBankAccountApi() {
  return new OrganizationBankAccountApi();
}
