import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { Navigation } from "@/interfaces/common/Navigation";
import type { NavigationByState } from "@/interfaces/common/NavigationByState";
import type { Pagination } from "@/interfaces/common/Pagination";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationInvoiceNotificationLog } from "@/interfaces/organization/invoice/OrganizationInvoiceNotificationLog";

class OrganizationInvoiceNotificationLogApi extends OrganizationGenericEntityApi<OrganizationInvoiceNotificationLog> {
  apiIdentifier = "organization_invoice_notification_log";

  getBaseUrl(args: { organizationId: string; invoiceId: string }) {
    return `/organizations/${args.organizationId}/invoice-notification-logs`;
  }

  async get(args: {
    entityIdentifier: EntityIdentifier;
    page?: number;
    limit?: number;
    deleted?: number;
    params?: { [key: string]: any };
  }): Promise<{
    entities: OrganizationInvoiceNotificationLog[];
    pagination: Pagination;
    navigation: Navigation;
    navigationByState: NavigationByState;
  }> {
    return super.get({
      ...args,
      params: {
        ...args.params,
        organization_invoice_id: args.entityIdentifier.invoiceId,
      },
    });
  }

  parseEntity(entityFromApi: any) {
    const entity = new ApiResponseParser(
      entityFromApi,
      "organization_invoice_notification_log"
    )
      .asOrganizationEntity()
      .asId("organization_invoice_id")
      .build() as OrganizationInvoiceNotificationLog;

    return entity;
  }

  fillWithEntityIdentifierFields(
    entityFromApi: any,
    entityIdentifier: EntityIdentifier
  ) {
    super.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);

    if (entityIdentifier.invoiceId != undefined) {
      entityFromApi.organization_invoice_id = entityIdentifier.invoiceId;
    }
  }
}

export function useOrganizationInvoiceNotificationLogApi() {
  return new OrganizationInvoiceNotificationLogApi();
}
