import type { Modifier } from "@popperjs/core";

export function offsetModifier(
  offset: [number, number]
): Partial<Modifier<any, any>> {
  return {
    name: "offset",
    options: { offset },
  };
}

export function sameWidthModifier(): Partial<Modifier<any, any>> {
  return {
    name: "sameWidth",
    enabled: true,
    phase: "beforeWrite",
    requires: ["computeStyles"],
    fn({ state }) {
      state.styles.popper.minWidth = `${state.rects.reference.width}px`;
    },
    effect({ state }) {
      state.elements.popper.style.minWidth = `${
        (state.elements.reference as any).offsetWidth
      }px`;
    },
  };
}

export function preventOverflowModifier(): Partial<Modifier<any, any>> {
  return {
    name: "preventOverflow",
  };
}
