import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationPayslipSchema } from "@/interfaces/organization/payslips/OrganizationPayslipSchema";

class OrganizationPayslipSchemaApi extends OrganizationGenericEntityApi<OrganizationPayslipSchema> {
  apiIdentifier = "organization_payslip_schema";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/payslip-schemas`;
  }

  parseEntity(entityFromApi: any) {
    const entityParsed = new ApiResponseParser(
      entityFromApi,
      "organization_payslip_schema"
    )
      .asOrganizationEntity()
      .asId("deleted_by_user_id")
      .build() as OrganizationPayslipSchema;

    for (const key of Object.keys(entityParsed.field_map)) {
      const field = entityParsed.field_map[key];
      // @ts-ignore
      field.is_required = field.is_required === "1" ? true : false;
    }

    return entityParsed;
  }

  getDefaultInclude(): string[] {
    return [];
  }
}

export function useOrganizationPayslipSchemaApi() {
  return new OrganizationPayslipSchemaApi();
}
