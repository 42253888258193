import type { GenericEntityApi } from "@/api/generic/GenericEntityApi";
import type { Entity } from "@/interfaces/generic/Entity";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { PaginatedCacheValue } from "@/interfaces/generic/PaginatedCacheValue";
import { deepClone, isEmpty, isObject } from "@/lib/Object";
import {
  markPropertiesForDeletion,
  removeNullProperties,
  removeUnmodifiedProperties,
} from "@/lib/common/ObjectPropertiesUtils";
import {
  getEntityIdentifier,
  getEntityIdentifierSerialized,
} from "@/lib/generic/EntityIdentifierUtils";
import { useUserStore } from "@/stores/user/User";
import type { ComputedRef } from "vue";
import type { GenericCache } from "../cache/GenericCache";
import type { EntityStorage } from "../storage/EntityStorage";
export abstract class GenericActions<T extends Entity> {
  storage: EntityStorage<T, any>;
  pageCache: GenericCache<PaginatedCacheValue<T>>;
  protected entityApi: GenericEntityApi<T>;
  protected mandatoryKeys: string[] = ["_entity", "id"];
  protected ignoredKeysForDeletion: string[] = [];

  protected enhanceEntity?: (entity: T, storage: EntityStorage<T, any>) => void;
  protected initializationCallback?: (...args: any[]) => void;
  protected preStore?: (entity: T) => void;
  protected prePersist?: (entity: T, existingEntity?: T) => void;

  private delayTimer: any;
  private idsQueue = {} as { [key: string]: string[] };
  private groupPromises = {} as { [key: string]: Function[] };
  private userStore = useUserStore();

  constructor(args: {
    storage: EntityStorage<T, any>;
    pageCache: GenericCache<PaginatedCacheValue<T>>;
    entityApi: GenericEntityApi<T>;
    enhanceEntity?: (entity: T, storage: EntityStorage<T, any>) => void;
    initializationCallback?: (...args: any[]) => void;
    preStore?: (entity: T) => void;
    prePersist?: (entity: T, existingEntity?: T) => void;
  }) {
    this.storage = args.storage;
    this.pageCache = args.pageCache;
    this.entityApi = args.entityApi;

    this.enhanceEntity = args.enhanceEntity;
    this.initializationCallback = args.initializationCallback;
    this.preStore = args.preStore;
    this.prePersist = args.prePersist;
  }

  abstract getByIds: (
    ids: string[],
    groupIdentifier?: EntityIdentifier
  ) => Promise<ComputedRef<T[]>>;

  getEntityRef = (entityIdentifier: EntityIdentifier) => {
    return this.storage.getComputed(entityIdentifier);
  };

  getEntityRefs = (entities: T[]) => {
    const result: ComputedRef<T | undefined>[] = [];
    for (const entity of entities) {
      result.push(this.getEntityRef(getEntityIdentifier(entity)));
    }

    return result;
  };

  prepareEntityUpdate = (entity: T) => {
    const existingEntity = this.storage.get(getEntityIdentifier(entity));
    if (existingEntity == undefined) {
      return;
    }

    // @TODO we need to add a function that will remove the object properties
    // that had not been updated and leave only what is changed. As it is now,
    // the full subobject (entity.someObject) will be sent to the backend.

    markPropertiesForDeletion({
      newObject: entity,
      oldObject: existingEntity,
    });

    removeUnmodifiedProperties({
      newObject: entity,
      oldObject: existingEntity,
      ignoredKeys: this.mandatoryKeys.concat(this.ignoredKeysForDeletion),
    });
  };

  computeProperties = (entity: T) => {
    // Populate title_lang_map if title is set title
    if (
      "title_lang_map" in entity &&
      (entity.title_lang_map === null || entity.title_lang_map === undefined)
    ) {
      if (
        "title" in entity &&
        entity.title !== null &&
        entity.title !== undefined
      ) {
        entity.title_lang_map = {
          [this.userStore.organization.locale]: entity.title,
        };
      }
    }
  };

  storeEntities = (entities: T[]) => {
    const result = [] as T[];

    for (const entity of entities) {
      if (this.preStore) {
        this.preStore(entity);
      }

      entity.stale = false;

      const entityId = getEntityIdentifier(entity);
      const storedEntity = this.storage.set(
        entityId,
        entity,
        this.initializationCallback
      );

      if (this.enhanceEntity) {
        this.enhanceEntity(storedEntity, this.storage);
      }

      // Compute certain properties from other entity properties
      this.computeProperties(storedEntity);

      result.push(storedEntity);
    }

    return result;
  };

  getPage = async (args: {
    entityIdentifier: EntityIdentifier;
    useCache: boolean;
    page?: number;
    limit?: number;
    deleted?: number;
    params?: { [key: string]: any };
  }) => {
    const { entityIdentifier: constEntityIdentifier, ...extraArgs } = args;
    let entityIdentifier = constEntityIdentifier;

    if (!isObject(entityIdentifier)) {
      entityIdentifier = {};
    }

    const queryParams = { entityIdentifier, ...extraArgs };
    const key = `${JSON.stringify(queryParams)}`;
    const cachedResponse = this.pageCache.get(key);
    if (args.useCache && cachedResponse) {
      return cachedResponse;
    }

    const response = await this.entityApi.get(queryParams);
    const storedEntities = this.storeEntities(response.entities);

    return this.pageCache.set(key, {
      entities: this.getEntityRefs(storedEntities),
      pagination: response.pagination,
      navigation: response.navigation,
      navigationByState: response.navigationByState,
    });
  };

  protected queueIds = async (
    groupIdentifier: EntityIdentifier,
    ids: string[],
    criticalFetch?: boolean
  ) => {
    clearTimeout(this.delayTimer);

    const groupIdentifierHash = getEntityIdentifierSerialized(groupIdentifier);
    if (!(groupIdentifierHash in this.idsQueue)) {
      this.idsQueue[groupIdentifierHash] = [];
      this.groupPromises[groupIdentifierHash] = [];
    }

    const idsToRetrieve = this.idsQueue[groupIdentifierHash];
    for (const entityId of ids) {
      if (!idsToRetrieve.includes(entityId)) {
        idsToRetrieve.push(entityId);
      }
    }

    const promise = new Promise<void>((resolve, reject) => {
      this.groupPromises[groupIdentifierHash].push(resolve);

      this.delayTimer = setTimeout(async () => {
        // TODO: this function can be extracted from here

        // In some cases other methods might have already retrieved the entities.
        // In this case we don't need to retrieve them again.
        let index = idsToRetrieve.length;
        while (index--) {
          const id = idsToRetrieve[index];
          const entityIdentifier = { ...groupIdentifier, id };
          if (
            this.storage.isInStore(entityIdentifier) &&
            !this.storage.get(entityIdentifier)?.stale
          ) {
            idsToRetrieve.splice(index, 1);
          }
        }

        if (!isEmpty(idsToRetrieve)) {
          try {
            if (idsToRetrieve.length == 1) {
              const response = await this.entityApi.getById(
                { ...groupIdentifier, id: idsToRetrieve[0] },
                undefined,
                criticalFetch
              );
              this.storeEntities([response]);
            } else {
              const response = await this.entityApi.getByIds(
                deepClone(idsToRetrieve),
                groupIdentifier
              );
              this.storeEntities(response);
            }
          } catch (error) {
            reject(error);
          } finally {
            idsToRetrieve.length = 0;
            for (const requestResolve of this.groupPromises[
              groupIdentifierHash
            ]) {
              requestResolve();
            }
            this.groupPromises[groupIdentifierHash].length = 0;
          }
        } else {
          for (const requestResolve of this.groupPromises[
            groupIdentifierHash
          ]) {
            requestResolve();
          }

          this.groupPromises[groupIdentifierHash].length = 0;
        }
      }, 100);
    });

    return promise;
  };

  isInStore = (id: EntityIdentifier) => this.storage.isInStore(id);

  /*
   * This method will return the EntityRef, and load the entity in the background,
   * useful for displaying information in an async manner
   * Example use case : displaying user information, in list views, of invoices / receipts, etc.
   *
   * In other functions, that leverage this, for example :
   *   - app\src\stores\organization\OrganizationUserRoles.ts
   *   -> a second param is available, if it should include abitilities.
   *
   * ( Robert approved comments )
   */
  lazyGetById = (
    entityIdentifier: EntityIdentifier,
    criticalFetch?: boolean
  ) => {
    if (!entityIdentifier.organizationId) {
      const activeOrganization = useUserStore().getActiveOrganization();
      if (activeOrganization.value) {
        entityIdentifier.organizationId = activeOrganization.value.id;
      }
    }

    const { id, ...groupIdentifier } = entityIdentifier;

    if (id && id != "0") {
      this.queueIds(groupIdentifier, [id], criticalFetch);
    }

    return this.getEntityRef(entityIdentifier);
  };

  getById = async (
    entityIdentifier: EntityIdentifier,
    criticalFetch?: boolean
  ) => {
    if (!entityIdentifier.organizationId) {
      const activeOrganization = useUserStore().getActiveOrganization();
      if (activeOrganization.value) {
        entityIdentifier.organizationId = activeOrganization.value.id;
      }
    }

    const { id, ...groupIdentifier } = entityIdentifier;

    if (id && id != "0") {
      await this.queueIds(groupIdentifier, [id], criticalFetch);
    }

    return this.getEntityRef(entityIdentifier);
  };

  create = async (entity: T) => {
    entity = deepClone(entity);

    removeNullProperties(entity);
    if (this.prePersist) {
      this.prePersist(entity);
    }

    const createdEntity = await this.entityApi.post(entity);
    const storedEntities = this.storeEntities([createdEntity]);

    this.pageCache.clearCache(getEntityIdentifier(storedEntities[0]));
    return storedEntities[0];
  };

  update = async (originalEntity: T) => {
    const entity = deepClone(originalEntity);
    const entityIdentifier = getEntityIdentifier(entity);

    if (this.prePersist) {
      this.prePersist(entity, this.storage.get(entityIdentifier));
    }

    this.prepareEntityUpdate(entity);

    // If there is at least one field besides the mandatoryKeys, the request is made.
    if (Object.keys(entity).length <= this.mandatoryKeys.length) {
      return originalEntity;
    }

    // Check if there are object properties inside the entity.
    // If so, include the whole object in the request.
    for (const key of Object.keys(entity)) {
      if (isObject(entity[key])) {
        entity[key] = (originalEntity as any)[key];
      }
    }

    const updatedEntity = await this.entityApi.put(entity);
    const storedEntities = this.storeEntities([updatedEntity]);

    this.pageCache.clearCache(getEntityIdentifier(storedEntities[0]));
    return storedEntities[0];
  };

  patch = async (originalEntity: T) => {
    const entity = deepClone(originalEntity);
    const entityIdentifier = getEntityIdentifier(entity);

    if (this.prePersist) {
      this.prePersist(entity, this.storage.get(entityIdentifier));
    }

    this.prepareEntityUpdate(entity);

    // If there is at least one field besides the mandatoryKeys, the request is made.
    if (Object.keys(entity).length <= this.mandatoryKeys.length) {
      return originalEntity;
    }

    // Check if there are object properties inside the entity.
    // If so, include the whole object in the request.
    for (const key of Object.keys(entity)) {
      if (isObject(entity[key])) {
        entity[key] = (originalEntity as any)[key];
      }
    }

    const updatedEntity = await this.entityApi.patch(entity);
    const storedEntities = this.storeEntities([updatedEntity]);

    this.pageCache.clearCache(getEntityIdentifier(storedEntities[0]));
    return storedEntities[0];
  };

  delete = async (entity: T, returnApiResponse = false) => {
    const apiResponse = await this.entityApi.delete(entity);

    const entityIdentifier = getEntityIdentifier(entity);
    this.storage.remove(entityIdentifier);
    this.pageCache.clearCache(entityIdentifier);

    if (returnApiResponse) {
      return apiResponse;
    }

    return entity;
  };

  restore = async (entity: T) => {
    let restoredEntity = await this.entityApi.restore(entity);

    if (restoredEntity) {
      this.storeEntities([restoredEntity]);
    } else {
      restoredEntity = this.storage.get(getEntityIdentifier(entity));
      delete restoredEntity.deleted_at;
    }

    this.pageCache.clearCache(getEntityIdentifier(restoredEntity));
    return restoredEntity;
  };

  addTag = async (entity: T, tag: string) => {
    const updatedEntity = await this.entityApi.addTag(entity, tag);
    const storedEntities = this.storeEntities([updatedEntity]);

    const entityIdentifier = getEntityIdentifier(storedEntities[0]);
    this.pageCache.clearCache(entityIdentifier);
    return this.getEntityRef(entityIdentifier);
  };

  removeTag = async (entity: T, tag: string) => {
    const updatedEntity = await this.entityApi.removeTag(entity, tag);
    const storedEntities = this.storeEntities([updatedEntity]);

    const entityIdentifier = getEntityIdentifier(storedEntities[0]);
    this.pageCache.clearCache(entityIdentifier);
    return this.getEntityRef(entityIdentifier);
  };
}
