<script setup lang="ts">
import Button from "@/components/elements/buttons/Button.vue";
import type { Entity } from "@/interfaces/generic/Entity";
import { useFrontendStore } from "@/stores/Frontend";
import { closeDialog } from "vue3-promise-dialog";
import BaseDialog from "./BaseDialog.vue";

const props = defineProps<{
  entity: Entity | Entity[];
}>();

const frontend = useFrontendStore();

const title = frontend.trans(
  "general.title.delete_prompt",
  true,
  frontend.trans(
    `${
      Array.isArray(props.entity)
        ? props.entity[0]._entity
        : props.entity._entity
    }.title.entity`
  )
)!;
</script>

<template>
  <BaseDialog :title="title" @close="closeDialog(false)" width="500px">
    <p>
      <template v-if="Array.isArray(entity)">
        {{
          frontend.trans(
            "general.content.delete_prompt", 
            true,
            `${frontend.trans(`${entity[0]._entity}.title.entity`)}`
          )
        }}
        <ul class="entities-list">
          <li v-for="item in entity" v-bind:key="item.id">{{ item._name }}</li>
        </ul>
      </template>
      <template v-else>
        {{ entity._fullName }}
      </template>
    </p>

    <template #actions>
      <Button @clicked="closeDialog(true)">
        {{ frontend.trans("general.operation.confirm") }}
      </Button>
      <Button class="secondary" @clicked="closeDialog(false)">
        {{ frontend.trans("general.operation.cancel") }}
      </Button>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
p {
  font-size: 16px;
  color: var(--enlivy-grey-100-color);
}

.entities-list {
  @include font-default();
  list-style-position: inside;
  list-style-type: disc;
}
</style>
