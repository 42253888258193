import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationProduct } from "@/interfaces/organization/OrganizationProduct";

class OrganizationProductApi extends OrganizationGenericEntityApi<OrganizationProduct> {
  apiIdentifier = "organization_product";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/products`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_product")
      .asOrganizationEntity()
      .asId("organization_tax_class_id")
      .asBool("price_is_tax_inclusive")
      .asIds("tag_ids")
      .build() as OrganizationProduct;
  }

  getDefaultInclude() {
    return ["tag_ids"];
  }
}

export function useOrganizationProductApi() {
  return new OrganizationProductApi();
}
