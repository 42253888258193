<script setup lang="ts">
import Gravatar from "@/components/elements/Gravatar.vue";
import { createTooltip } from "@/composables/Tooltip";
import { useUserStore } from "@/stores/user/User";
import { ref, useSlots, watch } from "vue";
import { useRoute } from "vue-router";

defineExpose({ hideTooltip });
interface Props {
  offset?: number;
}

const props = withDefaults(defineProps<Props>(), {
  offset: 0,
});

const userStore = useUserStore();
const route = useRoute();
watch(route, hideTooltip);

const profileElem = ref();
const slots = useSlots();
const tooltip = createTooltip(slots.default);

function displayTooltip() {
  if (tooltip) {
    tooltip.type = "fixed";
    tooltip.attachRect = profileElem.value.getBoundingClientRect();
    tooltip.orientation = "bottom-left";
    tooltip.visible = true;
    tooltip.offset = props.offset;
  }
}

function hideTooltip() {
  if (tooltip) {
    tooltip.visible = false;
  }
}

function toggleTooltip() {
  if (tooltip) {
    if (tooltip.visible) {
      hideTooltip();
    } else {
      displayTooltip();
    }
  }
}
</script>

<template>
  <a
    class="profile-image"
    href="#"
    ref="profileElem"
    @click.prevent="toggleTooltip"
    v-click-outside="hideTooltip"
  >
    <Gravatar
      class="profile-picture"
      :email="userStore.loggedUserInfo.email"
      defaultImg="@/assets/favicon.png"
    />
  </a>
</template>

<style scoped lang="scss">
.profile-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .profile-picture {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
