import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import Echo from "laravel-echo";

export function setupEcho() {
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true,
    auth: {
      headers: authHeader(),
    },
    authEndpoint: import.meta.env.VITE_PUSHER_AUTH_ENDPOINT,
  });
}
