import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationReport } from "@/interfaces/organization/report/OrganizationReport";

class OrganizationReportApi extends OrganizationGenericEntityApi<OrganizationReport> {
  apiIdentifier = "organization_report";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/reports`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_report")
      .asOrganizationEntity()
      .asId("organization_user_id")
      .asId("organization_report_schema_id")
      .build() as OrganizationReport;
  }
}

export function useOrganizationReportApi() {
  return new OrganizationReportApi();
}
