import type { Entity } from "@/interfaces/generic/Entity";
import { GenericEntityApi } from "./GenericEntityApi";

// @TODO find a better name for this as it will be used for all
// non-organization entities, not only for the administration features
export abstract class AdministrationGenericEntityApi<
  T extends Entity
> extends GenericEntityApi<T> {
  prepareEntity(entity: any) {
    delete entity._entity;
  }

  fillWithEntityIdentifierFields() {
    return;
  }
}
