import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationTask } from "@/interfaces/organization/task/OrganizationTask";

class OrganizationTaskApi extends OrganizationGenericEntityApi<OrganizationTask> {
  apiIdentifier = "organization_task";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/tasks`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_task")
      .asOrganizationEntity()
      .asId("parent_organization_task_id")
      .asId("assigned_by_organization_user_id")
      .asId("assigned_to_organization_user_id")
      .asId("completed_by_organization_user_id")
      .asDate("due_at")
      .asDate("completed_at")
      .build() as OrganizationTask;
  }
}

export function useOrganizationTaskApi() {
  return new OrganizationTaskApi();
}
