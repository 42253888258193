import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationInvoicePrefix } from "@/interfaces/organization/invoice/OrganizationInvoicePrefix";

class OrganizationInvoicePrefixApi extends OrganizationGenericEntityApi<OrganizationInvoicePrefix> {
  apiIdentifier = "organization_invoice_prefix";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/invoice-prefixes`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_invoice_prefix")
      .asOrganizationEntity()
      .build() as OrganizationInvoicePrefix;
  }
}

export function useOrganizationInvoicePrefixApi() {
  return new OrganizationInvoicePrefixApi();
}
