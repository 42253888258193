import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationBankTransaction } from "@/interfaces/organization/bank/OrganizationBankTransaction";

class OrganizationBankTransactionApi extends OrganizationGenericEntityApi<OrganizationBankTransaction> {
  apiIdentifier = "organization_bank_transaction";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/bank-transactions`;
  }

  parseEntity(entityFromApi: any) {
    if (entityFromApi?.connection_entities !== undefined) {
      entityFromApi.connection_entities =
        entityFromApi.connection_entities.data;
    }

    return new ApiResponseParser(entityFromApi, "organization_bank_transaction")
      .asOrganizationEntity()
      .asString("title")
      .asId("organization_bank_account_id")
      .asId("organization_bank_transaction_cost_type_id")
      .asId("organization_sender_user_id")
      .asId("organization_receiver_user_id")
      .asIds("tag_ids")
      .build() as OrganizationBankTransaction;
  }

  getDefaultInclude() {
    return ["connection_entities", "tag_ids"];
  }

  getDefaultIncludeMeta() {
    return ["navigation_by_state"];
  }
}

export function useOrganizationBankTransactionApi() {
  return new OrganizationBankTransactionApi();
}
