export const organizationSettingsRoutes = [
  {
    path: "membership",
    name: "organizationMembership",
    component: () =>
      import("@/views/organization/membership/OrganizationMembership.vue"),
    meta: {
      title: "general.title.organization_memberships",
      activeNav: "organization_settings_membership_list",
    },
  },
  {
    path: "membership/plans",
    name: "organizationMembershipPlans",
    component: () =>
      import("@/views/organization/membership/OrganizationMembershipPlans.vue"),
    meta: {
      title: "general.title.organization_memberships",
      activeNav: "organization_settings_membership_list",
    },
  },
  {
    path: "membership/add-new-payment-method",
    name: "organizationMembershipAddNewPaymentMethod",
    component: () =>
      import("@/views/organization/membership/AddNewPaymentMethod.vue"),
    meta: {
      title: "general.title.organization_memberships",
      activeNav: "organization_settings_membership_list",
    },
  },
  {
    path: "membership/invoices",
    name: "organizationMembershipInvoices",
    component: () =>
      import(
        "@/views/organization/membership/OrganizationMembershipInvoices.vue"
      ),
    meta: {
      title: "general.title.organization_memberships",
      activeNav: "organization_settings_membership_list",
    },
  },
  {
    path: "membership/usage-breakdown",
    name: "organizationMembershipUsageBreakdown",
    component: () =>
      import(
        "@/views/organization/membership/OrganizationMembershipUsageBreakdown.vue"
      ),
    meta: {
      title: "general.title.organization_memberships",
      activeNav: "organization_settings_membership_list",
    },
  },
  {
    path: "integrations",
    name: "organizationIntegrations",
    component: () =>
      import("@/views/organization/integrations/OrganizationIntegrations.vue"),
    meta: {
      title: "general.title.organization_integrations",
      activeNav: "organization_settings_integrations",
    },
  },
  {
    path: "integrations/anaf",
    name: "organizationIntegrationAnaf",
    component: () =>
      import(
        "@/views/organization/integrations/OrganizationIntegrationAnaf.vue"
      ),
    meta: {
      title: "general.title.organization_integration_anaf",
      activeNav: "organization_settings_integration_anaf",
    },
  },
  {
    path: "settings",
    name: "organizationSettings",
    component: () =>
        import("@/views/organization/settings/OrganizationSettings.vue"),
    meta: {
      title: "general.title.organization_settings",
      activeNav: "organization_settings_settings",
    },
  },
];
