import { useOrganizationTagApi } from "@/api/organization/OrganizationTagApi";
import type { OrganizationTag } from "@/interfaces/organization/OrganizationTag";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";

export const useOrganizationTagsStore = defineStore("organizationTags", () => {
  const storage = new OrganizationEntityStorage<OrganizationTag>();
  const pageCache = new OrganizationCache<OrganizationTag>();

  const genericActions = new OrganizationGenericActions<OrganizationTag>({
    storage: storage,
    pageCache: pageCache,
    entityApi: useOrganizationTagApi(),
    enhanceEntity: enhanceEntity,
  });

  return {
    ...genericActions,
  };
});

export function enhanceEntity(
  entity: OrganizationTag,
  storage: OrganizationEntityStorage<OrganizationTag>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.alias);
}
