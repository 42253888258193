import { useAdministrationConsoleJobApi } from "@/api/administration/AdministrationConsoleJobApi";
import type { AdministrationConsoleJob } from "@/interfaces/administration/console-job/AdministrationConsoleJob";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { AdministrationEntityStorage } from "@/stores/generic/storage/AdministrationEntityStorage";
import { AdministrationGenericActions } from "@/stores/generic/store/AdministrationGenericActions";
import { defineStore } from "pinia";

export const useAdministrationConsoleJobsStore = defineStore(
  "administrationConsoleJob",
  () => {
    const storage = new AdministrationEntityStorage<AdministrationConsoleJob>();
    const pageCache = new OrganizationCache<AdministrationConsoleJob>();
    const api = useAdministrationConsoleJobApi();

    const genericActions =
      new AdministrationGenericActions<AdministrationConsoleJob>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
      });

    const runJob = async (consoleJob: AdministrationConsoleJob) => {
      return await api.runJob(consoleJob);
    };

    return {
      ...genericActions,
      runJob,
    };
  }
);

export function enhanceEntity(
  entity: AdministrationConsoleJob,
  storage: AdministrationEntityStorage<AdministrationConsoleJob>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.id);
}
