import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationBankAccountDataAccount } from "@/interfaces/organization/bank/account-data/OrganizationBankAccountDataAccount";
import type { OrganizationBankAccountDataBridge } from "@/interfaces/organization/bank/account-data/OrganizationBankAccountDataBridge";
import { setOpt } from "@/lib/Object";
import { dateToApiFormat } from "@/lib/common/DateUtils";

class OrganizationBankAccountDataApi extends OrganizationGenericEntityApi<OrganizationBankAccountDataBridge> {
  apiIdentifier = "organization_bank_account_data_bridge";

  getRootBaseUrl(args: { organizationId?: string }) {
    return `/organizations/${args.organizationId}/bank-account-data`;
  }

  getBaseUrl(args: { organizationId?: string }) {
    return this.getRootBaseUrl(args) + "/bridge";
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(
      entityFromApi,
      "organization_bank_account_data_bridge"
    )
      .asOrganizationEntity()
      .asId("uuid")
      .asId("institution_id")
      .asId("organization_bank_account_id")
      .asId("user_id")
      .asId("requisition_id")
      .asId("bank_account_data_institution_id")
      .asDate("redirected_at")
      .asDate("paired_at")
      .asDate("expires_at")
      .build() as OrganizationBankAccountDataBridge;
  }

  fillWithEntityIdentifierFields(
    entityFromApi: any,
    entityIdentifier: EntityIdentifier
  ) {
    if (entityIdentifier.organizationId != undefined) {
      entityFromApi.organization_id = entityIdentifier.organizationId;
    }
  }

  async initSession(args: {
    organizationId: string;
    institutionId: string;
    redirectUrl: string;
  }) {
    const response = await this.baseApiPostEntity({
      url: `${this.getRootBaseUrl({
        organizationId: args.organizationId,
      })}/init-session`,
      entity: {
        institution_id: args.institutionId,
        redirect_url: args.redirectUrl,
      },
    }).then((entityFromApi) => {
      this.fillWithEntityIdentifierFields(entityFromApi, {
        organizationId: args.organizationId,
      } as EntityIdentifier);
      return this.parseEntity(entityFromApi);
    });

    return response as OrganizationBankAccountDataBridge;
  }

  async requisition(args: { organizationId: string; bridgeId: string }) {
    const response = await this.baseApiPostEntity({
      url: `${this.getRootBaseUrl({
        organizationId: args.organizationId,
      })}/requisition/${args.bridgeId}`,
      entity: {},
    }).then((entityFromApi) => {
      this.fillWithEntityIdentifierFields(entityFromApi, {
        organizationId: args.organizationId,
      } as EntityIdentifier);
      return this.parseEntity(entityFromApi);
    });

    return response as OrganizationBankAccountDataBridge;
  }

  async getAccount(args: { organizationId: string; bridgeId: string }) {
    // /account-details may return 'Account suspendend' in the logs
    // when the bridge expires. The request code is 400 and
    // the actual message returned is 'Nordigen communication error!'
    // allowSoftFail is set to true

    const response = (await this.baseApiGet({
      url: `${this.getBaseUrl({
        organizationId: args.organizationId,
      })}/${args.bridgeId}/account-details`,
      allowSoftFail: true,
    })) as { [key: string]: { account: OrganizationBankAccountDataAccount } };

    const accounts = {} as {
      [key: string]: OrganizationBankAccountDataAccount;
    };
    for (const key in response) {
      accounts[key] = response[key].account;
    }

    return accounts;
  }

  async pair(args: {
    organizationId: string;
    bridgeId: string;
    accountId: string;
    organizationBankAccountId: string;
    sync_from?: Date;
  }) {
    const response = await this.baseApiPostEntity({
      url: `${this.getBaseUrl({
        organizationId: args.organizationId,
      })}/${args.bridgeId}/pair`,
      entity: {
        organization_bank_account_id: args.organizationBankAccountId,
        account: args.accountId,
        ...setOpt(
          "sync_from",
          args.sync_from ? dateToApiFormat(args.sync_from) : undefined
        ),
      },
    });

    return this.parseEntity(response);
  }

  async unPair(args: {
    organizationId: string;
    bridgeId: string;
    organizationBankAccountId: string;
  }) {
    const response = await this.baseApiPostEntity({
      url: `${this.getBaseUrl({
        organizationId: args.organizationId,
      })}/${args.bridgeId}/unpair`,
      entity: {
        organization_bank_account_id: args.organizationBankAccountId,
      },
    });

    return this.parseEntity(response);
  }
}

export function useOrganizationBankAccountDataApi() {
  return new OrganizationBankAccountDataApi();
}
