import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { OrganizationMembershipPaymentMethod } from "@/interfaces/organization/membership/OrganizationMembershipPaymentMethod";
import type { OrganizationMembershipPaymentMethodSetupInformation } from "@/interfaces/organization/membership/OrganizationMembershipPaymentMethodSetupInformation";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import axios from "axios";
import { getAbortController } from "../generic/AbortControllerMapping";
import { handleApiError } from "../generic/ApiErrorHandler";
import { ApiConverter } from "../mapping/ApiConverter";

class OrganizationMembershipPaymentMethodApi extends OrganizationGenericEntityApi<OrganizationMembershipPaymentMethod> {
  apiIdentifier = "organization_membership_payment_method";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/membership/payment-methods`;
  }

  parseEntity(entityFromApi: any) {
    const entity = new ApiResponseParser(
      entityFromApi,
      "organization_membership_payment_method"
    )
      .asOrganizationEntity()
      .asBool("is_default")
      .asBool("can_delete")
      .asObject("card")
      .build() as OrganizationMembershipPaymentMethod;

    return entity;
  }

  async getPaymentMethodSetupInformation(args: { organizationId: string }) {
    const getUrl = `${this.getBaseUrl(args)}/setup-information`;
    try {
      const response = await axios.get(getUrl, {
        headers: authHeader(),
        signal: getAbortController(this.apiIdentifier).signal,
      });

      const entity =
        response.data as OrganizationMembershipPaymentMethodSetupInformation;

      return entity;
    } catch (error) {
      handleApiError(error, false);
    }
  }

  async postPaymentMethod(entity: any) {
    const entityIdentifier = getEntityIdentifier(entity);

    const rawEntity = entity as any;
    this.prepareEntity(rawEntity);

    try {
      const request = ApiConverter.toRequestData(entity, new FormData());
      const response = await axios.post(
        this.getBaseUrl(entityIdentifier as any),
        request,
        {
          headers: authHeader(),
          signal: getAbortController(this.apiIdentifier).signal,
        }
      );
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async makePrimary(entity: OrganizationMembershipPaymentMethod) {
    const entityIdentifier = getEntityIdentifier(entity);

    try {
      const response = await axios.put(
        `${this.getBaseUrl(entityIdentifier as any)}/${entity.id}/set-primary`,
        {},
        {
          headers: authHeader(),
          signal: getAbortController(this.apiIdentifier).signal,
        }
      );
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async delete(entity: OrganizationMembershipPaymentMethod) {
    const entityIdentifier = getEntityIdentifier(entity) as any;
    const deleteUrl = this.getBaseUrl(entityIdentifier) + `/${entity.id}`;

    try {
      const response = await axios.delete(deleteUrl, {
        headers: authHeader(),
        signal: getAbortController(this.apiIdentifier).signal,
      });
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationMembershipPaymentMethodApi() {
  return new OrganizationMembershipPaymentMethodApi();
}
