import { useAuthApi } from "@/api/AuthApi";
import { useTimeZoneApi } from "@/api/TimeZoneApi";
import { useAdministrationConsoleJobApi } from "@/api/administration/AdministrationConsoleJobApi";
import { useAdministrationMembershipApi } from "@/api/administration/AdministrationMembershipApi";
import { useAdministrationUserAccountTypeApi } from "@/api/administration/AdministrationUserAccountTypeApi";
import { useAdministrationUserInvitationCodeApi } from "@/api/administration/AdministrationUserInvitationCodeApi";
import { useAnalyticsApi } from "@/api/organization/OrganizationAnalyticsApi";
import { useOrganizationApi } from "@/api/organization/OrganizationApi";
import { useOrganizationBankAccountApi } from "@/api/organization/OrganizationBankAccountApi";
import { useOrganizationBankAccountDataApi } from "@/api/organization/OrganizationBankAccountDataApi";
import { useOrganizationBankTransactionApi } from "@/api/organization/OrganizationBankTransactionApi";
import { useOrganizationBankTransactionCostTypeApi } from "@/api/organization/OrganizationBankTransactionCostTypeApi";
import { useOrganizationContractApi } from "@/api/organization/OrganizationContractApi";
import { useOrganizationContractStatusApi } from "@/api/organization/OrganizationContractStatusApi";
import { useOrganizationCredentialsApi } from "@/api/organization/OrganizationCredentialsApi";
import { useOrganizationDataPushApi } from "@/api/organization/OrganizationDataPushApi";
import { useOrganizationExportApi } from "@/api/organization/OrganizationExportApi";
import { useOrganizationFileApi } from "@/api/organization/OrganizationFileApi";
import { useOrganizationInvoiceApi } from "@/api/organization/OrganizationInvoiceApi";
import { useOrganizationInvoiceNetworkExchangeApi } from "@/api/organization/OrganizationInvoiceNetworkExchangeApi";
import { useOrganizationInvoiceNotificationLogApi } from "@/api/organization/OrganizationInvoiceNotificationLogApi";
import { useOrganizationInvoicePrefixApi } from "@/api/organization/OrganizationInvoicePrefixApi";
import { useOrganizationMembershipApi } from "@/api/organization/OrganizationMembershipApi";
import { useOrganizationMembershipInvoiceApi } from "@/api/organization/OrganizationMembershipInvoiceApi";
import { useOrganizationMembershipPaymentMethodApi } from "@/api/organization/OrganizationMembershipPaymentMethodApi";
import { useOrganizationMiscApi } from "@/api/organization/OrganizationMiscApi";
import { useOrganizationPayslipApi } from "@/api/organization/OrganizationPayslipApi";
import { useOrganizationPayslipSchemaApi } from "@/api/organization/OrganizationPayslipSchemaApi";
import { useOrganizationProductApi } from "@/api/organization/OrganizationProductApi";
import { useOrganizationReceiptApi } from "@/api/organization/OrganizationReceiptApi";
import { useOrganizationReportApi } from "@/api/organization/OrganizationReportApi";
import { useOrganizationReportSchemaApi } from "@/api/organization/OrganizationReportSchemaApi";
import { useOrganizationReportSchemaFieldApi } from "@/api/organization/OrganizationReportSchemaFieldApi";
import { useOrganizationServiceIntegrationApi } from "@/api/organization/OrganizationServiceIntegrationApi";
import { useOrganizationTagApi } from "@/api/organization/OrganizationTagApi";
import { useOrganizationTaskApi } from "@/api/organization/OrganizationTaskApi";
import { useOrganizationTaxClassApi } from "@/api/organization/OrganizationTaxClassApi";
import { useOrganizationTaxRateApi } from "@/api/organization/OrganizationTaxRateApi";
import { useOrganizationUserActivityApi } from "@/api/organization/OrganizationUserActivityApi";
import { useOrganizationUserAddressApi } from "@/api/organization/OrganizationUserAddressApi";
import { useOrganizationUserApi } from "@/api/organization/OrganizationUserApi";
import { useOrganizationUserRoleAbilityApi } from "@/api/organization/OrganizationUserRoleAbilityApi";
import { useOrganizationUserRoleApi } from "@/api/organization/OrganizationUserRoleApi";
import { useOrganizationWebHookApi } from "@/api/organization/OrganizationWebHookApi";
import { useOrganizationWebHookNotificationApi } from "@/api/organization/OrganizationWebHookNotificationApi";
import { useUserApi } from "@/api/user/UserApi";
import { useUserApiTokenApi } from "@/api/user/UserApiTokenApi";
import { useUserOrganizationSettingsApi } from "@/api/user/UserOrganizationSettingsApi";
import type { Entity } from "@/interfaces/generic/Entity";
import { useAdministrationConsoleJobsStore } from "@/stores/administration/AdministrationConsoleJobs";
import { useAdministrationMembershipsStore } from "@/stores/administration/AdministrationMemberships";
import { useAdministrationUserAccountTypesStore } from "@/stores/administration/AdministrationUserAccountTypes";
import { useAdministrationUserInvitationCodesStore } from "@/stores/administration/AdministrationUserInvitationCodes";
import { useOrganizationUserActivitiesStore } from "@/stores/organization/OrganizationUserActivitiesStore";
import { useOrganizationBankAccountDataStore } from "@/stores/organization/entity/OrganizationBankAccountData";
import { useOrganizationBankAccountsStore } from "@/stores/organization/entity/OrganizationBankAccounts";
import { useOrganizationBankTransactionCostTypesStore } from "@/stores/organization/entity/OrganizationBankTransactionCostTypes";
import { useOrganizationBankTransactionImportsStore } from "@/stores/organization/entity/OrganizationBankTransactionImports";
import { useOrganizationBankTransactionsStore } from "@/stores/organization/entity/OrganizationBankTransactions";
import { useOrganizationContractStatusesStore } from "@/stores/organization/entity/OrganizationContractStatuses";
import { useOrganizationContractsStore } from "@/stores/organization/entity/OrganizationContracts";
import { useOrganizationExportEntriesStore } from "@/stores/organization/entity/OrganizationExportEntries";
import { useOrganizationFilesStore } from "@/stores/organization/entity/OrganizationFiles";
import { useOrganizationInvoiceNetworkExchangesStore } from "@/stores/organization/entity/OrganizationInvoiceNetworkExchanges";
import { useOrganizationInvoiceNotificationLogsStore } from "@/stores/organization/entity/OrganizationInvoiceNotificationLogs";
import { useOrganizationInvoicePrefixesStore } from "@/stores/organization/entity/OrganizationInvoicePrefixes";
import { useOrganizationInvoicesStore } from "@/stores/organization/entity/OrganizationInvoices";
import { useOrganizationMembershipInvoicesStore } from "@/stores/organization/entity/OrganizationMembershipInvoices";
import { useOrganizationMembershipPaymentMethodsStore } from "@/stores/organization/entity/OrganizationMembershipPaymentMethods";
import { useOrganizationPayslipSchemasStore } from "@/stores/organization/entity/OrganizationPayslipSchemas";
import { useOrganizationPayslipsStore } from "@/stores/organization/entity/OrganizationPayslips";
import { useOrganizationProductsStore } from "@/stores/organization/entity/OrganizationProducts";
import { useOrganizationReceiptsStore } from "@/stores/organization/entity/OrganizationReceipts";
import { useOrganizationReportSchemaFieldsStore } from "@/stores/organization/entity/OrganizationReportSchemaFields";
import { useOrganizationReportSchemasStore } from "@/stores/organization/entity/OrganizationReportSchemas";
import { useOrganizationReportsStore } from "@/stores/organization/entity/OrganizationReports";
import { useOrganizationTagsStore } from "@/stores/organization/entity/OrganizationTags";
import { useOrganizationTasksStore } from "@/stores/organization/entity/OrganizationTasks";
import { useOrganizationTaxClassesStore } from "@/stores/organization/entity/OrganizationTaxClasses";
import { useOrganizationTaxRatesStore } from "@/stores/organization/entity/OrganizationTaxRates";
import { useOrganizationUserAdressesStore } from "@/stores/organization/entity/OrganizationUserAdresses";
import { useOrganizationUserRolesStore } from "@/stores/organization/entity/OrganizationUserRoles";
import { useOrganizationUsersStore } from "@/stores/organization/entity/OrganizationUsers";
import { useOrganizationWebhookNotificationsStore } from "@/stores/organization/entity/OrganizationWebhookNotifications";
import { useOrganizationWebhooksStore } from "@/stores/organization/entity/OrganizationWebhooks";
import { useOrganizationsStore } from "@/stores/organization/entity/Organizations";
import { useUserStore } from "@/stores/user/User";
import { useUserApiTokensStore } from "@/stores/user/UserApiTokens";
import { useUserOrganizationSettingsStore } from "@/stores/user/UserOrganizationSettings";
import type { StoreGeneric } from "pinia";
import { useOrganizationSettingsApi } from "@/api/organization/OrganizationSettingsApi";
import { useOrganizationSettingsStore } from "@/stores/organization/entity/OrganizationSettings";
import { useOrganizationBillingScheduleStore } from "@/stores/organization/entity/OrganizationBillingSchedules";
import { useOrganizationBillingScheduleApi } from "@/api/organization/OrganizationBillingScheduleApi";
import { useOrganizationBillingScheduledPaymentApi } from "@/api/organization/OrganizationBillingScheduledPaymentApi";

const entityToStoreMap: { [key: string]: () => StoreGeneric } = {
  administration_membership: useAdministrationMembershipsStore,
  administration_user_account_type: useAdministrationUserAccountTypesStore,
  administration_user_invitation_code:
    useAdministrationUserInvitationCodesStore,
  administration_console_job: useAdministrationConsoleJobsStore,

  organization: useOrganizationsStore,
  organization_export_entry: useOrganizationExportEntriesStore,
  organization_bank_account: useOrganizationBankAccountsStore,
  organization_bank_account_data_bridge: useOrganizationBankAccountDataStore,
  organization_bank_transaction: useOrganizationBankTransactionsStore,
  organization_bank_transaction_import:
    useOrganizationBankTransactionImportsStore,
  organization_bank_transaction_cost_type:
    useOrganizationBankTransactionCostTypesStore,
  organization_contract: useOrganizationContractsStore,
  organization_contract_status: useOrganizationContractStatusesStore,
  organization_receipt: useOrganizationReceiptsStore,
  organization_file: useOrganizationFilesStore,
  organization_billing_schedule: useOrganizationBillingScheduleStore,
  organization_invoice: useOrganizationInvoicesStore,
  organization_invoice_prefix: useOrganizationInvoicePrefixesStore,
  organization_invoice_notification_log:
    useOrganizationInvoiceNotificationLogsStore,
  organization_invoice_network_exchange:
    useOrganizationInvoiceNetworkExchangesStore,
  organization_membership_invoice: useOrganizationMembershipInvoicesStore,
  organization_membership_payment_method:
    useOrganizationMembershipPaymentMethodsStore,
  organization_payslip: useOrganizationPayslipsStore,
  organization_payslip_schema: useOrganizationPayslipSchemasStore,
  organization_product: useOrganizationProductsStore,
  organization_report: useOrganizationReportsStore,
  organization_report_schema: useOrganizationReportSchemasStore,
  organization_report_schema_field: useOrganizationReportSchemaFieldsStore,
  organization_tag: useOrganizationTagsStore,
  organization_task: useOrganizationTasksStore,
  organization_tax_class: useOrganizationTaxClassesStore,
  organization_tax_rate: useOrganizationTaxRatesStore,
  organization_user: useOrganizationUsersStore,
  organization_user_role: useOrganizationUserRolesStore,
  organization_user_address: useOrganizationUserAdressesStore,
  user: useUserStore,
  user_organization_setting: useUserOrganizationSettingsStore,
  user_api_token: useUserApiTokensStore,
  organization_user_activity: useOrganizationUserActivitiesStore,
  organization_webhook: useOrganizationWebhooksStore,
  organization_webhook_notification: useOrganizationWebhookNotificationsStore,
  organization_settings: useOrganizationSettingsStore,
};

export function getStore(entity: Entity | string) {
  const store =
    typeof entity == "string"
      ? entityToStoreMap[entity]
      : entityToStoreMap[entity._entity];
  return store();
}

const entityToApiMap: { [key: string]: any } = {
  administration_console_job: useAdministrationConsoleJobApi(),
  administration_membership: useAdministrationMembershipApi(),
  administration_user_account_type: useAdministrationUserAccountTypeApi(),
  administration_user_invitation_code: useAdministrationUserInvitationCodeApi(),

  organization_analytics: useAnalyticsApi(),
  organization: useOrganizationApi(),
  organization_bank_account: useOrganizationBankAccountApi(),
  organization_bank_account_data_bridge: useOrganizationBankAccountDataApi(),
  organization_bank_transaction: useOrganizationBankTransactionApi(),
  organization_bank_transaction_cost_type:
    useOrganizationBankTransactionCostTypeApi(),
  organization_bank_transaction_import: useOrganizationBankTransactionApi(),
  organization_contract: useOrganizationContractApi(),
  organization_contract_status: useOrganizationContractStatusApi(),
  organization_credentials: useOrganizationCredentialsApi(),
  organization_data_push: useOrganizationDataPushApi(),
  organization_export_entry: useOrganizationExportApi(),
  organization_file: useOrganizationFileApi(),
  organization_billing_schedule: useOrganizationBillingScheduleApi(),
  organization_billing_scheduled_payment: useOrganizationBillingScheduledPaymentApi(),
  organization_invoice: useOrganizationInvoiceApi(),
  organization_invoice_network_exchange:
    useOrganizationInvoiceNetworkExchangeApi(),
  organization_invoice_notification_log:
    useOrganizationInvoiceNotificationLogApi(),
  organization_invoice_prefix: useOrganizationInvoicePrefixApi(),
  organization_membership: useOrganizationMembershipApi(),
  organization_membership_invoice: useOrganizationMembershipInvoiceApi(),
  organization_membership_payment_method:
    useOrganizationMembershipPaymentMethodApi(),
  organization_misc: useOrganizationMiscApi(),
  organization_payslip: useOrganizationPayslipApi(),
  organization_payslip_schema: useOrganizationPayslipSchemaApi(),
  organization_product: useOrganizationProductApi(),
  organization_receipt: useOrganizationReceiptApi(),
  organization_report: useOrganizationReportApi(),
  organization_report_schema: useOrganizationReportSchemaApi(),
  organization_report_schema_field: useOrganizationReportSchemaFieldApi(),
  organization_service_integration: useOrganizationServiceIntegrationApi(),
  organization_tag: useOrganizationTagApi(),
  organization_task: useOrganizationTaskApi(),
  organization_tax_class: useOrganizationTaxClassApi(),
  organization_tax_rate: useOrganizationTaxRateApi(),
  organization_user_activity: useOrganizationUserActivityApi(),
  organization_user_address: useOrganizationUserAddressApi(),
  organization_user: useOrganizationUserApi(),
  organization_user_role_ability: useOrganizationUserRoleAbilityApi(),
  organization_user_role: useOrganizationUserRoleApi(),
  organization_webhook: useOrganizationWebHookApi(),
  organization_webhook_notification: useOrganizationWebHookNotificationApi(),
  organization_settings: useOrganizationSettingsApi(),

  user: useUserApi(),
  user_api_token: useUserApiTokenApi(),
  user_organization_setting: useUserOrganizationSettingsApi(),

  auth: useAuthApi(),
  timezone: useTimeZoneApi(),
};

export function getApi(entity: Entity | string) {
  return typeof entity == "string"
    ? entityToApiMap[entity]
    : entityToApiMap[entity._entity];
}

const searchFieldsMap: { [key: string]: string[] } = {
  administration_membership: ["name", "slug"],
  administration_user_account_type: ["name", "description"],
  administration_user_invitation_code: ["code", "user_account_type_id"],
  administration_console_job: ["description"],

  organization: ["name", "country_code"],
  organization_export_entry: ["status", "disk_alias", "disk_path"],
  organization_bank_account: [
    "name",
    "bank_name",
    "bank_country_code",
    "account_information",
  ],
  organization_bank_transaction: [
    "title",
    "hash",
    "amount",
    "direction",
    "note",
  ],
  organization_bank_transaction_cost_type: ["title", "title_lang_map"],
  organization_contract: [],
  organization_contract_status: ["title_lang_map", "description_lang_map"],
  organization_receipt: ["receipt_number", "total"],
  organization_file: [],
  organization_billing_schedule: [],
  organization_invoice: ["number", "total"],
  organization_invoice_prefix: [],
  organization_invoice_notification_log: [],
  organization_invoice_network_exchange: [
    "organization_invoice_id",
    "institution_id",
  ],
  organization_membership_invoice: [],
  organization_membership_payment_method: [],
  organization_payslip: ["status", "payment_method", "currency"],
  organization_payslip_schema: [
    "name_lang_map",
    "description_lang_map",
    "field_map",
    "field_group_list",
  ],
  organization_product: [
    "name",
    "alias",
    "description",
    "type",
    "unit",
    "name_lang_map",
    "unit_lang_map",
    "invoice_schema_map",
    "ean_number",
  ],
  organization_report: [],
  organization_report_schema: [],
  organization_report_schema_field: [],
  organization_tag: ["alias", "description"],
  organization_task: ["title_lang_map", "content_lang_map"],
  organization_tax_class: ["name_lang_map", "description_lang_map"],
  organization_tax_rate: ["name"],
  organization_user: ["name", "first_name", "last_name", "email"],
  organization_user_role: ["name", "alias", "description"],
  organization_user_address: ["name", "address_line_1", "address_line_2"],
  user: [],
  user_organization_setting: [],
  user_api_token: [],
  organization_webhook: ["destination_url"],
  organization_webhook_notification: [
    "organization_webhook_endpoint_id",
    "event",
    "status",
    "response_status",
  ],
  user_settings: [],
};

export function getSearchFields(entity: Entity | string) {
  return typeof entity == "string"
    ? searchFieldsMap[entity]
    : searchFieldsMap[entity._entity];
}

const aclMap: { [key: string]: string | undefined } = {
  organization: "",
  organization_bank_account: "bank_accounts",
  organization_bank_transaction: "bank_transactions",
  organization_bank_transaction_cost_type: "bank_transaction_cost_types",
  organization_contract: "contracts",
  organization_contract_status: "contract_statuses",
  organization_receipt: "receipts",
  organization_file: "files",
  organization_billing_schedule: "billing_schedules",
  organization_invoice: "invoices",
  organization_invoice_prefix: "invoice_prefixes",
  organization_invoice_notification_log: undefined,
  organization_invoice_network_exchange: undefined,
  organization_membership_invoice: undefined,
  organization_membership_payment_method: undefined,
  organization_payslip: "payslips",
  organization_payslip_schema: "payslip_schemas",
  organization_product: "products",
  organization_report: "reports",
  organization_report_schema: "report_schemas",
  organization_report_schema_field: "report_schema_fields",
  organization_tag: "tags",
  organization_task: "tasks",
  organization_tax_class: "tax_classes",
  organization_tax_rate: "tax_rates",
  organization_user: "users",
  organization_user_role: "user_roles",
  user: undefined,
  user_organization_setting: undefined,
  user_api_token: undefined,
  organization_webhook: "webhooks",
  organization_webhook_notification: "webhooks",
  organization_settings: undefined,
};

export function getAcl(entity: Entity | string) {
  return typeof entity == "string" ? aclMap[entity] : aclMap[entity._entity];
}

const entityPluralMap: { [key: string]: string } = {
  administration_membership: "administration_memberships",
  administration_user_account_type: "administration_user_account_types",
  administration_user_invitation_code: "administration_user_invitation_codes",
  administration_console_job: "administration_console_jobs",

  organization: "organizations",
  organization_export_entry: "organization_export_entries",
  organization_bank_account: "organization_bank_accounts",
  organization_bank_transaction: "organization_bank_transactions",
  organization_bank_transaction_import: "organization_bank_transaction_imports",
  organization_bank_transaction_cost_type:
    "organization_bank_transaction_cost_types",
  organization_contract: "organization_contracts",
  organization_contract_status: "organization_contract_statuses",
  organization_receipt: "organization_receipts",
  organization_file: "organization_files",
  organization_billing_schedule: "organization_billing_schedules",
  organization_invoice: "organization_invoices",
  organization_invoice_prefix: "organization_invoice_prefixes",
  organization_invoice_notification_log:
    "organization_invoice_notification_logs",
  organization_invoice_network_exchange:
    "organization_invoice_network_exchanges",
  organization_membership_invoice: "organization_membership_invoices",
  organization_membership_payment_method:
    "organization_membership_payment_methods",
  organization_payslip: "organization_payslips",
  organization_payslip_schema: "organization_payslip_schemas",
  organization_product: "organization_products",
  organization_report: "organization_reports",
  organization_report_schema: "organization_report_schemas",
  organization_report_schema_field: "organization_report_schema_fields",
  organization_tag: "organization_tags",
  organization_task: "organization_tasks",
  organization_tax_class: "organization_tax_classes",
  organization_tax_rate: "organization_tax_rates",
  organization_user: "organization_users",
  organization_user_role: "organization_user_roles",
  organization_user_address: "organization_user_addresses",
  user: "users",
  user_organization_setting: "user_organization_settings",
  user_api_token: "user_api_tokens",
  organization_webhook: "organization_webhooks",
  organization_webhook_notification: "organization_webhook_notifications",
  organization_settings: "organization_settings",
};

export function toPlural(entity: Entity | string) {
  return typeof entity == "string"
    ? entityPluralMap[entity]
    : entityPluralMap[entity._entity];
}
