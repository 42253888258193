import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationTaxClass } from "@/interfaces/organization/tax/tax-classes/OrganizationTaxClass";

class OrganizationTaxClassApi extends OrganizationGenericEntityApi<OrganizationTaxClass> {
  apiIdentifier = "organization_tax_class";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/tax-classes`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_tax_class")
      .asOrganizationEntity()
      .asLangMap("name_lang_map")
      .asLangMap("description_lang_map")
      .asId("deleted_by_user_id")
      .build() as OrganizationTaxClass;
  }

  getDefaultInclude() {
    return ["tag_ids"];
  }
}

export function useOrganizationTaxClassApi() {
  return new OrganizationTaxClassApi();
}
