import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationBankTransactionImport } from "@/interfaces/organization/bank/OrganizationBankTransactionImport";
import type { OrganizationBankTransactionImportSendData } from "@/interfaces/organization/bank/OrganizationBankTransactionImportSendData";

class OrganizationBankTransactionImportApi extends OrganizationGenericEntityApi<OrganizationBankTransactionImport> {
  apiIdentifier = "organization_bank_transaction_import";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/bank-transactions/imports`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(
      entityFromApi,
      "organization_bank_transaction_import"
    )
      .asOrganizationEntity()
      .asDate("started_at")
      .asDate("completed_at")
      .asBool("success")
      .build() as OrganizationBankTransactionImport;
  }

  async importBankTransaction(args: {
    organizationId: string;
    data: OrganizationBankTransactionImportSendData;
  }) {
    const response = await this.baseApiPostEntity({
      url: `${this.getBaseUrl({
        organizationId: args.organizationId,
      })}?type=bank_account_statement_upload`,
      entity: args.data,
    });

    return response as OrganizationBankTransactionImport;
  }
}

export function useOrganizationBankTransactionImportApi() {
  return new OrganizationBankTransactionImportApi();
}
