import { useOrganizationWebHookApi } from "@/api/organization/OrganizationWebHookApi";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import type { OrganizationWebhook } from "@/interfaces/organization/webhook/OrganizationWebhook";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { useUserStore } from "@/stores/user/User";
import { defineStore } from "pinia";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationWebhooksStore = defineStore(
  "organizationWebhooks",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationWebhook>();
    const pageCache = new OrganizationCache<OrganizationWebhook>();

    const genericActions = new OrganizationGenericActions<OrganizationWebhook>({
      storage: storage,
      pageCache: pageCache,
      entityApi: useOrganizationWebHookApi(),
      ignoredKeysForDeletion: ["events"],
      enhanceEntity: enhanceEntity,
    });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationWebhook,
  storage: OrganizationEntityStorage<OrganizationWebhook>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  const userStore = useUserStore();

  if (userStore.hasAccess("users.view")) {
    const organizationUserStore = useOrganizationUsersStore();
    addComputedField<OrganizationWebhook, OrganizationUser>(
      entity,
      "deletedByUser",
      () => storage.get(entityIdentifier)?.deleted_by_user_id,
      async () =>
        organizationUserStore.lazyGetById({
          organizationId: entityIdentifier.organizationId,
          id: storage.get(entityIdentifier)?.deleted_by_user_id,
        })
    );
  }

  setEntityNameFields(
    entity,
    () => storage.get(getEntityIdentifier(entity))?.destination_url
  );
}
