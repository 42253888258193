import {
  authHeader,
  localeHeader,
} from "@/composables/api/RequestHeaderGenerator";
import type { RegistrationUser } from "@/interfaces/auth/RegistrationUser";
import type { User } from "@/interfaces/user/User";
import axios from "axios";
import { handleApiError } from "./generic/ApiErrorHandler";

class AuthApi {
  getBaseUrl() {
    return `/authentication`;
  }

  async login(args: { email: string; password: string; remember?: boolean }) {
    try {
      const response = await axios.post(
        this.getBaseUrl() + `/login`,
        { ...args },
        { headers: localeHeader() }
      );

      return {
        email: args.email,
        accessToken: response.data.token,
      } as User;
    } catch (error) {
      handleApiError(error);
    }
  }

  async logout() {
    try {
      await axios.get(this.getBaseUrl() + `/logout`, {
        headers: authHeader(),
      });
    } catch (error) {
      handleApiError(error);
    }
  }

  async register(user: RegistrationUser) {
    try {
      const response = await axios.post(
        this.getBaseUrl() + `/register`,
        { ...user },
        { headers: localeHeader() }
      );

      return response.data.data;
    } catch (error) {
      handleApiError(error);
    }
  }

  async forgotPassword(email: string) {
    try {
      return await axios.post(
        this.getBaseUrl() + `/forgot-password`,
        { email: email },
        { headers: localeHeader() }
      );
    } catch (error) {
      handleApiError(error);
    }
  }

  async resetPassword(args: {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
  }) {
    const { token, email, password, password_confirmation } = args;

    try {
      return await axios.post(
        this.getBaseUrl() + `/reset-password`,
        {
          token,
          email,
          password,
          password_confirmation,
        },
        { headers: localeHeader() }
      );
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useAuthApi() {
  return new AuthApi();
}
