import { useAdministrationMembershipApi } from "@/api/administration/AdministrationMembershipApi";
import type { AdministrationPricingPlan } from "@/interfaces/administration/membership/AdministrationPricingPlan";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { AdministrationEntityStorage } from "@/stores/generic/storage/AdministrationEntityStorage";
import { AdministrationGenericActions } from "@/stores/generic/store/AdministrationGenericActions";
import { defineStore } from "pinia";

export const useAdministrationMembershipsStore = defineStore(
  "administrationMembership",
  () => {
    const storage =
      new AdministrationEntityStorage<AdministrationPricingPlan>();
    const pageCache = new OrganizationCache<AdministrationPricingPlan>();

    const genericActions =
      new AdministrationGenericActions<AdministrationPricingPlan>({
        storage: storage,
        pageCache: pageCache,
        entityApi: useAdministrationMembershipApi(),
        enhanceEntity: enhanceEntity,
      });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: AdministrationPricingPlan,
  storage: AdministrationEntityStorage<AdministrationPricingPlan>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.name);
}
