import type { Tooltip } from "@/interfaces/common/Tooltip";
import { ref } from "vue";

let tooltipId = 0;
const tooltips = ref({} as { [key: number]: Tooltip });

export function createTooltip(content: any) {
  const tooltip = {
    id: tooltipId,
    content,
    visible: false,
    type: "absolute",
    orientation: "bottom",
    offset: 12,
  } as Tooltip;
  tooltipId += 1;

  tooltips.value[tooltip.id] = tooltip;

  return tooltips.value[tooltip.id];
}

export function destroyTooltip(tooltip: Tooltip) {
  delete tooltips.value[tooltip.id];
}

export function getTooltips() {
  return tooltips;
}
