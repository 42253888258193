import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { Organization } from "@/interfaces/organization/Organization";
import type { PricingPlan } from "@/interfaces/organization/membership/PricingPlan";
import type { StripeSession } from "@/interfaces/organization/membership/StripeSession";
import axios from "axios";

class OrganizationMembershipApi {
  apiIdentifier = "organization_membership";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/membership`;
  }

  async getPricingPlans(args: { organization: Organization }) {
    try {
      const response = await axios.get(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/pricing-plans`,
        { headers: authHeader() }
      );

      return response.data as PricingPlan[];
    } catch (error) {
      handleApiError(error);
    }
  }

  async getPaymentMethods(args: { organization: Organization }) {
    try {
      const response = await axios.get(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/payment-methods`,
        { headers: authHeader() }
      );

      return response.data as any[];
    } catch (error) {
      handleApiError(error);
    }
  }

  async checkout(args: {
    organization: Organization;
    stripePriceId: string;
    currency: string;
  }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/checkout/stripe`,
        {
          stripe_price_id: args.stripePriceId,
          currency: args.currency,
          success_url: window.location.href,
          cancel_url: window.location.href,
        },
        { headers: authHeader() }
      );

      return response.data as StripeSession;
    } catch (error) {
      handleApiError(error);
    }
  }

  async upgrade(args: { organization: Organization; plan: string }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/upgrade`,
        { plan: args.plan },
        { headers: authHeader() }
      );

      return response.data as StripeSession;
    } catch (error) {
      handleApiError(error);
    }
  }

  async downgrade(args: { organization: Organization; plan: string }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/downgrade`,
        { plan: args.plan },
        { headers: authHeader() }
      );

      return response.data as StripeSession;
    } catch (error) {
      handleApiError(error);
    }
  }

  async cancel(args: { organization: Organization }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/cancel`,
        {},
        { headers: authHeader() }
      );

      return response.data as StripeSession;
    } catch (error) {
      handleApiError(error);
    }
  }

  async resume(args: { organization: Organization }) {
    try {
      const response = await axios.post(
        `${this.getBaseUrl({
          organizationId: args.organization.id,
        })}/resume`,
        {},
        { headers: authHeader() }
      );

      return response.data as StripeSession;
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationMembershipApi() {
  return new OrganizationMembershipApi();
}
