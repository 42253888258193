import type { OrganizationSettings } from "@/interfaces/organization/settings/OrganizationSettings";
import { defineStore } from "pinia";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";
import { useOrganizationSettingsApi } from "@/api/organization/OrganizationSettingsApi";

export const useOrganizationSettingsStore = defineStore(
  "organizationSettings",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationSettings>();
    const pageCache = new OrganizationCache<OrganizationSettings>();

    const entityApi = useOrganizationSettingsApi();

    const genericActions = new OrganizationGenericActions<OrganizationSettings>(
      {
        storage: storage,
        pageCache: pageCache,
        entityApi: entityApi,
      },
    );

    return {
      ...genericActions,
    };
  },
);
