import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { Organization } from "@/interfaces/organization/Organization";

class OrganizationApi extends OrganizationGenericEntityApi<Organization> {
  apiIdentifier = "organization";

  getBaseUrl() {
    return `/organizations`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization")
      .asOrganizationEntity()
      .asId("user_id")
      .asId("organization_id")
      .asObject("information")
      .asId("branding_icon_organization_file_id")
      .asId("branding_logo_organization_file_id")
      .asBool("is_tax_payer")
      .asBool("membership_cancel_at_period_end")
      .asDate("membership_renewal_date")
      .asDate("membership_credit_reset_at")
      .asId("membership_stripe_product_id")
      .asId("membership_stripe_price_id")
      .asId("membership_stripe_customer_id")
      .asObject("remaining_credits")
      .asArray("user_abilities")
      .asArray("schema.ability_list", true)
      .asObject("remaining_credits")
      .asArray("membership_features")
      .build() as Organization;
  }

  getDefaultInclude() {
    // @todo With Robert, figure out, how to make this work properly, and not include everything, - ne zgariam pe ochi
    return [
      "schema",
      "user_abilities",
      "remaining_credits",
      "membership_features",
    ];
  }
}

export function useOrganizationApi() {
  return new OrganizationApi();
}
